/* 
CSS fuer die Legenden und Beschreibungen unterhalb der Karten oder SatBilder
2016 Stefan Goretzki 
*/

/* .sg-descr ist Identifier fuer Stefan + legenden */
.sg-descr .header{font-size:1.5em;font-style:bold;margin-bottom:1em} /* Titel z.B. "Thermikkarte" */
.sg-descr .sub-header{font-size:1.2em;font-style:italic;margin:2em 0 .5em 1em} /* Untertitel */
.sg-descr .main-sub-header{font-size:1.2em;font-style:italic;margin:2em 0 .5em 0} /* Untertitel */
.sg-descr .sub-txt{margin-left:1em} /* eingerueckte texte */
.sg-descr p{margin:.6em 0} /* normale Absaetze */
.sg-descr img{border:1px solid lightgrey}

.sg-descr .inline-block{display:inline-block;margin-top:2em}
.sg-descr .block{display:block;margin-top:2em}
.sg-descr .table{display:table}
.sg-descr .table-300px{display:table;width:300px;margin-top:.5em}
.sg-descr .table-100{display:table;width:100%;margin-top:.5em}
.sg-descr .row{display:table-row}
.sg-descr .cell{display:table-cell;padding:10px 10px 3px 0;vertical-align:middle}
.sg-descr .cell-left{width:40px;padding-left:1em}
.sg-descr .cell-left img{width:25px;height:25px}
.sg-descr .cell-left .sg2010{width:100px;height:37px}
.sg-descr .cell-left .sg2010l{width:100px;height:74px}
.sg-descr .table-300px .cell-left img{width:35px;height:35px}

.sg-descr .legend-block{max-width:700px}
.sg-descr .legend-block img{width:100%;border:none}
.sg-descr .legend-block-orig-width img{border:none}


@media screen and (max-width: 500px) and (orientation: portrait) {
	.sg-descr .table-300px{width:100%}
	.sg-descr .inline-block{margin-top:1em}
} 
