/**
 * @file sub module for template module shop
 * order process (body classes shop and order)
 * ......
 * scope:
 *   - shop
 *
 * @copyright CosmoCode GmbH
 */

@import (reference) "../setup";
@import (reference) "../helpers/content";
@import (reference) "../helpers/interactiveElems";
@import (reference) "../helpers/metSelect";
@import (reference) "../helpers/raster";

@packet-height: 178px;
@circle-base: 9px;


/* + + + + + + + + + + + + + + + + +
 * DESKTOP + MOBILE
 * + + + + + + + + + + + + + + + + */

body.shop.order {
    .content-area {
        label {
            cursor: pointer;
        }

        p.error {
            color: @topmeteo-red;
        }

        .sr-out {
            position: absolute;
            left: -10000em;
            top: -10000em;
            width: 1px;
            height: 1px;
            overflow: hidden;
        }


/* + + + + +  steps for order process  + + + + + */
        header {
            counter-reset: li;

            ul.shop-steps {
                &:extend(.clearfix all);

                display: flex;
                flex-wrap: nowrap;
                min-width: 80%;
                max-width: 90%;
                margin: @topmeteo-pad auto;
                padding: 0 @topmeteo-pad;

                .circle {
                    position: relative;
                    z-index: 100;
                    padding-top: (@circle-base * 1.5);
                    border: 1px solid @topmeteo-blue;
                    border-radius: 50%;
                    display: block;
                    margin: 0 auto @circle-base;
                    width: (@circle-base * 5);
                    height: (@circle-base * 5);
                    text-align: center;
                    background-color: #fff;
                    text-decoration: none;
                    color: @topmeteo-blue;
                }

                .step-label {
                    display: inline;
                    text-align: center;
                    color: @topmeteo-blue;
                }

                li {
                    counter-increment: li;
                    position: relative;
                    z-index: 1;
                    display: block;
                    flex: 1 1 100%;
                    text-align: center;
                    vertical-align: top;
                    margin: 0;
                    padding: (@circle-base *1.6) 0 @circle-base;

                    &::before {
                        position: absolute;
                        top: (@circle-base * 4);
                        left: 0;
                        content: "";
                        z-index: 10;
                        width: 100%;
                        height: 1px;
                        border-top: 1px solid @topmeteo-blue;
                    }

                    &:last-child {
                        padding-right: 0;

                        &::before {
                            width: 50%;
                        }
                    }

                    &.step-1 {
                        padding-right: 0;

                        &::before {
                            width: 50%;
                            left: auto;
                            right: 0;
                        }
                    }

/* + + +  for counting steps and overwrite the a-content  + + + */
                    .circle::after {
                        @counter-height: 1.4em;

                        content: counter(li);
                        position: absolute;
                        top: 50%;
                        left: 5%;
                        z-index: 10;
                        width: 90%;
                        height: @counter-height;
                        max-height: 90%; // for small circles
                        background-color: inherit;
                        border-radius: 10px;
                        margin-top: -(@counter-height / 2);
                    }

                    &.done,
                    &.current {
                        .circle {
                            color: #FFF;
                            background-color: @topmeteo-blue;
                        }

                        a.circle {
                            &:hover,
                            &:focus,
                            &:active {
                                background-color: @topmeteo-grey-light;
                                color: @topmeteo-blue;
                            }
                        }
                    }

                    &.current {
                        padding-top: 0;

                        .circle {
                            width: (@circle-base * 8);
                            height: (@circle-base * 8);
                            font-size: 18px;
                            padding-top: (@circle-base *2.6)
                        }

                        .step-label {
                            font-size: 18px;
                        }
                    }
                }
            } // shop-steps
        } // header

        main {
            &:extend(.clearfix all);

            float: left;
            margin-top: @topmeteo-pad;
            margin-bottom: @topmeteo-pad;
            .pad-h();
            .pad-l(2);

            *::before,
            *::after {
                font-family: 'topmeteo';
            }


/* + + +  datepicker  + + + */
            input[data-content="datepicker"] {
                position: relative;
                box-shadow: none;
                border-color: @topmeteo-blue;
                border-radius: 0;
                color: @topmeteo-blue;
                margin-top: 5px;
            }

            input[type="date"] {
                position: relative;
                box-shadow: none;
                border-color: @topmeteo-blue;
                border-radius: 0;
                color: @topmeteo-blue;
                margin-top: 5px;
            }

            input::-webkit-inner-spin-button {
                position: absolute;
                right: 0;
                top: 0;
                bottom: 0;
                height: auto;
            }


/* + + +  errorhandling  + + + */
            .errorlist,
            .non-field-errors {
                li {
                    color: @topmeteo-red;
                }
            }

            .errorlist {
                list-style-type: none;
            }

            .non-field-errors {
                padding-left: 30px;
            }


/* + + +  innercontent of 'main'  + + + */
            > div {
                &:extend(.clearfix all);

                margin: 0;
                width: 100%;
            }

            h2 {
                .content-headline-dark-on-light(@topmeteo-mobile-font-size-big, (@topmeteo-pad/2) (@topmeteo-pad/2) 0 @topmeteo-pad);
                .pad-b(.5);
            }

            p,
            li {
                color: @topmeteo-blue;
            }
        } // main

        aside {
            &:extend(.clearfix all);

            background: #fff;
            margin-bottom: 20px;
        } // aside


/* + + + + +  basket  + + + + + */
        #shopping-basket {
            > div {
                margin: @topmeteo-pad @topmeteo-pad @topmeteo-pad 0;
                border: 1px solid @topmeteo-blue;
                &:extend(.clearfix all);
            }

            h2 {
                .content-headline-light-on-dark(@topmeteo-mobile-font-size-big, (@topmeteo-pad*2/3) (@topmeteo-pad/2) (@topmeteo-pad*2/3));
                margin-bottom: 0;
            }

            .table-wrapper {
                .pad(.5);
            }

            .shopping-basket-table {
                width: 100%;
                font-size: (@topmeteo-font-size + 2);

                tr {
                    .price {
                        padding-left: 10px;
                        text-align: right;
                    }

                    td,
                    th {
                        color: @topmeteo-blue;
                    }

                    &.region,
                    &.duration,
                    &.start-date {
                        td:first-of-type {
                            .pad-l(.5);
                        }
                    }

                    &.total {
                        td {
                            .pad-t(.25);
                        }
                    }
                }

                .divider {
                    height: (@topmeteo-pad / 2);
                    border-bottom: 1px solid @topmeteo-blue;
                }
            }
        }

        .icon-link {
            &:extend(.icon-basket all);

            font-size: 130%;
            font-weight: bold;
            float: right;
            margin-top: -3px;
            margin-right: -5px;
        }

        .shopping-content {
            display: table;
            border: 1px solid @topmeteo-blue;

            .info {
                p.retry {
                    display: flex;
                    align-items: center;

                    a.btn-primary.btn {
                        border-radius: 0;
                        margin-left: .7em;
                    }
                }
            }

            #id_options-options {
                position: relative;

                li {
                    label {
                        clear: both;
                        min-width: 17rem;
                        width: 50%;
                    }
                }

                .options-details {
                    margin-right: 10rem;
                    padding-bottom: .5rem;
                }

                a.details-link {
                    position: absolute;
                    top: -.3rem;
                    right: 0;
                    border-radius: 0;
                    padding: 2px;
                    .pad-h(.25);

                    + p.details:not([class~="hidden"]) {
                        clear: both;
                        padding-top: .5rem;
                    }
                }
            }

            // needed ?
            .is-table& {
                border: 0 none;
            }

            .shop-content,
            .shop-head,
            .shop-intro {
                display: table-row;
            }

            .shop-head h2,
            .shop-intro > div,
            .shop-selection,
            #shop-selection,
            #shop-selection2,
            .region-map {
                display: table-cell;
            }

            .shop-intro {
                > div {
                    padding: (@topmeteo-pad) 0;
                }
            }

            .existing-customer,
            .new-customer {
                border-top: 1px solid @topmeteo-blue-light;
            }

            .new-customer {
                border-right: 1px solid @topmeteo-blue-light;
            }

            #shop-selection,
            .shop-selection,
            #shop-selection2,
            .region-map {
                display: table-cell;
                vertical-align: top;
                padding: (@topmeteo-pad/2) (@topmeteo-pad/2) @topmeteo-pad @topmeteo-pad;
            }

            #shop-selection,
            .shop-selection,
            #shop-selection2 {
                padding-right: @topmeteo-pad;
                text-align: left;

                p label {
                    display: none;
                }

                > p {
                    margin: 0;
                    padding-right: 0;
                    padding-left: 0;
                }

                .duration,
                .account-step {
                    padding-top: @topmeteo-pad;

                    p {
                        width: 50%;
                        float: left;
                        padding: 0 (@topmeteo-pad/2) @topmeteo-pad 0;

                        &.intro {
                            float: none;
                            width: auto;
                        }

                        input,
                        select {
                            height: 30px;
                            text-indent: 4px;
                            width: 100%;
                        }

                        + p {
                            padding-right: 0;
                        }
                    }

                    label {
                        display: block;
                    }
                }

                .account-step {
                    p {
                        width: 100%;
                        padding-right: 0;
                        padding-left: 0;
                    }
                }

                .option-step {
                    padding-top: @topmeteo-pad;

                    + .form {
                        padding-top: (@topmeteo-pad/2);
                    }

                    > label {
                        display: none;
                    }

                    // voucher
                    p label {
                        display: inline-block;
                        margin-top: @topmeteo-pad;
                        margin-right: @topmeteo-pad;

                        + input {
                            .pad-l(.25);
                        }
                    }

                    .tabs {
                        clear: both;
                        margin-top: 8em;

                         > ul {
                            margin: 0;
                            padding: 0;
                            list-style: none;
                            display: flex;
                            font-size: 16px;
                            border-bottom: 1px solid grey;

                            li {
                                padding: .5em 1em;
                                margin-bottom: -1px;
                                border: 1px solid transparent;
                                border-top-left-radius: .5em;
                                border-top-right-radius: .5em;

                                &.active {
                                    border-color: grey;
                                    border-bottom-color: white;
                                }

                                a {
                                    text-decoration: none;
                                }
                            }
                        }

                        .tab {
                            display: none;

                            &.active {
                                display: block;
                            }
                        }
                    }
                }
            }

            .form {
                clear: left;
                padding: 0;

                button,
                a.button-submit {
                    float: right;
                    margin-right: -(@topmeteo-pad);
                    margin-bottom: -(@topmeteo-pad);
                }
            }


/* + + + + +  duration radio list  + + + + + */
            #shop-selection {
                fieldset.duration {
                    p {
                        float: none;
                        width: 100%;
                    }

                    .is-halfsize {
                        display: inline-block;
                        width: 49.5%;
                        box-sizing: border-box;
                        vertical-align: top;

                        > p {
                            font-weight: bold;
                            padding-bottom: 0;
                            padding-right: 0;
                        }
                    }
                }

                #id_duration-start_date,
                #id_duration-duration {
                    clear: both;
                    margin: 0;
                    padding: 0;

                    li {
                        display: block;

                        label {
                            position: relative;
                            display: inline-block;
                            min-width: 7em;
                            padding-left: 18px;

                            input {
                                position: absolute;
                                left: 0;
                                top: -3px;
                                background-color: transparent;

                                &[type="checkbox"],
                                &[type="radio"] {
                                    top: 0;
                                }
                            }
                        }
                    }
                }
            } // shop-selection
        }

/* + + + + +  region radio list  + + + + + */
        #id_region-region,
        #id_options-options {
            margin: 0;
            padding: 0;
            list-style: none inside;

            li {
                margin: 0;
                padding: 0;
                display: inline-block;
                float: left;

                label {
                    position: relative;
                    padding-left: 18px;
                    padding-right: 6px;

                    input {
                        position: absolute;
                        left: 0;
                        top: -3px;

                        &[type="checkbox"],
                        &[type="radio"] {
                            top: 0;
                        }
                    }
                }
            }
        }
    }


/* + + + + + +  content looks like 'shopping basket'  + + + + + */
    &.is-basketstyle {
        .content-area {
            .shopping-content {
                border: 0 none;

                .shop-head {
                    display: block;

                    h2 {
                        .content-headline-light-on-dark(@topmeteo-mobile-font-size-big, (@topmeteo-pad*2/3) @topmeteo-pad);

                        display: block;
                        margin-bottom: 0;
                    }
                }

                .shop-content {
                    display: block;
                }

                /* form */
                #shop-selection {
                    display: block;
                    margin: 0;
                    padding: 0;

                    button[type="submit"] {
                        float: none;
                        width: 100%;
                        margin-top: (@topmeteo-pad * 2);
                    }
                }
            }
        }
    }


/* + + + + + +  content looks like 'standard'  + + + + + */
    &.is-standard {
        .content-area {
            .shopping-content {
                background-color: transparent;
                border: 0 none;

                .shop-head {
                    display: block;

                    h2 {
                        .content-headline(@topmeteo-mobile-font-size-big, (@topmeteo-pad*2/3) 0);
                    }
                }

                .info {
                    margin-bottom: (@topmeteo-pad * 2);
                }
            }
        }
    }
}


/* + + + + + + + + + + + + + + + + +
 * DESKTOP ONLY (1050)
 * + + + + + + + + + + + + + + + + */

@media (min-width: @topmeteo-site-width) {
    @aside-width: 30%;

    body.shop.order {

        /* The is-standard pages do not have a
           basket and could span the whole width. */
        &.is-standard {
            .content-area {
                main {
                    width: 100%;
                }
            }
        }

        .content-area {
            aside {
                width: @aside-width;
                float: right;

                &#shopping-basket {
                    display: block !important;
                }
            }

            main {
                width: (100% - @aside-width);

                .errorlist {
                    padding-left: 0;
                }
            }

            .shopping-content {
                #shop-selection,
                .shop-selection,
                #shop-selection2 {
                    margin-top: (@topmeteo-pad/2);

                    p.status {
                        text-align: right;
                    }

                    .duration {
                        padding-top: @topmeteo-pad;

                        p {
                            width: 50%;
                            float: left;
                            padding: 0 (@topmeteo-pad/2) @topmeteo-pad 0;

                            &.intro {
                                float: none;
                                width: auto;
                            }

                            input,
                            select {
                                height: 30px;
                                text-indent: 4px;
                                width: 100%;
                            }

                            + p {
                                padding-right: 0;
                            }
                        }

                        label {
                            display: block;
                        }
                    }
                }
            }

            #id_options-options {
                li {
                    width: 100%;

                    .options-details {
                        display: block;
                        float: right;
                    }

                    .price {
                        display: inline-block;
                        width: 6rem;
                        box-sizing: border-box;
                        text-align: right;
                        .pad-r(.5)
                    }

                    a.details {
                        text-decoration: underline;

                        &:hover,
                        &:focus,
                        &:active {
                            text-decoration: none;
                        }
                    }
                }
            }
        } // content-area


/* + + + + + +  content looks like 'shopping basket'  + + + + + */
        &.is-basketstyle {
            .content-area {
                .shopping-content {
                    @form-width: 50%;

                    .shop-head {
                        width: @form-width;
                    }

                    /* form */
                    #shop-selection {
                        width: @form-width;

                        button[type="submit"] {
                            margin-top: (@topmeteo-pad * 2);
                        }
                    }
                }

                main {
                    > div {
                        width: 100%;
                    }
                }
            }
        }
    }
}


/* + + + + + + + + + + + + + + + + +
 * MOBILE ONLY (1049)
 * + + + + + + + + + + + + + + + + */

@media (max-width: @topmeteo-site-width-max) {
    body.shop.order {
        .content-area {
            header {
                ul.shop-steps {
                    max-width: 90%;
                    margin: 16px auto;
                    padding: 0;
                    float: none;

                    .circle {
                        width: (@circle-base*4);
                        height: (@circle-base*4);
                        padding-top: (@circle-base);
                    }

                    .step-label {
                        display: none;
                    }

                    li {
                        padding-top: (@circle-base*2/3);

                        &::before {
                            top: (@circle-base*2.466);
                        }

                        &.current {
                            .circle {
                                width: (@circle-base*5);
                                height: (@circle-base*5);
                                padding-top: (@circle-base);
                            }
                        }

                        &.done {
                            .circle { }
                        }
                    }
                }
            }

            main {
                padding-left: 0;
                padding-right: 0;
                width: 100%;

                .errorlist {
                    .pad-b(.5);
                }

                > div {
                    margin-left: 0;
                    margin-right: 0;
                }
            }

            .basket-toggle {
                .toggle();

                z-index: 2000;
                top: 0;
                right: 0;

                .icon {
                    cursor: pointer;
                }
            }

            aside {
                width: (100%/2);
                position: absolute;
                top: 0;
                right: 0;
                z-index: 1000;

                &#shopping-basket {
                    display: none;

                    > div {
                        margin: 0;
                    }
                }
            }

            .shopping-content {
                width: 94%;
                margin-left: 3%;

                h2 {
                    font-size: (@topmeteo-mobile-font-size-big + 4);
                }

                li,
                p,
                label {
                    font-size: @topmeteo-mobile-font-size-small;
                }

                /* region radio list */
                #id_region-region,
                #id_options-options {
                    li {
                        label {
                            input {
                                top: 0;
                            }
                        }
                    }
                }

                #id_options-options {
                    li {
                        width: 100%;

                        label {
                            width: 70%;
                            max-width: 40rem;
                        }

                        .options-details {
                            display: block;
                            float: right;
                        }
                    }
                }

                #shop-selection {
                    #id_duration-start_date,
                    #id_duration-duration {
                        li {
                            label {
                                input {
                                    top: 0;
                                }
                            }
                        }
                    }
                }
            }
        } // content-area


/* + + + + + +  content looks like 'shopping basket'  + + + + + */
        &.is-basketstyle {
            .content-area {
                @form-width: 100%;
                @form-maxwidth: 480px;

                .shopping-content {

                    /* form */
                    .form {
                        border: solid 1px @topmeteo-blue;
                        border-top: 0 none;
                        .pad();

                        button[type="submit"] {
                            margin: 0;
                        }
                    }
                }

                main {
                    > div {
                        width: @form-width;
                        max-width: @form-maxwidth;
                        margin-left: auto;
                        margin-right: auto;
                    }
                }
            }
        }


/* + + + + + +  content looks like 'standard'  + + + + + */
        &.is-standard {
            .content-area {
                .shopping-content {
                    .shop-head {
                        h2 {
                            .content-headline(@topmeteo-headline-font-size, (@topmeteo-pad*2/3) @topmeteo-pad (@topmeteo-pad*1.5));
                        }
                    }

                    .info {
                        .pad-h();
                    }

                    p,
                    .errorlist li {
                        font-size: @topmeteo-mobile-font-size;
                    }
                }
            }
        }
    }
}


/* + + + + + + + + + + + + + + + + +
 * ADDITIONAL BREAKPOINTS
 * + + + + + + + + + + + + + + + + */

@media (max-width: 600px) {
    body.shop.order {

        /* datepicker */
        input[type="date"] {
            min-width: 94.5%;
            border-radius: 0;
        }

        .content-area {
            header {
                ul.shop-steps {
                    width: 100%;
                    max-width: 100%;
                    padding: @topmeteo-pad 0;

                    .circle {
                        width: (@circle-base * 2 + 4);
                        height: (@circle-base * 2 + 4);
                        padding-top: 1px;
                    }

                    li {
                        padding-top: (@circle-base*.9);

                        &::before {
                            top: (@circle-base*1.8);
                        }

                        &.current {
                            .circle {
                                width: (@circle-base*4);
                                height: (@circle-base*4);
                                padding-top: (@circle-base*.6);
                            }
                        }

                        &.done {
                            padding-top: (@circle-base*.55);

                            .circle {
                                width: (@circle-base*3);
                                height: (@circle-base*3);
                                padding-top: (@circle-base*2/3);
                            }
                        }
                    }
                }
            }

            aside {
                width: 100%;

                &#shopping-basket {
                    > div {
                        border-left: 0 none;
                        border-right: 0 none;
                    }
                }
            }

            .shopping-content {
                display: block;
                border: 0 none;
                background-color: #fff;

                li,
                p,
                label {
                    font-size: @topmeteo-mobile-font-size;
                }

                #id_options-options {
                    li {
                        label {
                            width: 100%;
                            max-width: none;
                            padding-right: 0;
                        }

                        .options-details {
                            float: none;
                            margin-right: 0;
                        }

                        a.details-link {
                            top: 2.3rem;
                        }
                    }
                }

/* + + + + +  duration radio list  + + + + + */
                #shop-selection {
                    fieldset.duration {
                        .is-halfsize {
                            display: block;
                            width: 100%;

                            + .is-halfsize {
                                margin-top: @topmeteo-pad;
                                .pad-t(.5);
                            }
                        }

                        #id_duration-start_date,
                        #id_duration-duration {
                            li {
                                span {
                                    float: right;
                                }

                                label {
                                    display: block;
                                    font-size: @topmeteo-mobile-font-size;

                                    input {
                                        top: 1px;
                                    }

                                    .options-details {
                                        float: right;
                                    }

                                    &[for=id_duration-start_date_1] {
                                        display: inline-block;
                                    }
                                }
                            }
                        }
                    }

                    .errorlist {
                        .pad-b(0);

                        li {
                            border: 0 none;
                            padding: 0;
                        }
                    }
                }

                #shop-selection,
                .shop-selection,
                #shop-selection2 {
                    display: block;
                    border: 1px solid @topmeteo-blue;

                    .duration p {
                        width: 100%;
                        float: none;
                    }

                    label,
                    span.price,
                    a.details {
                        font-size: @topmeteo-mobile-font-size;
                    }

                    input::-webkit-inner-spin-button {
                        height: 100%;
                    }

                    // voucher
                    .option-step {
                        p label {
                            display: block;
                            margin-right: 0;

                            + input {
                                min-height: 40px;
                                width: 100%;
                            }
                        }
                    }
                }

                #shop-selection2 {
                    border-top: 0 none;
                }

                .shop-head {
                    display: block;
                    text-align: center;
                    margin: 0 @topmeteo-pad;

                    h2 {
                        .content-headline-light-on-dark(@topmeteo-headline-font-size, (@topmeteo-pad*2/3) @topmeteo-pad);

                        display: block;
                        margin-bottom: 0;
                    }
                }

                .shop-intro {
                    display: block;

                    > div {
                        display: block;
                    }
                }

                .shop-content {
                    display: block;
                    margin-left: @topmeteo-pad;
                    margin-right: @topmeteo-pad;
                }

                .form {
                    margin: 0;

                    button {
                        float: none;
                        clear: both;
                        margin: @topmeteo-pad 0 0;
                        display: block;
                        text-align: center;
                        width: 100%
                    }
                }
            }
        }


/* + + + + + +  content looks like 'shopping basket'  + + + + + */
        &.is-basketstyle {
            .content-area {
                .shopping-content {
                    .shop-head {
                        h2 {
                            font-size: @topmeteo-headline-font-size;
                        }
                    }

                    #shop-selection {
                        border: 0 none;
                    }

                    .shop-content {
                        margin-left: @topmeteo-pad;
                        margin-right: @topmeteo-pad;
                    }
                }

                main > div {
                    max-width: 100%;
                }
            }
        }


/* + + + + + +  content looks like 'shopping basket'  + + + + + */
        &.is-standard {
            .content-area {
                .shopping-content {
                    .shop-head {
                        h2 {
                            .content-headline-light-on-dark(@topmeteo-headline-font-size, (@topmeteo-pad*2/3) @topmeteo-pad);

                            margin-bottom: 0;
                        }
                    }

                    #shop-selection {
                        border: 0 none;
                    }

                    .shop-content {
                        border: solid 1px @topmeteo-blue;
                        margin-left: @topmeteo-pad;
                        margin-right: @topmeteo-pad;
                    }

                    .errorlist {
                        padding-bottom: 0;
                        .pad-t();
                        .pad-r();
                    }

                    .info {
                        margin-bottom: 0;
                        .pad-v();
                    }
                }

                main > div {
                    max-width: 100%;
                }
            }
        }
    }
}
