/**
 * @file template module shop: step region
 * ......
 * scope:
 *   - shop
 *
 * @copyright CosmoCode GmbH
 */

@import (reference) "../setup";
@import (reference) "../helpers/content";
@import (reference) "../helpers/interactiveElems";
@import (reference) "../helpers/metSelect";
@import (reference) "../helpers/raster";


/* + + + + + + + + + + + + + + + + +
 * DESKTOP + MOBILE
 * + + + + + + + + + + + + + + + + */

body.shop {
    .content-area .shopping-content {
        @form-min-height: 310px;

        &.is-region {
            table-layout: fixed;

            form#shop-selection {
                position: relative;
                display: block;
                min-height: @form-min-height;
                box-sizing: border-box;
                border: solid 1px @topmeteo-blue;
                border-top: 0 none;
                margin: 0;

                button[type="submit"] {
                    position: absolute;
                    right: @topmeteo-pad;
                    bottom: @topmeteo-pad;
                }
            }

            .region-step {
                label {
                    white-space: nowrap;
                }
            }

            .region-domain-list,
            .region-list {
                display: flex;
                list-style: none;
                padding: 0;
            }

            .region-domain-entry {
                flex: 1 1 100%;
            }

            .region-list {
                flex-direction: column;
            }
        }

        .region-map {
            position: relative;
            top: -3.6rem;
            padding-top: 0;


            h3 {
                color: @topmeteo-blue;
                margin: @topmeteo-pad 0;

                a {
                    &:extend(.icon-left-open-big all);
                }
            }

            img {
                width: 100%;
                height: auto;
            }

            .region-image {
                > a {
                    display: block;

                    &.world {
                        min-height: @form-min-height;
                        display: flex;
                        align-items: center;

                        img {
                            flex: 0 0 100%;
                        }
                    }
                }
            }

/* + + +  backlink  + + + */
            .backlink a {
                &:extend(.icon-left-open-big all);

                font-size: (@topmeteo-mobile-font-size-big - 4);

                &::before {
                    .pad-r(.5);
                }

                &:hover,
                &:focus,
                &:active {
                    text-decoration: none;
                }
            }


/* + + +  lightbox current region as text  + + + */
            .current-region {
                display: none;
            }


/* + + +  lightbox function  + + + */
            .toggle-map {
                .toggle-map-detail {
                    display: none;
                }

                /* region 'imagemap function' */
                &.region-image {
                    position: relative;
                    padding: 0;

                    /* lightbox close-button */
                    .back {
                        .close-link();

                        top: -60px;
                        right: -31px;
                        display: none;
                        font-size: 30px;
                        background-color: @topmeteo-blue;
                        color: #fff;

                        &:hover,
                        &:focus,
                        &:active {
                            background-color: @topmeteo-blue-darkest;
                        }
                    }

                    /* lightbox shown */
                    &.active {
                        .backlink a,
                        .backlink + a {
                            cursor: default;
                            pointer-events: none;
                            z-index: -1;

                            &::before {
                                display: none;
                            }
                        }

                        .backlink a {
                            font-size: @topmeteo-mobile-font-size-big;
                        }

                        .toggle-map-detail {
                            display: block;
                        }
                    }
                } // region-image
            } // toggle-map
        } // region-map

        #id_region-region {
            li {
                label {
                    width: 100%;
                }
            }
        }
    }
}


/* + + + + + + + + + + + + + + + + +
 * DESKTOP ONLY (601)
 * + + + + + + + + + + + + + + + + */

@media (min-width: 601px) {
    body.shop.order {
        .content-area .shopping-content {
            &.is-region {
                border: 0 none;

                .shop-head {
                    display: block;
                    border: solid 1px @topmeteo-blue;
                    border-bottom: 0 none;
                }
            }

            .region-map {
                padding-right: 0;
                padding-bottom: 0;

                .region-image {
                    &.active {
                        .back {
                            display: inline-block;
                        }
                    }
                }

                h3 {
                    .content-headline-light-on-dark((@topmeteo-mobile-font-size + 2), (@topmeteo-pad * 2 / 3) @topmeteo-pad);
                    margin-bottom: 0;

                    a {
                        cursor: default;
                        pointer-events: none;
                        z-index: -1;
                        color: #fff;

                        &::before {
                            display: none;
                        }
                    }
                }
            }

/* + + +  lightbox function  + + + */
            .toggle-map {
                display: none;

                /* lightbox open */
                &.active {
                    position: fixed;
                    top: 5vh;
                    left: 50%;
                    z-index: 2002;
                    display: block;
                    width: 80%;
                    max-width: 80vh;
                    height: auto;
                    max-height: 80vh;
                    box-sizing: content-box;
                    background-color: #fff;
                    border: 30px solid #fff;
                    border-top-width: 5em;
                    transform: translateX(-50%);
                    -ms-transform: translateX(-50%);
                    -webkit-transform: translateX(-50%);

                    img {
                        display: block;
                        width: auto;
                        height: auto;
                        max-width: 100%;
                        max-height: 100%;
                        margin-left: auto;
                        margin-right: auto;
                    }

                    a {
                        max-width: 80vh;
                        max-height: 80vh;

                        img {
                            max-width: 80vh;
                            max-height: 80vh;
                        }
                    }

                    > img {
                        display: none;
                    }

                    /* transparent background */
                    + .lightbox-overlay {
                        position: fixed;
                        top: 0;
                        left: 0;
                        width: 100%;
                        height: 100%;
                        background-color: rgba(20, 20, 20, .5);
                        z-index: 2001;
                    }
                }
            }

            .toggle-map-detail {
                z-index: 2002;

                a {
                    position: relative;
                    top: 0;
                    left: 0;
                    display: block;
                    background-color: #fff;

                    img {
                        width: 100%;
                        height: auto;
                    }
                }

/* + + +  backlink  + + + */
                .backlink {
                    position: absolute;
                    top: -50px;
                    left: 0;
                    display: block;
                    width: 100%;
                    height: auto;
                    z-index: 2;

                    a {
                        position: static;
                        box-shadow: none;
                        display: inline-block;
                        height: auto;
                        width: auto;
                    }
                }
            }
        }
    }
}


/* + + + + + + + + + + + + + + + + +
 * DESKTOP ONLY (1050)
 * + + + + + + + + + + + + + + + + */

@media (min-width: @topmeteo-site-width) {
    body.shop.order {
        .content-area .shopping-content {
            &.is-region {
                #shop-selection {
                    .region-step {
                        .status {
                            text-align: center;
                        }
                    }
                }
            }

            .region-map {
                position: relative;
                padding-top: 0;
            }

            /* region - radio list */
            #id_region-region {
                li {
                    width: 33%;
                }
            }
        }
    }
}



/* + + + + + + + + + + + + + + + + +
 * MOBILE ONLY (1049)
 * + + + + + + + + + + + + + + + + */

@media (max-width: @topmeteo-site-width-max) {
    body.shop.order {
        .content-area .shopping-content {
            &.is-region {
                .shop-head {
                    display: block;
                    border: solid 1px @topmeteo-blue;
                    border-bottom: 0 none;
                }

                form#shop-selection {
                    button[type="submit"] {
                        position: absolute;
                        right: @topmeteo-pad;
                        bottom: @topmeteo-pad;
                    }
                }
            }

            .region-map {
                padding-top: 0;
                padding-bottom: 0;

                h3 {
                    font-size: @topmeteo-headline-font-size;
                    margin-top: -5px;

                    a {
                        font-size: (@topmeteo-mobile-font-size-big - 2);
                    }
                }
            }

            /* lightbox fuction */
            .toggle-map-detail {

                /* backlink */
                p a {
                    left: 10%;
                    width: 80%;
                }
            }

            #id_region-region {
                li {
                    width: 33%;
                }
            }
        }
    }
}


/* + + + + + + + + + + + + + + + + +
 * ADDITIONAL BREAKPOINTS
 * + + + + + + + + + + + + + + + + */

@media (max-width: 850px) {
    body.shop.order {
        .content-area .shopping-content {
            &.is-region {
                .region-domain-list {
                    flex-direction: column;
                }
            }

            #id_region-region {
                li {
                    width: 50%;
                }
            }
        }
    }
}

@media (max-width: 600px) {
    body.shop.order {
        .content-area .shopping-content {
            &.is-region {
                form#shop-selection {
                    button[type="submit"] {
                        position: static;
                    }
                }
            }

            .region-map {
                display: none;
                top: 2em;
                padding-left: 0;
                padding-right: 0;

                h3 {
                    margin-top: @topmeteo-pad;

                    a {
                        &::before {
                            .pad-r(.5);
                        }

                        &:hover,
                        &:focus,
                        &:active {
                            text-decoration: none;
                        }
                    }
                }

                .toggle-map {
                    display: block;
                }

                /* lightbox function */
                .toggle-map-detail {
                    position: absolute;
                    top: 0;
                    bottom: -2px;
                    left: 0;
                    right: 0;
                    z-index: 1;
                    background: #fff;
                    text-align: center;

                    img {
                        max-height: 100%;
                        max-width: 100%;
                        width: auto;
                        margin-left: auto;
                        margin-right: auto;
                    }

                    /* backlink */
                    p {
                        display: none;
                    }
                }

                img {
                    width: 100%;
                    height: auto;
                }
            }

            #id_region-region {
                li {
                    width: 49%;

                    &:nth-child(odd) {
                        margin-right: 1.99%;
                    }
                }
            }
        }
    }
}
