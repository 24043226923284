/**
 * Mixins for content area
 */

.desktop-content-full(){
    .content-area{
        margin: 20px 0;
        .row{
            text-align: center;
        }
    }
}

.desktop-content-full-center-image(){
    .content-area {
        .row {
            #content {
                img {
                    background-color: #fff;
                    display: block;
                    margin: 0 auto;
                }
            }
        }
    }
}

.desktop-content-full-center-table(){
    .content-area {
        .row {
            #content {
                table, caption {
                    text-align: left;
                    background-color: #fff;
                    margin: 0 auto;
                    font-size: 12px;
                }
                caption {
                    white-space: nowrap;
                }
                td{
                    p{
                        display: inline;
                    }
                }
            }
        }
    }
}

/**
* Pseudo Table Container
*/
.desktop-table-container(){
    .t-container{
        display: table;
        width: 100%;
        .t-row{
            display: table-row;
        }
        .t-cell{
            display: table-cell;
            padding-right: 10px;
        }
    }
}

/**
* Legend Definition List
*/
.desktop-legend-dl(){
    .legend{
        dl{
            text-transform: uppercase;
            dt{
                width: 25px; height: 25px; float: left;
                clear: left;
            }
            dd{
                min-height: 25px; padding-top: 3px;
            }
        }
    }
}


.webkit-scrollbar(@background: @bgScroll){
    overflow-x: auto;
    overflow-y: hidden;

    &::-webkit-scrollbar {
        -webkit-appearance: none;
    }

    &::-webkit-scrollbar:vertical {
        width: 11px;
    }

    &::-webkit-scrollbar:horizontal {
        height: 11px;
    }

    &::-webkit-scrollbar-thumb {
        border-radius: @topmeteo-pad/2;
        border: ~"2px solid @{background}"; /* should match background, can't be transparent */
        background-color: rgba(90, 122, 141, .8);
    }

    &::-webkit-scrollbar-track {
        background-color: @background;
        border-radius: 0px;
    }
}

.icon-font-basics(){

        font-family: "topmeteo";
        font-style: normal;
        font-weight: normal;
        speak: none;

        display: inline-block;
        text-decoration: inherit;
        width: 1em;
        margin-right: .2em;
        text-align: center;
        /* opacity: .8; */

        /* For safety - reset parent styles, that can break glyph codes*/
        font-variant: normal;
        text-transform: none;

        /* fix buttons height, for twitter bootstrap */
        line-height: 1em;

        /* Animation center compensation - margins should be symmetric */
        /* remove if not needed */
        margin-left: .2em;

        /* you can be more comfortable with increased icons size */
        /* font-size: 120%; */

        /* Font smoothing. That was taken from TWBS */
        -webkit-font-smoothing: antialiased;
        -moz-osx-font-smoothing: grayscale;
}


.content-headline(@font-size, @padding){
    font-size: @font-size;
    font-weight: 400;
    padding: @padding;
    margin: 0 0 @line-height-computed 0;
}

.content-headline-light-on-dark(@font-size: @topmeteo-headline-font-size, @padding: @line-height-computed @topmeteo-pad (@line-height-computed *2/3)){
    .content-headline(@font-size, @padding);
    background-color: @topmeteo-blue;
    color: #fff;
}

.content-headline-dark-on-light(@font-size: @topmeteo-headline-font-size,@padding: @line-height-computed @topmeteo-pad (@line-height-computed *2/3)){
    .content-headline(@font-size, @padding);
    background-color: transparent;
    color: @topmeteo-blue;
}
