/**
 * @file template module shop
 * ......
 * scope:
 *   - shop
 *
 * @copyright CosmoCode GmbH
 */

@import (reference) "../setup";
@import (reference) "../helpers/content";
@import (reference) "../helpers/interactiveElems";
@import (reference) "../helpers/metSelect";
@import (reference) "../helpers/raster";

@packet-height: 178px;
@circle-base: 9px;


/* + + + + + + + + + + + + + + + + +
 * DESKTOP + MOBILE
 * + + + + + + + + + + + + + + + + */

body.shop {
    .payment-notifications {
        margin-top: 20px;

        .notifications-inner {
            display: flex;
            flex-direction: column;
            justify-content: center;
        }

        a {
            display: inline;
        }
    }

    .content-area {
        margin-top: 20px;

        .row {
            background-color: #fff;
        }

/* + + +  content header with headline  + + + */
        header {
            h1 {
                .content-headline-dark-on-light();

                &.dark {
                    .content-headline-light-on-dark();
                }
            }
        }

/* + + +  footnotes  + + + */
        footer {
            .pad-h();

            h3 {
                .pad-b();
            }

            .footnote-list {
                padding: 0 0 (@topmeteo-pad / 2) 2rem;
            }

            .footnote-entry {
                .pad-b(.5);

                font-size: (@topmeteo-font-size + 2);
            }

            .footnote-content {
                font-size: @topmeteo-font-size;
            }
        }
    }

    .container {
        position: relative;

        main {
            clear: left;
        }
    }
}


/* + + + + + + + + + + + + + + + + +
 * DESKTOP ONLY
 * + + + + + + + + + + + + + + + + */

@media (min-width: @topmeteo-site-width) {}


/* + + + + + + + + + + + + + + + + +
 * MOBILE ONLY
 * + + + + + + + + + + + + + + + + */

@media (max-width: @topmeteo-site-width-max) {
    body.shop {
        #mobile-region-select {
            display: none;
        }

        .content-area {
            margin-top: 0;

            .container {
                margin-top: 0;
            }

            header {
                h1,
                h1.dark {
                    background-color: #fff;
                    color: @topmeteo-blue;
                    text-align: center;
                    margin-left: 40px;
                    margin-right: 40px;
                    padding-top: (@topmeteo-pad/2);
                }
            }

/* + + +  footnotes  + + + */
            footer {
                h3 {
                    .pad-t();
                }

                .footnote-entry {
                    font-size: @topmeteo-mobile-font-size;
                }

                .footnote-headline {
                    font-size: @topmeteo-mobile-font-size-big;
                }

                .footnote-content {
                    font-size: @topmeteo-mobile-font-size;
                }
            }
        }
    }
}
