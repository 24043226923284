/**
 * @file template module shop: step verification
 * ......
 * scope:
 *   - shop
 *
 * @copyright CosmoCode GmbH
 */

@import (reference) "../setup";
@import (reference) "../helpers/content";
@import (reference) "../helpers/interactiveElems";
@import (reference) "../helpers/metSelect";
@import (reference) "../helpers/raster";


/* + + + + + + + + + + + + + + + + +
 * DESKTOP + MOBILE
 * + + + + + + + + + + + + + + + + */

body.shop {
    .content-area .shopping-content {
        &.is-verification {
            .form {
                button[type="submit"] {
                    box-shadow: inset 0 -13px 15px 0 @icon-orange;
                    background-color: @topmeteo-yellow;
                    border: solid 1px @topmeteo-blue-darkest;
                    color: @topmeteo-blue-darkest;
                    margin-right: 0;
                    margin-bottom: @topmeteo-pad;

                    &:hover,
                    &:focus,
                    &:active {
                        box-shadow: inset 0 0 15px 0 @icon-orange;
                    }

                    &:disabled {
                        opacity: .3;
                    }
                }

                .terms-of-service,
                .psp-message {
                    padding: @topmeteo-pad / 2;

                    span {
                        white-space: nowrap;
                    }
                }

                .terms-of-service {
                    margin-top: 1.5em;

                    label {
                        position: relative;
                        font-size: 12px;
                        .pad-l(1.5);
                    }

                    input {
                        &[type="checkbox"] {
                            position: absolute;
                            left: 0;
                            top: 0;

                            animation: pulsate 2s ease-in-out infinite;

                            @keyframes pulsate {
                                0%   { box-shadow: 0 0 5px 0 @icon-orange; }
                                50%  { box-shadow: 0 0 5px 2px @icon-orange; }
                                100% { box-shadow: 0 0 5px 0 @icon-orange; }
                            }
                        }
                    }
                }

                .psp-message {
                    display: none;
                }
            }

            #shop-selection {
                .payment-method {
                    color: @topmeteo-blue;
                    padding: @topmeteo-pad / 2;
                    margin-top: 2.5em;

                    legend {
                        color: inherit;
                        border: none;
                        margin: 0;
                    }

                    .methods {
                        label {
                            width: 100%;
                            display: flex;
                            align-items: center;
                            margin-top: @topmeteo-pad;
                            margin-right: @topmeteo-pad;

                            input {
                                float: none;
                            }

                            .logo-wrapper {
                                overflow: hidden;
                            }

                            img {
                                height: @topmeteo-pad * 2;

                                &.mastercard {
                                    width: auto;
                                    height: (@topmeteo-pad * 2) + 10; // 10px higher
                                    margin-bottom: -6px; // from 10px (6 + 4)
                                    margin-top: -4px; // from 10px (6 + 4)
                                    margin-left: 1px; // optical correction
                                }
                            }
                        }
                    }
                }

                .terms-of-service {
                    label {
                        display: block;
                    }
                }
            }
        }

        .verification-step {
            border: solid 1px @topmeteo-blue;
            font-size: (@topmeteo-font-size + 2);
        }


/* + + + + +  logolist  + + + + + */
        .paymentlist {
            margin-top: (@topmeteo-pad * 3);
            margin-bottom: @topmeteo-pad;

            .sr-out {
                position: absolute;
                left: -10000em;
                top: -10000em;
                display: block;
                width: 1px;
                height: 1px;
                overflow: hidden;
            }

            h3 {
                .content-headline(@topmeteo-mobile-font-size-big, (@topmeteo-pad/2) (@topmeteo-pad/2) 0 @topmeteo-pad);

                color: @topmeteo-blue;
                padding-left: 0;
                .pad-v();
            }

            ul {
                margin: 0;
                padding: 0;
            }

            li {
                list-style-type: none;
                display: inline-block;
                width: 45px;
                height: 30px;
                overflow: hidden;
                box-sizing: border-box;
                vertical-align: top;

                &::before {
                    width: auto;
                    font-size: 30px;
                    margin: 0;
                }

                &.icon-cc-sepa {
                    width: auto;

                    &::before {
                        background-color: @topmeteo-blue;
                        border-radius: 2px;
                        color: #FFF;
                        font-size: 14px;
                        margin-top: .15rem;
                        padding: 6px 3px;
                    }
                }

                &.icon-cc-mastercard {
                    width: 43px;
                }

                &.icon-maestro {
                    width: 50px;

                    img {
                        width: auto;
                        height: 27px;
                    }
                }

                &.icon-ec {
                    width: 31px;

                    &::before {
                        font-size: 26px;
                        margin-top: 1px;
                    }
                }

                &.icon-giropay {
                    width: 70px;
                }
            }

            img {
                width: 100%;
                height: auto;
            }
        }
    }
}


/* + + + + + + + + + + + + + + + + +
 * DESKTOP ONLY (1050)
 * + + + + + + + + + + + + + + + + */

@media (min-width: @topmeteo-site-width) {
    body.shop.order {
        .content-area .shopping-content {
            &.is-verification {
                .form {
                    min-height: 150px;
                }
            }
        }
    }
}


/* + + + + + + + + + + + + + + + + +
 * MOBILE ONLY (1049)
 * + + + + + + + + + + + + + + + + */

@media (max-width: @topmeteo-site-width-max) {
    body.shop.order {
        .payment-notification {
            margin-top: 0;
            margin-bottom: 20px;
        }

        .content-area .shopping-content {
            &.is-verification {
                #shop-selection {
                    .payment-method {
                        padding: 0;
                        margin-top: 2.5em;

                        input {
                            min-width: auto;
                            min-height: auto;
                        }
                    }

                    .terms-of-service {
                        label {
                            display: block;
                        }
                    }

                    .psp-message {
                        overflow: hidden;
                        text-overflow: ellipsis;
                    }
                }
            }

            .verification-step {
                border-bottom: 0 none;
            }
        }
    }
}
