/**
 * @file cross template module for ntifications
 * ......
 * scope:
 *   - map, metar-taf,location-forecast, satellite
 *   - shop, shop-checkout
 *   - start,index
 *   - login. password-reset-confirm, user-profile
 *   - standard
 *
 * @copyright CosmoCode GmbH
 */

@import (reference) "../setup";
@import (reference) "../helpers/content";
@import (reference) "../helpers/interactiveElems";
@import (reference) "../helpers/metSelect";
@import (reference) "../helpers/raster";


/* + + + + + + + + + + + + + + + + +
 * DESKTOP + MOBILE
 * + + + + + + + + + + + + + + + + */

#user-notifications {
    position: relative;
    z-index: 15;

    body.start & {
        position: absolute;
    }

    .expiring-subscription {
        .notifications-inner {
            a {
                display: inline-block;
            }

            .close {
                display: none;
            }
        }
    }
}

.notifications-inner {
    position: relative;
    width: 100%;
    overflow: hidden;
    background-color: @topmeteo-blue-shade;
    border: 0 none;
    border-top: 1px solid @topmeteo-blue-shade;
    border-radius: 0;
    color: #fff;
    margin-bottom: 0;
    .pad-v();
    .pad-l(2);
    .pad-r(4);

    &.info, // user-profile
    .notification[class~="info"] & {
        min-height: 120px;
        background-color: @topmeteo-blue-table;

        &::before {
            content: '';
            position: absolute;
            left: 35px;
            top: 30px;
            bottom: 30px;
            display: block;
            width: 90px;
            max-height: 80px;
            background: url('~images/style/icon-attention.svg') no-repeat 0 0;
            background-size: auto 100%;
        }

        .close {
            a {
                background-color: @topmeteo-blue-table;
                border-color: @topmeteo-blue-table;

                &:hover,
                &:focus,
                &:active {
                    background-color: @topmeteo-blue-darkest;
                    border-color: @topmeteo-blue-darkest;
                }
            }
        }
    }

    &.success, // user-profile
    .notification.success &,
    .notification.packet-purchase & {
        &:extend(.icon-check all);

        &::before {
            position: absolute;
            left: 50px;
            top: 12px;
            bottom: auto;
            display: block;
            background-image: none;
            font-family: 'topmeteo';
            font-size: 24px;
        }
    }

    h3,
    p {
        margin-left: 111px; // for SVG
    }

    h3 {
        margin-top: 5px;
        margin-bottom: 5px;
    }

    p {
        font-size: (@topmeteo-font-size + 2);
        //line-height: 2; // if there is only BR permitted
        .pad-v(.25);
    }

    a {
        display: block;
        color: #fff;
        text-transform: uppercase;
        .pad-t(.5);
    }

    .close {
        .close-link();
        display: block;

        a {
            background-color: @topmeteo-blue-shade;
            border-color: @topmeteo-blue-shade;
            text-transform: none;
        }
    }


    // inverse notification (topmeteo blue auf weiss)
    &.inverse{
        padding-left: 0;
        .pad-r();
        background-color: @topmeteo-content;
        border-top-color: @topmeteo-content;

        &:first-child {
            @media (max-width: @topmeteo-site-width-max) {
                margin-top: @topmeteo-pad;
            }
        }

        &:not([class~="alert-success"]) {
            &::before {
                background-image: url('~images/style/icon-attention-dark.svg');
                left: @topmeteo-pad;

                @media (max-width: @topmeteo-site-width-max) {
                    left: 0;
                }
            }
        }

        p, li, h1,h2,h3,h4,h5,h6,a{
            color: @topmeteo-blue;
        }
    }
}

.anonymous-notice {
    .notifications-inner {
        border-top: 0 none;
        text-align: left;
    }
}


/* + + + + +  test version  + + + + + */
#test-notifications {
    left: 0;
    width: 100%;
    max-width: 1050px;
    background-color: @topmeteo-orange;
    margin: 0 auto;

    body.start &,
    body.index &,
    body.login &,
    body.shop &,
    body.pw-reset &,
    body.profile &,
    body.standard & {
        display: none;
    }

    .info {
        height: 40px;
        min-height: 40px;
        overflow: hidden;
        box-sizing: border-box;
        background-color: inherit;
        color: #FFF;
        padding: 0;

        &::before {
            top: 4px;
            width: 36px;
            height: 30px;
            max-height: none;
            color: inherit;
        }
    }

    p {
        .pad-t(.5);
        margin-left: 80px;
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;

        a {
            display: inline-block;
            padding: 0;
            .pad-l(.5);
        }
    }
}


/* + + + + + + + + + + + + + + + + + */
/* + + + +  DESKTOP  + + + + + + + + */

@media (min-width: @topmeteo-site-width) {
    #user-notifications {
        width: 1050px;
        max-width: 100%; // prevent horizontal scrollbar
    }


/* + + + + +  test version  + + + + + */
    #test-notifications {
        position: relative;
        top: 0;
        z-index: 99;
    }
}


/* + + + + + + + + + + + + + + + + + */
/* + + + + +  MOBILE ONLY  + + + + + */

@media (max-width: @topmeteo-site-width-max) {
    #user-notifications {
        width: 100%;
    }

    // bootstrap overwrite
    .start .alert-info {
        background-color: transparent;
        border-color: transparent;
    }

    /* default notification im Header */
    .notifications-inner {
        width: auto;
        border: 0 none;
        margin-left: 15px;
        margin-right: 15px;

        &:first-child {
            margin-top: 50px;
        }

        &.info, // user-profile
        .notification[class~="info"] & {
            min-height: 85px;

            &::before {
                left: 20px;
                top: 20px;
                bottom: 20px;
                width: 45px;
                height: 40px;
            }
        }

        &.success, // user-profile
        .notification.success &,
        .notification.packet-purchase & {
            &:extend(.icon-check all);

            &::before {
                top: 20px;
                left: 30px;
                font-size: 18px;
            }
        }

        h3,
        p {
            margin-left: 50px; // for SVG
        }

        p {
            line-height: 1.67;
            padding-top: 0;
        }
    }


    // only start-page for loged in user
    body.start{
        #user-notifications {
            top: 30px;
        }

        .notifications-inner {
            margin-top: 0;
            padding-right: 55px; // place for icon "!"
        }

        .notification:first-child {
            .notifications-inner {
                margin-top: 30px;
            }
        }
    }


/* + + + + +  test version  + + + + + */
    #test-notifications {
        position: fixed;
        top: (@topmeteo-pad / 2 + @topmeteo-toggle-size);
        left: (@topmeteo-toggle-size + @topmeteo-pad);
        right: (@topmeteo-toggle-size + @topmeteo-pad);
        z-index: 101;
        width: auto;
        opacity: .9;

        .info {
            margin-top: 0;

            &::before {
                left: 0;
            }
        }

        p {
            position: relative;
            padding-bottom: 0;
            margin-left: 40px;

            a {
                display: inline;

                &::before {
                    content: '';
                    position: absolute;
                    top: 0;
                    left: 0;
                    display: block;
                    width: 100%;
                    height: 100%;
                }
            }
        }
    }
}


@media (max-width: 479px) {
    #test-notifications {
        .info {
            &::before {
                display: none;

            }

            p {
                margin-left: 0;
            }

            .landscape-only {
                display: none;
            }
        }
    }
}
