body.shop {
    .is-paypal {
        form {
            padding: @topmeteo-pad @topmeteo-pad 0;

            p {
                margin: 0;
            }
        }

        #paypal-button-container {
            padding: @topmeteo-pad;
        }

        .total-costs {
            margin-bottom: (@topmeteo-pad * 3);

            dl {
                margin-left: 0;
            }

            @media (min-width: @topmeteo-site-width) {
                width: 50%;
            }
        }
    }
}
