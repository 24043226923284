/**
 * @file cross template module for form elemens
 * ......
 * scope:
 *   - map, metar-taf,location-forecast, satellite
 *   - shop, shop-checkout
 *   - start,index
 *   - login. password-reset-confirm, user-profile
 *   - standard
 *
 * @copyright CosmoCode GmbH
 */

@import (reference) "../setup";
@import (reference) "../helpers/content";
@import (reference) "../helpers/interactiveElems";
@import (reference) "../helpers/metSelect";
@import (reference) "../helpers/raster";


/* + + + + + + + + + + + + + + + + +
 * DESKTOP + MOBILE
 * + + + + + + + + + + + + + + + + */

.password-reset-link {
    position: relative;
    display: inline-block;
    border: solid 1px @topmeteo-blue;
    border-radius: 0;
    font-size: (@topmeteo-font-size + 2);
    font-weight: normal;
    text-transform: uppercase;
    letter-spacing: .05em;
    .pad-v(.5);
    .pad-h();

    &:hover,
    &:focus,
    &:active {
        background-color: @topmeteo-blue;
        color: #fff;
        text-decoration: none;
    }
}


// overwrite browser defaults for form elems
form {
    .errorlist {
        list-style-type: none;
        padding-left: 0;
        color: @topmeteo-red;
    }

    + p {
        margin-top: -@topmeteo-pad;
    }

    ul.errorlist + p {
        label {
            color: @topmeteo-red;
        }

        input,
        textarea {
            border-color: @topmeteo-red;
        }
    }

    input,
    textarea,
    select {
        background-color: #fff;
        border: 1px solid @topmeteo-blue;

        &:-webkit-autofill {
            -webkit-box-shadow: 0 0 0 20px white inset;

            &:hover,
            &:focus,
            &:active {
                -webkit-box-shadow: 0 0 0 20px @topmeteo-grey-lightest inset;
            }
        }

        &:hover,
        &:focus,
        &:active {
            background-color: @topmeteo-grey-lightest;
        }
    }

    input {
        &[type="checkbox"],
        &[type="radio"] {
            float: left;
            min-width: 16px;
            min-height: 16px;
            margin-top: 0;
            margin-right: (@topmeteo-pad / 2);
        }
    }


/* + + +  font-size for check in user registration (MET-1054)  + + + */
    body.standard #user-notifications + div & {
        input[type="checkbox"] {
            margin-top: 2px;
        }
    }
}


// general styles for form elems in form container
.form {
    .pad-h(4);

    p {
        .help-text,
        .helptext {
            display: block;
            margin: (@topmeteo-pad / 2) 0 @topmeteo-pad 0;
            opacity: 0.7;
            font-size: 90%;
        }
    }

    .page-header + p:last-child,
    form + p:last-of-type {
        .pad-b(2);
    }

    form {
        .pad-b(3);
    }

    label {
        cursor: pointer;
        min-width: 10em;
        font-size: (@topmeteo-font-size + 2);
    }

    input,
    select {
        .pad-v(.25);

        min-width: 20em;
        max-width: 100%;
        min-height: 27px;
        border-radius: 0;
        font-size: (@topmeteo-font-size + 1);

        @media (min-width: @topmeteo-site-width) {
            margin-top: (@topmeteo-pad*0.75);
        }

        @media (max-width: @topmeteo-site-width-max) {
            margin-bottom: (@topmeteo-pad*0.5);
        }
    }

    input {
        .pad-h(.5);
    }

    select {
        .pad-h(.25);
    }

    ::placeholder {
        color: @topmeteo-blue-shade;
    }
    ::-webkit-input-placeholder { /* Chrome/Opera/Safari */
        color: @topmeteo-blue-shade;
    }
    ::-moz-placeholder { /* Firefox 19+ */
        color: @topmeteo-blue-shade;
    }
    :-ms-input-placeholder { /* IE 10+ */
        color: @topmeteo-blue-shade;
    }
    :-moz-placeholder { /* Firefox 18- */
        color: @topmeteo-blue-shade;
    }

    button[type="submit"], a.button-submit {
        .pad-h();
        .pad-v(.5);

        min-height: 27px;
        background-color: @topmeteo-blue;
        border: 0 none;
        text-transform: uppercase;
        border-radius: 0;
        color: #fff;
        font-size: (@topmeteo-font-size + 2);
        font-weight: normal;
        letter-spacing: .05em;
        margin-top: (@topmeteo-pad*0.75);

        &:hover,
        &:focus,
        &:active {
            background-color: @topmeteo-blue-darkest;
        }
    }
}



/* + + + + + + + + + + + + + + + + +
 * DESKTOP ONLY
 * + + + + + + + + + + + + + + + + */
@media (min-width: @topmeteo-site-width){ }



/* + + + + + + + + + + + + + + + + +
 * MOBILE ONLY
 * + + + + + + + + + + + + + + + + */
@media (max-width: @topmeteo-site-width-max){
    .form {
        .pad-h();
        .pad-t(2);


        input[type="text"],
        input[type="email"] {
            -webkit-appearance: none;
        }

        select {
            -webkit-appearance: menulist;
            border-radius: 0;
            box-shadow: none;
            background-color: #fff;
        }
    }
}
