/**
 * @file template module shop
 * ......
 * scope:
 *   - shop
 *
 * @copyright CosmoCode GmbH
 */


@import (reference) "../setup";
@import (reference) "../helpers/content";
@import (reference) "../helpers/interactiveElems";
@import (reference) "../helpers/metSelect";
@import (reference) "../helpers/raster";


/* + + + + + + + + + + + + + + + + +
 * DESKTOP + MOBILE
 * + + + + + + + + + + + + + + + + */

body.profile {
    #regenerate-security-codes-form {
        display: none;
    }

    .page-header {
        border: 0 none;
        margin-bottom: 0;
        padding-bottom: 0;
    }

    .form {
        input,
        select {
            min-width: 100%;
            box-sizing: border-box;
            margin-top: 0;

            &[type="checkbox"],
            &[type="radio"] {
                min-width: 16px;
                min-height: 16px;
            }
        }

        select {
            width: 100%;
            overflow: hidden;
        }

        input[readonly] {
            border: 0 none;
            padding: 0;

            &:hover {
                background-color: transparent;
            }
        }

        button[type="submit"] {
            clear: both;
            display: block;
            margin-left: auto;
        }

        label,
        .label {
            display: inline-block; /* in german there are slightly longer labels than in other languages */
            font-weight: normal;
            text-align: left;
            padding: 0;
        }

        span.profileblock-input {
            display: block;
            min-height: 27px;
            padding-top: 2px;
        }
    }


/* + + + + +  shopping basket  + + + + + */
    .buy-button {
        position: relative;
        height: auto;
        background-color: @topmeteo-blue;
        border: solid 2px @topmeteo-blue;
        border-radius: 0;
        color: #fff;
        text-align: left;
        margin: 0;
        .pad-v(0);
        .pad-l(0.5);

        &::after {
            &:extend(.icon-basket:before);

            color: inherit;
        }

        &:hover,
        &:focus,
        &:active {
            background-color: @topmeteo-blue-darkest;
            border-color: @topmeteo-blue-darkest;
            text-decoration: none;
        }
    }


    /*  extend doesnt work in media-queries  */
    div[data-toggle="collapse"],
    div[aria-expanded="false"] {
        //&::after,
        &::before {
            &:extend(.icon-down-open-big:before);
        }
    }

    div[aria-expanded="true"] {
        //&::after,
        &::before {
            &:extend(.icon-up-open-big:before);
        }
    }


/* + + + + +  all forms  + + + + + */
    .profileblock {
        *::before,
        *::after {
            font-family: "topmeteo";
        }

        position: relative;
        color: @topmeteo-blue;
    }


/* + + + + +  personal data  + + + + + */
    .user-profile-form {
        .form-link {
            .metBtn();

            font-size: 14px;

            .met-btn {
                padding: 4px 8px;
            }
        }
    }

    #profile {
        position: relative;

        .profile-fields {
            .profile-field {
                position: relative;

                .form-link {
                    display: block;
                    position: absolute;
                    right: 0;
                    top: 50%;
                    transform: translate(0, -50%);
                }
            }
        }

        .security-codes {
            position: relative;

            .security-code {
                margin-top: 0;
                padding-top: 0;

                .metBtn();

                button.met-btn {
                    font-size: 14px;
                    padding: 2px 8px;
                    position: absolute;
                    right: 0;
                    margin: 0;
                }

                input {
                    min-width: 0;
                }
            }
        }
    }

    #units {
        .is-onethird {
            p {
                label {
                    overflow: hidden;
                    white-space: nowrap;
                    text-overflow: ellipsis;
                }
            }
        }
    }


/* + + + + +  profile-block headline  + + + + + */
    .profileblock-head {
        position: relative;

        h3 {
            font-size: @topmeteo-mobile-font-size-big;
            font-weight: normal;
            margin: 0;
        }
    }


/* + + + + +  profile-block content  + + + + + */
    .profileblock-content {
        &::after {
            content: '' !important;
            clear: both;
            display: block;
            height: 1px;
            overflow: hidden;
        }

        p {
            clear: both;
            .pad-t(0.5);


/* + + +  label  + + + */
            .is-head {
                display: block;
                font-weight: bold;
            }


/* + + +  text  + + + */
            .is-content {
                display: block;
            }
        }

        .shared-label {
            ::placeholder {
                opacity: .5;
            }

            > :first-child {
                margin-bottom: @topmeteo-pad / 4;
            }

            > :last-child {
                padding-top: 0;

                label {
                    display: none;
                }
            }
        }

        .is-halfsize {
            p {
                clear: left;
                float: left;
                width: 48%;

                &:nth-of-type(even) {
                    clear: right;
                    float: right;
                }
            }
        }

        .is-onethird {
            &::after {
                content: '';
                clear: both;
                display: block;
                height: 1px;
                overflow: hidden;
            }

            display: flex;
            flex: 0 0 auto;
            align-items: baseline;

            p {
                @select-width: 92%;

                width: 33.3%;

                label,
                select {
                    min-width: @select-width;
                    width: @select-width;
                    overflow: hidden;
                    margin-left: ((100 - @select-width) / 2);
                    margin-right: ((100 - @select-width) / 2);
                }

                &:first-of-type {
                    label,
                    select {
                        margin-left: 0;
                        margin-right: (100 - @select-width);
                    }
                }

                &:nth-of-type(3) {
                    label,
                    select {
                        margin-left: (100 - @select-width);
                        margin-right: 0;
                    }
                }
            }
        }

        .options {
            clear: both;
            padding-top: @topmeteo-pad;

            h4 {
                margin: 0;
                padding-bottom: (@topmeteo-pad / 2);
                font-size: (@topmeteo-font-size + 5);
            }

            .packet-option {
                position: relative;
                margin: 0;
                padding: (@topmeteo-pad / 2) 0;

                .name {
                    font-size: (@topmeteo-font-size + 3);
                }

                button {
                    position: absolute;
                    top: (@topmeteo-pad / 4);
                    right: 0;
                    font-size: (@topmeteo-font-size + 2);
                    padding-top: 2px;
                    padding-bottom: 2px;

                    &:disabled {
                        border-color: grey;
                        background-color: grey;
                        padding-right: (@topmeteo-pad / 2);

                        &:after {
                            display: none;
                        }

                        &:hover {
                            border-color: grey;
                            background-color: grey;
                        }
                    }
                }
            }
        }
    } // profileblock-content
}


//html[lang=de] {
//    body.profile {
//        .user-profile-form,
//        .user-packets-form {
//            p {
//                label,
//                .label {
//                    min-width: 13em; /* in german there are slightly longer labels than in other languages */
//                }
//            }
//        }
//    }
//}


/* + + + + + + + + + + + + + + + + +
 * DESKTOP ONLY
 * + + + + + + + + + + + + + + + + */

@media (min-width: @topmeteo-site-width) {
    body.profile {
        .content-area .container {
            background-color: transparent;

            .row {
                > div {
                    background-color: #fff;
                }

                .col-xs-12 + .col-xs-12 {
                    margin-top: (@topmeteo-pad * 2);

                    .form {
                        h2 {
                            margin-top: (@topmeteo-pad * 2);
                        }
                    }
                }
            }
        }


/* + + + + +  all forms  + + + + + */
        .form {
            form.packet-option{
                position: relative;

                .buy-button[type="submit"] {
                    top: auto;
                    bottom: @topmeteo-pad;
                    right: auto;
                    left: 0;
                }
            }

            input,
            select {
                font-size: @topmeteo-mobile-font-size;
            }

            label,
            .is-label {
                min-width: 10px;
                font-size: @topmeteo-font-size;
                font-weight: normal;
            }

            span.profileblock-input {
                font-size: 16px;
            }
        }


/* + + + + +  personal data  + + + + + */
        .user-profile-form {
            fieldset {
                display: inline-block;
                width: 422px;
                max-width: 422px;
                min-width: 422px;
                overflow: hidden;
                vertical-align: top;

                &:nth-child(even) {
                    margin-right: 7.9%;
                }
            }

            button[type="submit"] {
                margin-right: .7%;
            }
        }


/* + + + + +  packets  + + + + + */
        .user-packets-form {
            display: inline-block;
            width: 45%;
            vertical-align: top;

            &:nth-child(even) {
                margin-right: 9%;
            }

            .profileblock-content {
                .is-expired {
                    .is-content {
                        color: @topmeteo-red;
                    }
                }
            }

            margin-bottom: @topmeteo-pad * 2;
        }


/* + + + + +  shopping basket  + + + + + */
        .buy-button {
            .pad-r(2);

            &::after {
                position: absolute;
                top: 0;
                right: 5px;
                font-size: 20px;
                margin-top: -3px;
            }

            &[type="submit"] {
                position: absolute;
                top: (@topmeteo-pad * 2);
                right: 0;
                width: auto;
            }
        }


        // no accordion function
        div[data-toggle="collapse"],
        div[aria-expanded="false"],
        div[aria-expanded="true"] {
            &::before,
            &::after {
                display: none;
            }
        }

        .collapse {
            position: static;
            display: block;
            height: auto !important;
            visibility: visible;
        }


        // not clickable
        .profileblock-head {
            min-height: 2.75rem;
            border-bottom: solid 1px @topmeteo-blue-light;
            margin-top: (@topmeteo-pad * 2);

            h3 {
                padding-right: 5em;
                .pad-b(0.25);
            }

            &::after {
                content: '';
                position: absolute;
                width: 100%;
                height: 100%;
                left: 0;
                top: 0;
            }
        }

        .profileblock-content {
            p {
                .is-content {
                    font-size: @topmeteo-mobile-font-size;
                }
            }
        }
    }
}


/* + + + + + + + + + + + + + + + + +
 * <= 768
 * + + + + + + + + + + + + + + + + */

@media (max-width: 768px) {
    body.profile {
        .profileblock-content {
            .is-halfsize {
                p {
                    float: none;
                    width: 100%;

                    &:nth-of-type(even) {
                        float: none;
                    }
                }
            }

            .is-onethird {
                display: block;

                p,
                p:nth-of-type(3) {
                    width: 100%;

                    label,
                    select {
                        min-width: 100%;
                        width: 100%;
                        margin-left: 0;
                        margin-right: 0;
                    }
                }
            }
        } // profileblock-content
    }
}


/* + + + + + + + + + + + + + + + + +
 * MOBILE ONLY
 * + + + + + + + + + + + + + + + + */

@media (max-width: @topmeteo-site-width-max) {
    body.profile {
        #mobile-region-select {
            display: none;
        }

        legend {
            display: none;
        }


/* + + + + +  shopping basket  + + + + + */
        .buy-button {
            font-size: @topmeteo-mobile-font-size-big;
            line-height: 1;
            .pad-t(0.25);
            .pad-b(0.5);
            .pad-r(0.5);

            &::after {
                font-size: 25px;
                margin-top: 0;
            }

            &[type="submit"] {
                width: 100%;
                text-align: center;
                margin-top: @topmeteo-pad;
            }
        }


/* + + + + +  accordion function  + + + + + */
        div[data-toggle="collapse"] {
            position: relative;

            &::before,
            &::after {
                position: absolute;
                top: 50%;
                font-size: 20px;
                margin-top: -12px;
            }

            &::before {
                left: (@topmeteo-pad * 2);
            }

            &::after {
                right: (@topmeteo-pad * 2);
            }
        }


/* + + + + +  all forms  + + + + + */
        .form {
            form {
                .pad-b();
            }

            // section "packets"
            form.packet-option {
                .buy-button[type="submit"] {
                    width: auto;
                    margin-left: 0;
                }
            }

            input,
            select {
                font-size: (@topmeteo-mobile-font-size-big + 2);
            }

            label,
            .is-label {
                font-size: @topmeteo-mobile-font-size;
            }

            span.profileblock-input {
                font-size: (@topmeteo-mobile-font-size-big + 2);
            }
        }

        .profileblock {
            padding-bottom: 0;
        }

        #profile {
            .profile-fields {
                .profile-field {
                    .form-link {
                        position: relative;
                        transform: none;
                        margin: (@topmeteo-pad / 2) 0 @topmeteo-pad 0;
                    }
                }
            }
        }

/* + + + + +  block header  + + + + + */
        .profileblock-head {
            background-color: @topmeteo-blue;
            color: #fff;
            text-align: center;
            margin-top: (@topmeteo-pad * 2);
            .pad-v(0);

            h3 {
                margin: 0 50px;
                .pad-v();
            }


            // clickable area
            > div {
                cursor: pointer;
                .pad-h();

                &:hover {
                    background-color: @topmeteo-blue-dark;
                }
            }
        }


/* + + + + +  block content  + + + + + */
        .profileblock-content {
            border: solid 1px @topmeteo-blue;
            .pad();

            &::before {
                display: none;
            }

            > form {
                clear: both;
                .pad-t(.5);
            }

            p {
                .is-content {
                    font-size: (@topmeteo-mobile-font-size-big + 2);
                }
            }


/* + + + + +  region  + + + + + */
            .packet-region {
                padding-top: 0;
            }


/* + + + + +  duration  + + + + + */
            .packet-duration {
                padding-top: 0;
            }


/* + + + + +  price  + + + + + */
            .packet-price { }


/* + + + + +  options  + + + + + */
            .options {
                .packet-option {
                    @button-size: 9rem;

                    clear: both;

                    .name {
                        display: block;
                        float: left;
                    }

                    .buy-button {
                        position: static;
                        float: right;
                        margin-top: -3px;
                        min-width: @button-size;
                        line-height: 130%;
                        text-align: right;
                        margin-bottom: 3px;

                        &:disabled {
                            text-align: center;
                        }

                        &::after {
                            font-size: 19px;
                        }
                    }
                }
            }
        }
    }
}
