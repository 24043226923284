/**
 * @file helper mixins for raster
 * ......
 * scope:
 *   - global
 *
 * @copyright CosmoCode GmbH
 */



/* adds padding. @adjust can be used to half or double padding*/
.pad-l(@adjust: 1) {
    padding-left: (@topmeteo-pad * @adjust);
}
.pad-r(@adjust: 1) {
    padding-right: (@topmeteo-pad * @adjust);
}
.pad-t(@adjust: 1) {
    padding-top: (@topmeteo-pad * @adjust);
}
.pad-b(@adjust: 1) {
    padding-bottom: (@topmeteo-pad * @adjust);
}
.pad-v(@adjust: 1) {
    .pad-t(@adjust);
    .pad-b(@adjust);
}
.pad-h(@adjust: 1) {
    .pad-l(@adjust);
    .pad-r(@adjust);
}
.pad(@adjust: 1) {
    .pad-h(@adjust);
    .pad-v(@adjust);
}

