//
// Print styles
// --------------------------------------------------


@media print {
    //*,
    //*:before,
    //*:after {
    //    background: transparent !important;
    //    color: #000 !important;
    //    box-shadow: none !important;
    //    text-shadow: none !important;
    //}

    .icon-menu,
    .loop-button,
    #mobile-region-select,
    .legend-toggle,
    .browse-layer {
        display: none !important;
    }
    a,
    a:visited {
        text-decoration: underline;
    }
    a[href]:after {
        content: " (" attr(href) ")";
    }
    abbr[title]:after {
        content: " (" attr(title) ")";
    }
    a[href^="#"]:after,
    a[href^="javascript:"]:after {
        content: "";
    }
    pre,
    blockquote {
        border: 1px solid #999;
        page-break-inside: avoid;
    }
    thead {
        display: table-header-group;
    }
    tr,
    img {
        page-break-inside: avoid;
    }
    img {
        max-width: 100% !important;
    }
    p,
    h2,
    h3 {
        orphans: 3;
        widows: 3;
    }
    h2,
    h3 {
        page-break-after: avoid;
    }
    select {
        background: #fff !important;
    }
    .navbar {
        display: none;
    }
    .btn > .caret,
    .dropup > .btn > .caret {
        border-top-color: #000 !important;
    }
    .label {
        border: 1px solid #000;
    }
    .table {
        border-collapse: collapse !important;
    }
    .table td,
    .table th {
        background-color: #fff !important;
    }
    .table-bordered th,
    .table-bordered td {
        border: 1px solid #ddd !important;
    }
}
