@import "~ol/ol.css";

body.flight-planning {
  @ol-padding: @topmeteo-pad / 2;
  @map-control-size: 40px; /* default size of all square controls/buttons on the map */
  @desktop-map-size: 750px; /* the map has a fixed height on desktop */
  @opacity-default: 0.7;
  @bg-color-default: @topmeteo-blue;
  @bg-color-hover: @topmeteo-blue;

  .flight-planning-config-form {
    display: none;
  }

  #flight-planning-legend {
    display: none;
  }

  #map {
    height: @desktop-map-size;
  }

  #map-wrapper {
    position: relative;
  }

  #current-region-identifier {
    position: absolute;
    top: @ol-padding;
    right: @ol-padding + @map-control-size + @ol-padding;

    min-width: @map-control-size;
    height: @map-control-size;
    padding: 0 @ol-padding;

    opacity: @opacity-default;

    display: flex;
    align-items: center;
    justify-content: center;

    font-size: 16px;
    color: white;
    background-color: @bg-color-default;
    text-transform: uppercase;
  }

  canvas {
    display: block;
  }

  .browse-layer-outer {
    position: absolute;
    bottom: 0;
    top: 0;

    .next {
      right: @topmeteo-pad + @map-control-size + @ol-padding;
    }

    @media (max-width: @topmeteo-site-width-max) {
      max-width: 100vw;
    }
  }

  .ol-attribution {
    left: @ol-padding;
    right: auto;
  }

  .controls {
    position: absolute;
    top: @ol-padding;
    right: @ol-padding;
    bottom: @ol-padding;

    width: @map-control-size;

    display: flex;
    flex-direction: column;

    opacity:  @opacity-default;

    button {
      border: none;
      color: white;
      background: @topmeteo-blue;

      width: @map-control-size;
      height: @map-control-size;

      flex-shrink: 0;

      &.settings {
        padding: 0;

        .icon {
          font-size: 24px;
          line-height: 26px;

          &::before {
            margin: 0;
          }
        }
      }

      &.toggle-draw {
        padding: @topmeteo-pad / 2;

        svg {
          circle {
            fill: @disable-color-grey;
          }

          line {
            stroke: @disable-color-grey;
            stroke-width: 14;
            stroke-miterlimit: 10;
          }
        }

        &.drawing {
          svg {
            circle {
              fill: white;
            }

            line {
              stroke: white;
            }
          }
        }
      }
    }

    .export-button {
      padding: @topmeteo-pad / 2;
      margin: @ol-padding 0;

      img {
        height: 24px;
      }

      &.disabled {
        opacity: @opacity-default + 0.1;

        img {
          filter: invert(0.45);
        }
      }
    }

    .opacity-slider {
      // default slider size on desktop (overridden below for mobile)
      @others-height: 3*@map-control-size + 5*@ol-padding;
      @slider-height: @desktop-map-size - @others-height;

      // reserve vertical space
      height: @slider-height;
      margin: @ol-padding 0;
      position: relative;

      input {
        // put element at the bottom of the space
        position: absolute;
        bottom: 0;

        // rotate 90 degrees upwards
        transform-origin: @map-control-size bottom;
        transform: rotate(-90deg);
        margin: @map-control-size 0;

        // reversed, because these apply to the size before the rotation
        height: @map-control-size;
        width: @slider-height;

        -webkit-appearance: none;

        &::-webkit-slider-thumb {
          -webkit-appearance: none;
          width: @map-control-size;
          height: @map-control-size;
          background: @topmeteo-blue;
        }

        &::-moz-range-thumb {
          width: @map-control-size;
          height: @map-control-size;
          background: @topmeteo-blue;
        }

        &:focus {
          outline: none;
        }
      }

      // mobile height override for @slider-height using CSS calc
      @media (max-width: @topmeteo-site-width-max) {
        & {
          // we need to substract space for the mobile header and footer
          // the 130 is an aproximation that seems to be okayish
          @offset: 130 + @others-height;
          height: calc(100vh - @offset);

          input {
            width: calc(100vh - @offset);
          }
        }
      }
    }
  }

  .confirm-region-change-light-box {
    .buttons {
      display: flex;

      .metBtn()
    }
  }

  .settings-light-box {
    h2 {
      /* centers the headline relative to the close button */
      margin: (@topmeteo-pad / 4) 0 @topmeteo-pad;
    }

    form,
    .checkbox-group span {
      font-size: 15px;
    }

    .layer-select {
      margin-bottom: 10px;

      legend {
        display: none;
      }

      .layer-option {
        display: flex;
        align-items: center;

        cursor: pointer;

        .image-container {
          @image-size: 40px;
          @container-padding: 3px;

          width: @image-size + @container-padding;
          height: @image-size + @container-padding;

          border: 1px solid transparent;
          border-radius: 10%;

          overflow: hidden;

          margin-right: 15px;
          padding: @container-padding;

          img {
            width: 100%;
            border-radius: 10%;
          }
        }

        span {
          font-weight: bold;
        }

        &.checked {
          .image-container {
            border-color: @topmeteo-blue;
          }
        }
      }

      &.gray {
        img {
          filter: grayscale(1);
        }
      }
    }

    .checkbox-group {
      /* align the checkbox groups to the layer select */

      margin-left: 9px;

      @align: 50px;

      span {
        left: @align;

        &::before {
          left: -@align;
        }
      }
    }
  }

  .export-light-box {
    .tab-list {
      padding: 0;
      margin-right: @topmeteo-pad;
      border-bottom: 1px solid @topmeteo-blue;

      .tab {
        display: inline-block;
        cursor: pointer;
        list-style: none;
        margin-right: 1rem;
        margin-bottom: -1px;
        padding: 1rem;
        border: 1px solid transparent;

        &.active {
          border-color: @topmeteo-blue;
          border-bottom-color: white;
        }
      }
    }

    .tab-content {
      .tab {
        display: none;

        &.active {
          display: block;
        }
      }

      label {
        span {
          margin-right: (@topmeteo-pad / 2);
        }
      }

      button {
        border: none;
        color: white;
        padding: (@topmeteo-pad / 2);
        background-color: @topmeteo-blue-dark;
      }
    }
  }

  .export-notification {
    position: absolute;
    top: @ol-padding;
    left: 50%;
    transform: translate(-50%, 0);

    color: white;
    background: @topmeteo-blue;

    border-radius: 0.5em;

    transition: opacity 1s ease-in-out;

    p {
      margin: 1em;
      font-size: @topmeteo-font-size;
    }
  }

  .calculation-box {
    position: absolute;
    top: @ol-padding;
    left: @ol-padding;

    width: auto;

    opacity: @opacity-default;

    text-align: left;

    .calculation-box-inner {
      padding: @ol-padding;

      color: white;
      background-color: @bg-color-default;

      p {
        margin: 0;
        font-size: 16px;
      }
    }
  }

  .slider-background {
    display: none;
    position: absolute;
    bottom: 0;
    right: 0;
    left: 0;
    height: 60px;
    background: @topmeteo-blue;
    opacity: 0.3;
  }

  // make sure the map uses the whole height on mobile
  @media (max-width: @topmeteo-site-width-max) {
    height: 100%;

    @top-controls-bottom: @topmeteo-pad / 2 + @topmeteo-headerbar-xs;

    #page-wrapper,
    .container,
    .row,
    #map {
      height: 100%;
      margin: 0;
      padding: 0;
    }

    .controls {
      right: 0;
      top: @ol-padding + @map-control-size + @ol-padding;
      bottom: @ol-padding + @map-control-size;
    }

    .controls-area {
      &.panel-open {
        > .container {
          height: 0;
        }
      }
    }

    #content-area,
    #legend-area > .container {
      height: calc(100% - @topmeteo-headerbar-xs);
      margin-top: @topmeteo-headerbar-xs;
      padding-bottom: 0;
    }

    .ol-attribution {
      left: @ol-padding / 2;
      bottom: @ol-padding / 2;
    }

    .calculation-box {
      left: 0;
      right: auto;
      top: @ol-padding + @map-control-size + @ol-padding;
    }

    .panel-open {
      @panel-height: 110px;

      .slider-background {
        display: block;
      }

      .controls {
        bottom: @panel-height + @ol-padding;
      }

      .ol-attribution {
        bottom: @panel-height + @ol-padding / 2;
      }
    }
  }
}
