/**
 * @file cross template module for lightboxes and overlays
 * ......
 * scope:
 *   - map, metar-taf,location-forecast, satellite
 *   - shop, shop-checkout
 *   - start,index
 *   - login. password-reset-confirm, user-profile
 *   - standard
 *
 * @copyright CosmoCode GmbH
 */

@import (reference) "../setup";
@import (reference) "../helpers/content";
@import (reference) "../helpers/interactiveElems";
@import (reference) "../helpers/metSelect";
@import (reference) "../helpers/raster";


/* + + + + + + + + + + + + + + + + +
 * DESKTOP + MOBILE
 * + + + + + + + + + + + + + + + + */
.lightbox-outer {
    display: none;
    color: @topmeteo-blue;
    text-align: left;
}

.overlay {
    position: fixed;
    top: 0;
    left: 0;
    z-index: 1001; // menu-button
    width: 100%;
    height: 100%;
    background-color: #000;
    opacity: .3;
}

.lightbox {
    border: solid 1px @topmeteo-blue;
    position: fixed;
    top: 5%;
    left: 5%;
    right: 5%;
    z-index: 1002;
    max-height: 90%;
    overflow: auto;
    background-color: #fff;
    .pad-v();
    .pad-h(2);

    *::before,
    *::after {
        font-family: 'topmeteo';
    }

    &.hint {
        top: 15%;
        left: 25%;
        right: 25%;
        max-height: 80%;
        .pad-v();
        .pad-l(2);
        .pad-r(3.5);
    }

    .link {
        display: inline-block;
        text-transform: uppercase;
        .pad-b(.5);
    }

    .close {
        .close-link();

        top: 10px;
        right: 10px;
    }
}

.lightbox-inner {
    form {
        .pad-t(.5);
        .pad-b();
    }

    label {
        cursor: pointer;
        clear: left;
        display: block;
        min-height: 32px;
        color: @topmeteo-blue;
        line-height: 1;
        margin-bottom: 10px;
    }

    input[type="checkbox"] {
        position: absolute;
        appearance: none;
        visibility: hidden;

        + span {
            position: relative;
            top: 10px;
            left: 40px;
            display: inline-block;
            font-size: @topmeteo-font-size;
            line-height: 1;

            &::before {
                content: '';
                position: absolute;
                top: -6px;
                left: -40px;
                display: inline-block;
                width: 25px;
                height: 25px;
                overflow: hidden;
                background-color: #fff;
                border: solid 1px @topmeteo-blue;
                padding: 5px 0 0 3px; // position for icon-check
            }
        }

        &:checked {
            + span {
                &:extend(.icon-check all);
            }
        }
    }

    input[type="submit"] {
        clear: left;
        background-color: @topmeteo-blue;
        border: solid 1px @topmeteo-blue;
        border-radius: 0;
        color: #fff;
        text-transform: uppercase;
        margin-top: 20px;
        .pad-v(.5);
        .pad-h();

        &:hover,
        &:focus,
        &:active {
            background-color: #fff;
            color: @topmeteo-blue;
        }
    }

    p {
        &.error {
            color: @topmeteo-red;
        }
    }
}



/* + + + + + + + + + + + + + + + + +
 * DESKTOP ONLY
 * + + + + + + + + + + + + + + + + */



/* + + + + + + + + + + + + + + + + +
 * MOBILE ONLY
 * + + + + + + + + + + + + + + + + */
@media (max-width: @topmeteo-site-width-max) {
    .lightbox {
        &.hint {
            top: 10%;
            left: 10%;
            right: 11%;
        }
    }

    .lightbox-outer {
        font-size: @topmeteo-mobile-font-size-small;
    }

    .lightbox-inner {
        input[type="checkbox"] {
            + span {
                font-size: @topmeteo-mobile-font-size-small;
            }
        }
    }
}


/* + + + + + + + + + + + + + + + + +
 * SPECIAL MEDIA QUERIES
 * + + + + + + + + + + + + + + + + */
@media (max-width: 600px) {
    .lightbox {
        &.hint {
            top: 5%;
            left: 4%;
            right: 4%;
            max-height: 90%;
        }
    }
}