/**
 * @file cross template module for content styles
 * ......
 * scope:
 *   - map, metar-taf,location-forecast, satellite
 *   - shop, shop-checkout
 *   - start,index
 *   - login. password-reset-confirm, user-profile
 *   - standard
 *
 * @copyright CosmoCode GmbH
 */

@import (reference) "../setup";
@import (reference) "../helpers/content";
@import (reference) "../helpers/interactiveElems";
@import (reference) "../helpers/metSelect";
@import (reference) "../helpers/raster";


/* + + + + + + + + + + + + + + + + +
 * DESKTOP + MOBILE
 * + + + + + + + + + + + + + + + + */

.content-area {
    .container {
        margin-top: @topmeteo-pad;
        margin-bottom: @topmeteo-pad;
        background-color: @topmeteo-content;
    }
}

body.exception {
    .content-area {
        .container {
            article {
                text-align: center;
            }
        }
    }
}

a {
    cursor: pointer; // we have some JS links without HREFs

    main p & {
        text-transform: uppercase;
    }
}

.uppercase {
    text-transform: uppercase;
}


/**
 * mixin: The logo
 * Can be adjusted by adjusting the fontsize
 */

.topmeteo-logo {
    font-size: (@topmeteo-font-size * 2.25);
    line-height: 1em;
    vertical-align: baseline;
    padding-top: 1em;
    display: block;
    width: 6.5em;
    color: @text-color;

    &:focus,
    &:hover {
        text-decoration: none;
    }

    img {
        margin-top: -.75em;
        width: auto;
        height: 2em;
    }
}


/* + + + + + + + + + + + + + + + + +
 * DESKTOP ONLY
 * + + + + + + + + + + + + + + + + */

@media (min-width: @topmeteo-site-width) {
    .container {
        max-width: 100%; // viewport: 1050px -> container 1050px: scrollbar not respected
    }


/* + + +  MET-1008  + + + */
    #page-wrapper {
        .content-area {
            #projection-map-image {
                position: relative;
                left: 50%;
                top: 0;
                transform: translateX(-50%) translateY(0);
                margin: 0;

                .image {
                    img {
                        max-width: 80vw;
                    }
                }
            }
        }
    }
}

/* + + + + + + + + + + + + + + + + +
 * MOBILE ONLY
 * + + + + + + + + + + + + + + + + */

@media (max-width: @topmeteo-site-width-max) {
    .content-area {
        // no content behind the lower toggle buttons:
        padding-bottom: @topmeteo-toggle-size;
    }
}
