/**
 * @file template module for map (projection maps)
 * ......
 * scope:
 *   - map
 *
 * @copyright CosmoCode GmbH
 */


@import (reference) "../setup";
@import (reference) "../helpers/content";
@import (reference) "../helpers/interactiveElems";
@import (reference) "../helpers/metSelect";
@import (reference) "../helpers/raster";


/* + + + + + + + + + + + + + + + + +
 * DESKTOP + MOBILE
 * + + + + + + + + + + + + + + + + */

body.map {
    .loading-wrapper {
        background: none;
    }

    #projection-map-image {
        .unavailable {
            margin: 0;
            display: flex;
            align-items: center;
            justify-content: center;
        }

        .loading {
            img {
                filter: grayscale(.5) brightness(.5);
            }
        }

        // MET-1102: click highlights images
        img {
            -khtml-user-select: none;
            -o-user-select: none;
            -moz-user-select: none;
            -webkit-user-select: none;
            user-select: none;

            /* so it could always contain
               the loading indicator */
            min-height: 120px;
        }
    }
}


/* + + + + + + + + + + + + + + + + +
 * DESKTOP ONLY
 * + + + + + + + + + + + + + + + + */

@media (min-width: @topmeteo-site-width) {
    body.map {
        .desktop-content-full();
        .desktop-content-full-center-image();

        div#mtx-controls .row {
            padding-bottom: 0;

            &:last-of-type {
                display: flex;
                align-items: flex-end;
            }
        }

        #projection-map-image {
            .unavailable {
                width: @topmeteo-site-width;
                min-height: 350px;
                background: white;
            }
        }
    }
}


/* + + + + + + + + + + + + + + + + +
 * MOBILE ONLY
 * + + + + + + + + + + + + + + + + */

@media (max-width: @topmeteo-site-width-max) {
    body.map {
        background-color: @topmeteo-blue-darkest;

        div.header-toggle {
            top: 0;
            left: 0;
        }

        div#mtx-controls {
            .met-select-wrapper {
                margin-top: 0;
            }
        }

        #projection-map-image {
            width: 100%;
            height: 100%;

            .image {
                img {
                    width: 100vw;
                }

                .unavailable {
                    color: white;
                    padding: 20%;
                    height: 100%;
                    text-align: center;
                }
            }
        }

        .zoom {
            position: absolute;
            top: 40px;
            bottom: 0;
            left: 0;
            overflow: auto;
            height: auto;
            width: 100%;
            z-index: @topmeteo-z-bottom;

            #content {
                position: relative;
            }
        }

        .panel-open {
            + div.content-area {
                .zoom.is-playable {
                    bottom: 57px;
                }
            }
        }
    }
}
