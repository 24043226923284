/**
 * @file cross template module for page structure and bootstrap overwrites
 * ......
 * scope:
 *   - map, metar-taf,location-forecast, satellite
 *   - shop, shop-checkout
 *   - start,index
 *   - login. password-reset-confirm, user-profile
 *   - standard
 *
 * @copyright CosmoCode GmbH
 */

@import (reference) "../setup";
@import (reference) "../helpers/content";
@import (reference) "../helpers/interactiveElems";
@import (reference) "../helpers/metSelect";
@import (reference) "../helpers/raster";


/* + + + + + + + + + + + + + + + + +
 * DESKTOP + MOBILE
 * + + + + + + + + + + + + + + + + */

.browse-layer-outer {
    position: relative;
    max-width: 1050px;
    max-height: 100%;
    margin: 0 auto;

    *::before,
    *::after {
        font-family: 'topmeteo';
    }
}

.browse-layer {
    @arrow-size: 90px;

    .met-btn {
        position: absolute;
        z-index: 1;
        display: block;
        overflow: hidden;
        width: @arrow-size;
        height: @arrow-size;
        background-color: @topmeteo-blue;
        opacity: @opacity-browser-icon;
        border: 0 none;
        border-radius: 50%;
        color: #fff;
        font-weight: bold;
        font-size: (@arrow-size - 30);
        margin: 0;
        padding: 0;

        &::before {
            position: relative;
            top: 0;
            z-index: 1;
            margin: 0;
        }

        &::after {
            content: '';
            position: absolute;
            z-index: 0;
            left: 0;
            top: 0;
            width: 100%;
            height: 100%;
        }

        &:hover {
            opacity: .8;

            &::after {
                background-color: @topmeteo-blue;
                opacity: .3;
            }
        }

        &:focus {
            outline: none;
        }

        &.disabled {
            display: none;
        }
    }

    .browse {
        position: static;
        width: 1050px; /* width is set by JS (depends on table width) */
        max-width: 100%;
        text-align: center;

        &.initial {
            display: none;
        }

        @arrow-half: (@arrow-size / 2);
        @arrow-pad: (@topmeteo-pad / 4);

        .prev,
        .next {
            top: 50%;
            margin-top: -(@arrow-half);

            &.date {
                top: calc(50% + @arrow-half + @arrow-pad);
            }

            &.index {
                top: calc(50% - @arrow-half - @arrow-pad);
            }
        }

        .prev {
            left: (@topmeteo-pad * 2);

            span {
                &:extend(.icon-left-open-big all);
            }

            &.index {
                span {
                    padding-right: 7px;
                }
            }
        }

        .next {
            right: (@topmeteo-pad * 2);

            span {
                &:extend(.icon-right-open-big all);
            }

            &.index {
                span {
                    padding-left: 7px;
                }
            }
        }

        .top,
        .bottom {
            top: @topmeteo-pad;
            left: 50%;
            margin-left: -(@arrow-half);

            &::before {
                content: '';
                position: absolute;
                left: 50%;
                display: block;
                transform: translateX(-50%);
                -ms-transform: translateX(-50%);
                -webkit-transform: translateX(-50%);
            }
        }

        .top {
            transform: translateX(-55%);
            -ms-transform: translateX(-55%);
            -webkit-transform: translateX(-55%);

            &::before {
                background: url('~images/style/icon-up.svg') no-repeat 0 0;
            }
        }

        .bottom {
            transform: translateX(55%);
            -ms-transform: translateX(55%);
            -webkit-transform: translateX(55%);

            &::before {
                background: url('~images/style/icon-down.svg') no-repeat 0 0;
            }
        }
    }
}


/* + + + + + + + + + + + + + + + + +
 * DESKTOP ONLY
 * + + + + + + + + + + + + + + + + */

@media (min-width: @topmeteo-site-width) {
    /* MET-1114 */
    .browse-layer {
        .browse {
            .top,
            .bottom {
                &::before {
                    top: 20px;
                    bottom: 20px;
                    width: 38px;
                }
            }
        }
    }
}


/* + + + + + + + + + + + + + + + + +
 * MOBILE ONLY
 * + + + + + + + + + + + + + + + + */

@media (max-width: @topmeteo-site-width-max){

/* + + + + +  test version  + + + + + */
    .is-testing {
        .browse-layer {
            .browse {
                .top,
                .bottom {
                    top: (@topmeteo-toggle-size + @topmeteo-pad);
                }
            }
        }
    }

    .browse-layer {
        @arrow-size: 46px;

        .browse {
            .prev,
            .next {
                margin-top: -(@arrow-size / 2);

                &.date {
                    top: calc(50% + @arrow-size);
                }

                &.index {
                    top: calc(50% - @arrow-size);
                }
            }

            .prev {
                left: @topmeteo-pad;

                &.index {
                    span {
                        padding-right: 3px;
                    }
                }
            }

            .next {
                right: @topmeteo-pad;

                &.index {
                    span {
                        padding-left: 3px;
                    }
                }
            }

            .top,
            .bottom {
                margin-left: -(@arrow-size / 2);

                &::before {
                    top: 10px;
                    bottom: 10px;
                    width: 20px;
                }
            }

            .top,
            .bottom {
                top: @topmeteo-pad;
            }
        }

        .met-btn {
            width: @arrow-size;
            height: @arrow-size;
            font-size: (@arrow-size - 20);
            font-weight: normal;

            &:hover {
                opacity: .3;
            }
        }
    }
}
