/**
 * @file template module metar-taf
 * ......
 * scope:
 *   - metar-taf
 *
 * @copyright CosmoCode GmbH
 */

@import (reference) "../setup";
@import (reference) "../helpers/content";
@import (reference) "../helpers/interactiveElems";
@import (reference) "../helpers/metSelect";
@import (reference) "../helpers/raster";


@headline-width: 20%;
@icon-space: 64px;


/* + + + + + + + + + + + + + + + + +
 * DESKTOP + MOBILE
 * + + + + + + + + + + + + + + + + */

body.metar {

    /* + + + + +  test version  + + + + + */
    #test-notifications {
        display: none;
    }

    #metar-taf-controls {
        text-align: center;

        // extend cannot be used in media query, has to be declared here,
        // though it's only used in desktop view
        .metar-taf-map-toggler {
            .toggle-icon {
                &:extend(.icon-minus-circled all); /* state: map open */
            }
        }

        /* state: map closed */
        &.closed {
            .metar-taf-map-toggler{
                .toggle-icon {
                    &:extend(.icon-plus-circled all);
                }
            }
        }

        .map-wrapper {
            /* The container has to be as wide as it's contained image
               so the pins could be absolute positioned to it. */
            display: inline-block;
            position: relative;

            .map-pin {
                display: inline-block;
                position: absolute;

                /* For information on how and why the pins are positioned
                   see: location-forcast.less > .location-position */

                &.map-pin-location {
                    pointer-events: none;  /* let a click go through it to the map */

                    width: @topmeteo-pin-default-size;
                    height: @topmeteo-pin-default-size;

                    margin-top: -@topmeteo-pin-default-size;
                    margin-left: -(@topmeteo-pin-default-size / 2);

                    z-index: 3;  /* make them always above the airport pins */

                    &::before {
                        font-size: @topmeteo-pin-default-size;
                        margin: 0;
                        text-shadow: 0 0 10px white;
                    }

                    &.icon-pin-up {
                        color: @icon-pin-green;
                    }

                    &.icon-pin-down {
                        color: @icon-pin-red;
                    }
                }

                &.map-pin-airport {
                    width: @topmeteo-pin-small-size;
                    height: @topmeteo-pin-small-size;

                    margin-top: -@topmeteo-pin-small-size;
                    margin-left: -(@topmeteo-pin-small-size / 2);

                    z-index: 2;

                    &::before {
                        font-size: @topmeteo-pin-small-size;
                        margin: 0;
                        text-shadow: 0 0 8px white;
                    }

                    color: @icon-pin-darkgrey;

                    .map-pin-airport-popup {
                        display: none;

                        position: absolute;
                        left: @topmeteo-pin-small-size;
                        top: @topmeteo-pin-small-size * 1.5;

                        min-width: 200px;
                        max-width: 90vw;

                        padding: @topmeteo-pad / 2;
                        padding-bottom: 0;

                        background-color: @topmeteo-blue;
                        color: #fff;

                        border-radius: 0.5em;

                        z-index: 4;

                        .tail {
                            @size: 10px;

                            top: -@size * 2;
                            width: 0;
                            height: 0;
                            display: block;
                            position: absolute;
                            border: @size solid transparent;
                            border-bottom-color: @topmeteo-blue;
                        }

                        p {
                            margin: 0 0 @topmeteo-pad / 2;
                        }

                        .title {
                            white-space: nowrap;
                            overflow: hidden;
                            text-overflow: ellipsis;
                        }
                    }

                    &.active {
                        z-index: 3;
                        color: @topmeteo-blue;

                        .map-pin-airport-popup {
                            display: inline-block;
                        }
                    }
                }
            }

            .map {
                z-index: 1;
                max-width: 100%; /* downscale the map on mobile devices */
            }
        }
    }

    #content {
        color: @topmeteo-blue;

        *::before {
            font-family: 'topmeteo';
            line-height: 1;
        }

        /* + + + + +  listen  + + + + + */
        ul {
            margin: 0;
            padding: 0;
        }

        li {
            list-style-type: none;
            font-size: @topmeteo-font-size;
        }

        /* + + +  toggle-list  + + + */
        .taf {
            padding-left: @icon-space;

            > li {
                position: relative;
                clear: left;
            }

            span > span {
                font-weight: bold;
            }
        }

        /* + + +  1. col (icon)  + + + */
        .toggle-icon {
            position: absolute;
            top: 0;
            left: -@icon-space;
            opacity: .6;
            font-size: 50px; // special icon
            line-height: 1;
            &:extend(.icon-minus-circled all);
        }

        /* first state: open */
        .extended {
            li {
                margin-left: -@icon-space;
            }

            + .toggle-icon {
                &:extend(.icon-minus-circled all);
            }

            &[style="display: none;"] {
                + .toggle-icon {
                    &:extend(.icon-plus-circled all);
                }
            }
        }

        /* first state: closed */
        .closed {
            .extended {
                display: none;

                + .toggle-icon {
                    &:extend(.icon-plus-circled all);
                }
            }

            .extended[style="display: block;"] {
                + .toggle-icon {
                    &:extend(.icon-minus-circled all);
                }
            }
        }

        /* + + +  2. col (EDAH HERINGSDORF)  + + + */
        h3 {
            float: left;
            text-transform: uppercase;
            line-height: 1.3;

            + ul {
                display: inline-block;
                .pad-t(.5);
            }

            a {
                position: relative;
                z-index: 1;
                display: block;
                margin-left: -@icon-space;
                padding-left: @icon-space;
            }
        }
    }

    /* + + + + +
	Formate Metar/TAF Meldungen
	$Revision: 1.13 $
	$Date: 2012/07/17 08:39:41 $
    + + + + + */
    .metar_ovc_20 {
        color: @metar_yellow;
    }
    .metar_bkn_20 {
        color: @metar_yellow;
    }
    .metar_sct_20 {
        color: #000000;
        font-weight: normal;
    }
    .metar_ovc_10 {
        color: @metar_orange;//#CE7C17;
    }
    .metar_bkn_10 {
        color: @metar_orange;//#CE7C17;
    }
    .metar_sct_10 {
        color: #000000;
        font-weight: normal;
    }
    .metar_ovc_5 {
        color: @metar_red;
    }
    .metar_bkn_5 {
        color: @metar_red;
    }
    .metar_sct_5 {
        color: @metar_orange;//#CE7C17;
    }

    // new colors: MET-989
    .metar_sight_1500 {
        color: @metar_red;
        font-style: italic;
    }
    .metar_sight_3000 {
        color: @metar_orange;
        font-style: italic;
    }
    .metar_sight_5000 {
        color: @metar_yellow;
        font-style: italic;
    }
}



/* + + + + + + + + + + + + + + + + +
 * DESKTOP ONLY
 * + + + + + + + + + + + + + + + + */

@media (min-width: @topmeteo-site-width) {
    body.metar {
        #metar-taf-controls {
            .ctl-metar-taf-map-toggle {
                display: none;
            }

            @topmeteo-metar-taf-map-toggler-height: 110px;

            .metar-taf-map-toggler {
                position: relative;
                height: @topmeteo-metar-taf-map-toggler-height;
                .pad-t(.5);
                .pad-h();

                p {
                    text-align: left;
                }

                .toggle-icon {
                    font-family: 'topmeteo';
                    position: relative;
                    display: block;
                    width: 100%;
                    height: 1px;
                    top: 0; left: 0;
                    z-index: 20;
                    opacity: .6;
                    cursor: pointer;

                    line-height: 1;
                    /* state: map open */
                    &::before{
                        font-size: (@topmeteo-metar-taf-map-toggler-height + 10); // special icon
                        line-height: @topmeteo-metar-taf-map-toggler-height;
                        margin-bottom: -30px;
                    }

                    &:hover {
                        opacity: .8;
                    }
                }
            }
            /* state: map closed */
            &.closed {
                #metar-taf-map {
                    height: 160px;
                    overflow: hidden;
                }
            }
        }

        #content {
            .pad-h(4);

            .break {
                display: block;
            }

            h3 {
                display: inline-block;
                width: @headline-width;
                font-size: (@topmeteo-font-size + 2);
                margin-top: 8px;

                + ul {
                    width: (99 - @headline-width);
                }
            }

            /* + + +  toggle-list  + + + */
            .taf {
                > li {
                    margin-bottom: 12px;
                }
            }

            /* first state: open */
            .extended {
                margin-left: @headline-width;
                padding-left: @icon-space; // like .taf

                li {
                    margin-left: -@icon-space;
                }
            }

            /* + + +  3. col (TAF/S und 11:50Z)  + + + */
            .metar-date {
                display: table-cell;
                width: 4em;
            }

            /* + + +  4. col (140649Z 1407/1415 01009KT 9999 SCT030 PROB30)  + + + */
            .metar-string {
                display: table-cell;
            }
        }
    }
}


/* + + + + + + + + + + + + + + + + +
 * MOBILE ONLY
 * + + + + + + + + + + + + + + + + */

@media (max-width: @topmeteo-site-width-max) {
    body.metar {
        .notifications-inner {
            &:first-child {
                margin-top: 0;
                margin-bottom: (@topmeteo-pad / 2);
            }
        }

        .controls-area {
            > .container {
                padding-top: @topmeteo-headerbar-xs;
            }
        }

        #metar-taf-controls {
            @icon-size: 44px;

            .ctl-metar-taf-map-toggle {
                .centered-panel();
                opacity: 1;

                .met-btn {
                    height: @icon-size;
                    background-color: transparent;
                    border-color: @topmeteo-blue-dark;
                    padding-top: 0;
                }
            }

           .metar-taf-map-toggler {
               span,
               p {
                   display: none;
               }
           }

            &.closed {
                #metar-taf-map {
                    height: 0;
                    overflow: hidden;
                    //border-bottom: @icon-size solid #fff; //necessary for metar-taf lists (use case map closed only), otherwise the button covers parts of it,
                    // padding or margins wouldn't work because of blue background
                }
            }
        }

        .content-area {
            > .container {
                margin-top: 0;
                .pad-t();
            }
        }

        #content {
            .pad-h(.5);

            .break {
                display: inline-block;
                width: 20px;

                &::after {
                    content: '\2212'; // ' - '
                    display: block;
                    float: left;
                    line-height: .75;
                    padding: 0 5px; // special for -
                }
            }

            h3 {
                width: 92%;
                font-size: @topmeteo-mobile-font-size;
                margin-top: 4px;
                margin-bottom: 0;

                + ul {
                    padding-top:4px;
                    width: 92%;
                }
            }

            /* + + +  toggle-list  + + + */
            .taf {
                > li {
                    margin-bottom: 8px;
                }
            }

            /* first state: open */
            .extended {
                li {
                    margin-left: 0;
                }
            }

            /* + + +  3. col (TAF/S und 11:50Z)  + + + */
            .metar-date {
                display: table-cell;
                width: 4em;
            }

            /* + + +  4. col (140649Z 1407/1415 01009KT 9999 SCT030 PROB30)  + + + */
            .metar-string {
                display: table-cell;
            }
        }
    }
}



/* + + + + + + + + + + + + + + + + +
 * SPECIAL MEDIA QUERIES
 * + + + + + + + + + + + + + + + + */
@media (max-width: 600px) {
    body.metar {
        #content {
            .extended {
                margin-left: 0;

                li {
                    margin-left: 0;
                }
            }

            h3 {
                + ul {
                    > li {
                        margin-bottom: 10px;
                    }
                }
            }

            .metar-date,
            .metar-string {
                display: block;
                width: auto;
            }
        }
    }
}