/**
 * @file cross template module for header/navigation
 * ......
 * scope:
 *   - map, metar-taf,location-forecast, satellite
 *   - shop, shop-checkout
 *   - start,index
 *   - login. password-reset-confirm, user-profile
 *   - standard
 *
 * @copyright CosmoCode GmbH
 */

@import (reference) "../setup";
@import (reference) "../helpers/content";
@import (reference) "../helpers/interactiveElems";
@import (reference) "../helpers/metSelect";
@import (reference) "../helpers/raster";


/* + + + + + + + + + + + + + + + + +
 * DESKTOP + MOBILE
 * + + + + + + + + + + + + + + + + */

nav.header {
    &#panel-header {
        .met-select-wrapper {
            &:extend(.icon-menu all);
        }
    }

    .group-location {
        /* default met-select styles */
        .metSelect();

        // MET-695 - no wordbreak in region select
        #region-select {
            li {
                white-space: nowrap;

                &.region {
                    opacity: 0.7;

                    &.available {
                        opacity: 1;
                    }
                }
            }
        }

        /* special */
        .met-select-wrapper{
            z-index: 100;

            &::before{
                top: 5px;
            }
        }

        .styled-dropdown > ol {
            // MET-695 - wide version for country selection
            &[data-placement=bottom-left] {
                top: -2px;
                left: -(@topmeteo-pad);
                right: -(@topmeteo-pad);
                width: auto;
                box-shadow: 0 3px 6px rgba(0, 0, 0, 0.12), 0 3px 6px rgba(0, 0, 0, 0.12);
            }

            > li {
                .pad-r(.25);
            }
        }
    }

    &.container {
        .group-location {
            float: right;
            max-width: 180px;
        }
    }

    .logout-form {
        display: inline;

        button {
            border: none;
            background: none;
            text-transform: uppercase;

            &:hover {
                text-decoration: underline;
            }
        }
    }
}


/* + + + + + + + + + + + + + + + + +
 * DESKTOP ONLY
 * + + + + + + + + + + + + + + + + */

@media (min-width: @topmeteo-site-width) {

    // basic header setup
    nav.header {
        display: block !important; // make sure this is always visible on Desktop even when toggled in mobile before
        height: @topmeteo-header-height;
        max-width: 100%; // breakpoint != viewport (1050px)
        background-color: #fff;
        color: @topmeteo-blue;
        text-transform: uppercase;
        line-height: 1.1em;

        span.break {
            display: block;
            height: 1px;
            overflow: hidden;
        }

        ul {
            .list-unstyled;
            height: @topmeteo-header-height;
            display: flex;
            flex-direction: row;
            align-items: center;
            margin-bottom: 0;

            > li {
                display: inline-block;

                .logout-form button,
                > a {
                    display: flex;
                    flex-direction: column;
                    justify-content: center;
                }
            }

            li {
                flex-grow: 1;
            }
        }

        li {
            .logout-form button,
            a {
                height: @topmeteo-header-height;
                display: inline-block;
                .pad-v(0.5);
                .pad-h(1);
            }
        }

        li.active a {
            background-color: @topmeteo-blue;
            color: #fff;
        }

        .group-navigation-index {
            // hidden or shown by template logic
            // since all the styling is very different from the rest this is setup in index-header-less
        }

        .group-home {
            .make-lg-column(2);
            .pad-l();
            max-width: 125px;

            .topmeteo-logo {
                display: inline-block;
                text-transform: none;
                font-size: (@topmeteo-font-size * 1.5);
                .pad(0);
                .pad-t();
            }
        }

        .group-navigation {
            .make-lg-column(5);
        }

        .group-usertools {
            .make-lg-column(2);
            min-width: 297px;

            .user-profile {
                a {
                    background-image: url(~images/style/userhead.svg);
                    background-position: left center;
                    background-repeat: no-repeat;
                    background-size: @topmeteo-header-height, @topmeteo-header-height;
                    padding-left: @topmeteo-header-height;

                    .current-user{
                        position: relative;
                        white-space: nowrap;
                        text-overflow: ellipsis;
                        overflow: hidden;
                        display: inline-block;
                        max-width: 150px;
                    }
                }

                &.active {
                    a {
                        background-image: url(~images/style/userhead-white.svg);
                    }
                }
            }
        }

        .group-location {
            .make-lg-column(3);
            background-color: @topmeteo-blue;
            color: #fff;

            select {
                color: @text-color;
            }
        }

        .group-footertools {
            display: none;
        }

        .group-legal {
            display: none;
        }
    }

    // additional class on index page
    nav.header-index {
        // these are only shown on mobile
        .group-home,
        .group-navigation,
        .group-usertools,
        .group-location,
        .group-footertools,
        .group-legal {
            display: none;
        }

        // login inputs
        .form-element{
            width: 110px;
            border: 1px solid #fff;
            border-bottom-color: @topmeteo-blue;
            border-right-color: @topmeteo-blue;
            color: @topmeteo-blue;
            padding: 0.4em 10px;
            min-height: 27px;
            margin-right: 7px;
            margin-left: 7px;
        }

        ::placeholder{
            color: @topmeteo-blue;
        }
        ::-webkit-input-placeholder { /* Chrome/Opera/Safari */
            color: @topmeteo-blue;
        }
        ::-moz-placeholder { /* Firefox 19+ */
            color: @topmeteo-blue;
        }
        :-ms-input-placeholder { /* IE 10+ */
            color: @topmeteo-blue;
        }
        :-moz-placeholder { /* Firefox 18- */
            color: @topmeteo-blue;
        }
    }

    // index page header panel
    div.panel-index {
        display: none;
    }
}


/* + + + + + + + + + + + + + + + + +
 * MOBILE ONLY
 * + + + + + + + + + + + + + + + + */

@media (max-width: @topmeteo-site-width-max) {

    // toggle button
    div.header-toggle {
        .toggle();
        top: 0;
        left: 0;
        z-index: (@topmeteo-z-drawer + 1);// MET-312 - header is fixed positioned
        opacity: 1;
        cursor: pointer;

        // special sizes
        width: @topmeteo-headerbar-xs;
        height: @topmeteo-headerbar-xs;

        // MET-312 - header is fixed positioned
        body:not([class="map"]) & {
            position: fixed;
        }

        .icon {
            line-height: @topmeteo-headerbar-xs;
        }

        &[aria-expanded="true"] {
            .icon {
                .icon-close();
                font-size: 35px;
                line-height: 100%;
            }
        }
    }

    nav.header {
        background-color: @topmeteo-blue;
        color: #fff;
        position: absolute;
        top: 0;
        left: 0;
        z-index: @topmeteo-z-drawer;
        width: 90%;
        height: 100%;
        overflow: auto;
        display: none; // hidden by default

        span.break {
            display: inline-block;
            width: 5px;
            overflow: hidden;
        }

        &#panel-header {
            position: fixed;
            height: auto;
            max-height: 100%;
            overflow: auto;
            width: auto;

            .row {
                padding-top: @topmeteo-headerbar-xs;

                ul {
                    margin: @topmeteo-pad/2 (@topmeteo-pad / 2);
                    padding: 0 0 (@topmeteo-pad / 2) 0;

                    &.group-legal {
                        border-bottom: 0 none;
                    }

                    .payment-methods {
                        margin-left: -3px;
                    }

                    li {
                        list-style-type: none;
                        font-size: @topmeteo-mobile-font-size;
                        text-transform: uppercase;

                        a,
                        span.prefix-lang {
                            padding: 6px @topmeteo-pad / 2;
                            display: block;
                            font-weight: @topmeteo-weight-lightest;

                            br {
                                display: none;
                            }
                        }

                        &.lang-switcher {
                            .prefix-lang {
                                float: left;
                                /* give it the doubled spacing of the margin between flags */
                                padding-right: 8px;
                                margin-right: 8px;
                            }

                            .clearer {
                                display: block;
                                height: 3px;
                            }

                            .lang {
                                background: transparent none no-repeat center center;
                                background-size: cover;
                                text-indent: -1000px;
                                display: inline-block;
                                width: 1.5em;
                                height: 1em;
                                vertical-align: text-top;
                                margin: 8px 6px 4px 0;

                                &.en-gb {
                                    background-image: url(~images/flags/gb.svg);
                                }

                                &.de {
                                    background-image: url(~images/flags/de.svg);
                                }

                                &.fr {
                                    background-image: url(~images/flags/fr.svg);
                                }
                            }
                        }

                        &.social-media {
                            a {
                                display: flex;
                                align-items: center;

                                span {
                                    margin-right: .3em;
                                }

                                img {
                                    width: 16px;
                                    height: 16px;
                                }
                            }
                        }
                    }
                }
            }

            .menu-layer {
                display: none;
            }

            &[aria-expanded="true"] {
                .menu-layer {
                    top: 0 !important;
                    bottom: 0 !important;
                    left: 0;
                    display: block;
                    width: 100vw;
                    height: 100vh;
                    position: fixed;
                    z-index: 10;
                }
                .row {
                    position: relative;
                    z-index:11;
                }
            }
        }

        a {
            color: #fff;
        }

        ul {
            border-bottom: 1px solid #fff;
        }

        /* mobile menu home template */
        .group-navigation-index {
            form {
                display: none;
            }
        }

        .group-home {}

        .group-navigation {}

        .group-usertools {}

        .group-location {
            display: none;
        }

        .group-footertools {}

        .group-legal {}
    }

    // index page header panel
    div.panel-index {
        background-color: @topmeteo-blue;
        color: #fff;
        z-index: @topmeteo-z-top;
        height: 50px;

        a {
            color: #fff;
        }
    }
}
