/**
 * @file sub module for template module location-forecast
 * modul specific elems in controls area
 * ......
 * scope:
 *   - location-forecast
 *
 * @copyright CosmoCode GmbH
 */

@import (reference) "../setup";
@import (reference) "../helpers/content";
@import (reference) "../helpers/interactiveElems";
@import (reference) "../helpers/metSelect";
@import (reference) "../helpers/raster";

@search-input-width: 186px;
// @TODO styles are not in scope location-forecast
// @TODO some mobile styles are not in scope lfc-controls, check


/* + + + + + + + + + + + + + + + + +
 * DESKTOP + MOBILE
 * + + + + + + + + + + + + + + + + */

#lfc-controls {
    clear: left;
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-start;
    margin: 0;
    padding: 0;

    > li {
        list-style-type: none;
        width: 25%;
        position: relative;
        padding-top: 5px;
        padding-bottom: 0;

        .react-wrapper {
            display: inline;
            float: right;
        }

        span.label {
            display: inline-block;
            text-align: right;
            font-size: @topmeteo-font-size;
            line-height: 1.42857143;
            font-weight: @topmeteo-weight-light;
            .pad-t(0.5);

        }

        &#location-favorites {

            .met-select-wrapper {
                margin-top: 0;
            }

            ol {
                width: auto;

                .unavailable {
                    color: @disable-color-grey;
                }

                /* + + +  trash icon  + + + */
                .change-location {
                    display: inline-block;

                    + .icon-trash-empty {
                        position: relative;
                        top: 0;
                        left: -10px;
                        display: inline-block;
                    }
                }
            }
        }

        &#location-map {
            z-index: 10;
        }

        &#location-search {
            &:extend(.icon-search all);
            z-index: 11;

            input.form-element {
                color: #fff;
                text-transform: none;

                &:hover,
                &:focus,
                &:active {
                    color: #fff;
                }
            }

            /* + + +  suggestion-list  + + + */
            .location-search {
                position: relative;
                input.form-element{
                    position: relative;
                    z-index: 41;
                }

            }

            .search-suggestions-list {
                position: absolute;
                left: 0;
                max-width: 100%;
                min-width: @search-input-width;
                background-color: @topmeteo-blue;
                padding-left: 0;

                &:not(:empty) {
                    border: solid 1px @topmeteo-blue-shade;
                    border-top: 0 none;
                }

                li {
                    list-style-type: none;
                    position: relative;
                    z-index: 41;

                    &:nth-child(odd) {
                        background-color: @topmeteo-blue-shade;
                    }

                    .icao,
                    .name {
                        padding-right: 0.5em;
                    }

                    span.shop{
                        position: relative;
                        display: inline-block;
                        width: 29px; height: 10px;

                        &:before{
                            .icon-font-basics();
                            position: absolute;
                            font-size: 20px;
                            top: -5px;
                        }
                        &:extend(.icon-basket all);

                    }
                    &.available{
                        span.shop{
                            display: none;
                        }
                    }

                    &.closer{
                        position: fixed;
                        top: 0; left: 0; width: 100%; height: 100%;
                        background-color: transparent !important;
                        z-index: 40;

                    }
                }
                li.closer{
                    display: none;
                }
                li + li.closer{
                    display:block
                }

                .focus {
                    a {
                        background-color: #fff;
                        color: @topmeteo-blue;
                        text-decoration: none;
                    }
                }

                a {
                    display: block;
                    text-transform: uppercase;
                    .pad-v(.25);
                    .pad-h(.5);

                    &:hover,
                    &:focus,
                    &:active {
                        background-color: #fff;
                        color: @topmeteo-blue;
                        text-decoration: none;
                    }
                }
            }
        }

        &#start-hour {
            z-index: 8;
            text-align: right;

            input[type=text] {
                max-width: 60px;
            }
        }

        &#date-select {
            z-index: 7;

            .styled-dropdown {
                li {
                    opacity: 0.7;

                    &.available {
                        opacity: 1;
                    }
                }
            }
        }

        &#date-switcher {
            width: 50%;
            z-index: 6;

            .met-btn {
                &.disabled {
                    opacity: .3;
                }
            }
        }

        &#config-header {
            width: 100%;
            .pad();
        }

        &#resolution-select {
            z-index: 5;
            text-align: right;

            input[type=text] {
                max-width: 60px;
            }
        }

        input.form-element {
            border: 1px solid transparent;
            border-bottom-color: #fff;
            border-right-color: #fff;
            background-color: transparent;
            font-size: @topmeteo-font-size;
            text-transform: uppercase;
            border-radius: 0;
            .pad-h();
            .pad-v(.25);
            opacity: .8;

            &:focus,
            &:hover {
                background-color: @topmeteo-blue-shade;
            }
        }

        ::placeholder {
            color: #fff;
            text-transform: uppercase;
        }
        ::-webkit-input-placeholder { /* Chrome/Opera/Safari */
            color: #fff;
            text-transform: uppercase;
        }
        ::-moz-placeholder { /* Firefox 19+ */
            color: #fff;
            text-transform: uppercase;
        }
        :-ms-input-placeholder { /* IE 10+ */
            color: #fff;
            text-transform: uppercase;
        }
        :-moz-placeholder { /* Firefox 18- */
            color: #fff;
            text-transform: uppercase;
        }
    }
}



/* + + + + + + + + + + + + + + + + +
 * DESKTOP ONLY
 * + + + + + + + + + + + + + + + + */

@media (min-width: @topmeteo-site-width) {
    #lfc-controls {
        #location-map {
            .struct-link-wrapper {
                margin-top: 0;
            }

            .struct-link {
                position: relative;
                .pad-l(2);

                &::before {
                    content: url('~images/style/map.svg');
                    display: inline-block;
                    position: absolute; top: -9px; left: 0;
                }
            }
        }
        li#date-select {
            width: 36%;
        }
        li#start-hour,
        li#resolution-select{
            width: 14%;
        }
        li#location-search{
            @offset: @topmeteo-pad - 2px /* align magnifier and stripes */;

            margin-left: @offset;
            width: calc(50% - @offset);

            span{
                &.icao, &.country, &.coordinates{
                    white-space: nowrap;
                    padding: 0 1px;
                    display: inline-block;
                }
            }
        }

        li#date-switcher {
            width: 50% - 14%; /* take the remaining space between search and resolution select */
            padding-left: @topmeteo-pad;  /* align with stripes above */
        }

        #location-search {
            .topmeteo-icon();
            padding-top: 8px;

            &::before{
                position: absolute;
                top: 12px;
                left: 0;
                font-size: (@topmeteo-font-size + 4);
            }

            input.form-element {
                padding: 4px @topmeteo-pad/2 4px 32px;
                min-width: @search-input-width;
            }
        }
    }
}


/* + + + + + + + + + + + + + + + + +
 * MOBILE ONLY
 * + + + + + + + + + + + + + + + + */

@media (max-width: @topmeteo-site-width-max) {
    #lfc-controls {
        display: block;
        position: relative;
        margin: 0;
        padding: 0;

        > li {
            padding-top: 0;

            span.label {
                margin-top: @topmeteo-pad*0.5;
            }

            .config-toggle {
                position: absolute;
                top: @topmeteo-pad;
                right: @topmeteo-pad;
                width: @topmeteo-toggle-size;
                height: @topmeteo-toggle-size;
                background-color: @topmeteo-blue;
                overflow: hidden;
                color: #fff;
                cursor: pointer;

                .icon {
                    margin: 0;
                    padding: 0;
                    font-size: 29px;
                    line-height: @topmeteo-toggle-size;
                    text-align: center;
                    display: block;
                    width: 100%;
                    height: 100%;
                    border: 1px solid @topmeteo-blue;
                }
            }

            .met-select-wrapper select.met-select {
                cursor: pointer;
                border-color: @topmeteo-blue-dark;
                height: @topmeteo-toggle-size;
                background-position: 100% 14px;
                font-size: @topmeteo-mobile-font-size;
            }

            .struct-link-wrapper {
                padding: 0;
                display: block;
            }

            .struct-link {
                height: @topmeteo-toggle-size;
                display: block;
                padding: @topmeteo-pad/2 @topmeteo-pad 6px;
                font-size: @topmeteo-mobile-font-size;
                border: 1px solid @topmeteo-blue-dark;
            }

            input.form-element {
                height: @topmeteo-toggle-size;
                font-size: @topmeteo-mobile-font-size;
                margin-top: @topmeteo-pad/2;
                background-color: @topmeteo-blue-td;
                color: @topmeteo-blue-dark;
                width: 100%;
                min-width: 127px;
                .pad();
            }

            ::placeholder {
                color: @topmeteo-blue-dark;
            }
            ::-webkit-input-placeholder { /* Chrome/Opera/Safari */
                color: @topmeteo-blue-dark;
            }
            ::-moz-placeholder { /* Firefox 19+ */
                color: @topmeteo-blue-dark;
            }
            :-ms-input-placeholder { /* IE 10+ */
                color: @topmeteo-blue-dark;
            }
            :-moz-placeholder { /* Firefox 18- */
                color: @topmeteo-blue-dark;
            }

            &#location-favorites {
                width: 100%;

                .met-select-wrapper {
                    margin: 0 110px @topmeteo-pad/2 45px; // 70: region
                }
            }

            &#config-mobile {
                position: fixed;
                right: 0;
                bottom: -1px;
                z-index: 15;
                width: 45px;
                height: 45px;
                padding: 0;

                .config-toggle {
                    top: 0;
                    right: 0;

                    // special sizes
                    width: 44px;
                    height: 44px;
                    opacity: .8;

                    .icon {
                        line-height: 40px;
                    }
                }
            }

            &#location-map {
                z-index: 10;
                order: 90;
                position: absolute;
                left: 33%;
                width: 25%;
                bottom: 0;
                padding: 2px 0;
                a.struct-link{
                    text-align: center;
                }
            }

            &#location-search {
                z-index: 11;
                order: 99;
                width: 41.666667%;
                position: absolute;
                right: 0;
                bottom: 0;
                min-width: 115px;
                padding: 2px 8px;
                input.form-element{
                    padding: 6px @topmeteo-pad/2 @topmeteo-pad/2;
                    height: 39px; //optical illusion 40-1px transparent top border
                    min-width: 115px;
                }

                /* + + +  suggestion-list  + + + */
                .search-suggestions-list {
                    left: auto;
                    right: 0;
                    width: 100%;
                    min-width: 115px;
                    overflow: hidden;
                }
            }

            li#start-hour {
                text-align: left;
                z-index: 8;
                width: 50%;
                float: left;
                .react-wrapper{
                    float: none;
                    display: block;
                    padding-right: 3px;
                    .met-select-wrapper{
                        float: none;
                        display: block;
                    }

                }

                > span.label{
                    display: none;
                }

                input[type=text] {
                    max-width: 60px;
                }
            }

            &#date-select {
                z-index: 7;
                width: 33.333333%;
                padding: 2px @topmeteo-pad/2;
            }

            &#date-switcher {
                width: 50%;
                z-index: 6;
            }

            nav.config-area{
                margin-right: 45px;
                margin-left: 45px;
                li{
                    margin-right: 0;
                }
                .clearer{
                    height: 6px;
                }
            }

            li#resolution-select {
                text-align: right;
                float: right;
                display: block;
                width: 50%;
                z-index: 5;
                > span.label{
                    display: none !important;
                }
                .react-wrapper {
                    float: none;
                    display: block;
                    padding-left: 3px;
                    .met-select-wrapper{
                        float: none;
                        display: block;
                    }
                }

                input[type=text] {
                    max-width: 60px;
                }
            }

            &#config-header {
                width: 100%;
                padding: 0;
            }
        }
    }

    #location-search {
        &::before {
            display: none;
        }
    }

    div.header-toggle {
        .icon {
            border: 1px solid @topmeteo-blue;
        }
    }
}



/* + + + + + + + + + + + + + + + + +
 * SPECIAL MEDIA QUERIES
 * + + + + + + + + + + + + + + + + */

@media  (max-width: @topmeteo-xxs-width){
    #lfc-controls > li {
        .struct-link,
        &#location-search input.form-element {
            .pad-h(.25);
        }
    }
}
