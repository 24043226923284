/**
 * @file cross template module for standard pages (default body class for topmeteo templates)
 * can have additional body class
 * ......
 * scope:
 *   - standard
 *   - login.standard, user-profil.standard, password-reset-confirm.standard, shop.standard, shop-checkout.standard
 *
 * @copyright CosmoCode GmbH
 */

@import (reference) "../setup";
@import (reference) "../helpers/content";
@import (reference) "../helpers/interactiveElems";
@import (reference) "../helpers/metSelect";
@import (reference) "../helpers/raster";


/* + + + + + + + + + + + + + + + + +
 * DESKTOP + MOBILE
 * + + + + + + + + + + + + + + + + */

body.standard {
    .content-area {
        margin-top: 20px;

        .row {
            background-color: #FFF;
        }
    }

    .container {
        position: relative;

        main {
            clear: left;
        }
    }

    header {
        .pad-t();
        .pad-b(.5);
    }

    .page-content {
        .pad-b();

        h2 {
            font-size: @topmeteo-mobile-font-size;
            margin: 0;
        }


/* + + +  first-steps - content navigation  + + + */
        .nav-list {
            margin-top: @topmeteo-pad;
        }

        .row {
            margin-bottom: @topmeteo-pad;

            div[class*="col"] {
                box-sizing: border-box;
                .pad-l();

                &:first-of-type {
                    padding-left: 0;
                }
            }
        }

        .nav-link {
            display: block;
            background-color: @topmeteo-blue-dark;
            color: #FFF;
            font-size: @topmeteo-mobile-font-size;
            text-align: center;
            .pad-v();
        }
    }



/* + + +  toggle  + + + */
    #accordion {
        h3 {
            background-color: @topmeteo-grey-light;
            font-size: @topmeteo-mobile-font-size;
            margin: 0 0 @topmeteo-pad;
            .pad-h();
            .pad-v(.5);

            a {
                display: block;
                color: @topmeteo-blue;

                &:focus {
                    text-decoration: none;
                }
            }
        }

        .toggle-link {
            display: block;
            background-color: @topmeteo-blue;
            border: solid 1px @topmeteo-blue;
            color: #FFF;
            text-align: center;
            .pad-h();
            .pad-v(.5);

        }

        > .toggleblock-head {
            display: inline-block;
            min-width: 32%;
            margin-top: @topmeteo-pad;
            margin-bottom: @topmeteo-pad;

            + .toggleblock-head {
                margin-left: 1.6%;
            }

            a {
                display: block;
                border: solid 1px @topmeteo-blue;
                color: @topmeteo-blue;
                text-align: center;
                .pad(.5);

                &:hover,
                &[aria-expanded="true"] {
                    background-color: @topmeteo-blue;
                    color: #FFF;
                    text-decoration: none;
                }

                &[aria-expanded="true"] {
                    cursor: default;
                }
            }
        }

        .toggleblock-content {
            h4 {
                font-size: @topmeteo-mobile-font-size-small;
                .pad-t(.5);
                .pad-b(.5);
            }

            h5 {
                font-weight: bold;
                .pad-t(.5);
                .pad-b(.25);
            }

            h4,
            h5 {
                .pad-h();
            }

            ul {
                .pad-v(.5);
            }

            p {
                margin: 0;
                .pad-h();

                + p {
                    .pad-t(.5);
                }

                a {
                    text-transform: uppercase;
                }
            }
        }
    }


/* + + + +  accordion in accordion  + + + + + */
    .toggleblock-content {
        .toggleblock-head {
            margin-top: @topmeteo-pad;

            a {
                position: relative;

                &::after {
                    content: '\002B';
                    position: absolute;
                    right: 0;
                    top: 50%;
                    display: block;
                    width: 1rem;
                    height: 2rem;
                    font-weight: bold;
                    margin-top: -1rem;
                }

                &[aria-expanded="true"] {
                    &::after {
                        content: '\2212';
                    }
                }
            }
        }
    }

    .panel {
        box-shadow: none;
        border: 0 none;
    }

    .box-image {
        display: block;
        max-width: 70%;
        box-sizing: content-box;
        border: solid 1px @topmeteo-blue;
        margin: @topmeteo-pad;
        line-height: 0;

        &.is-no-border {
            border: 0 none;
        }

        img,
        video {
            width: 100%;
        }

        p {
            text-align: center;
            line-height: 1.7rem;
            padding: 0;
            .pad-v();
        }
    }
}


/* + + + + + + + + + + + + + + + + +
 * DESKTOP ONLY
 * + + + + + + + + + + + + + + + + */

@media (min-width: @topmeteo-site-width) {
    body.standard {
        header,
        .page-content {
            .pad-h(4);
        }

        .box-image {
            &.is-half-size {
                width: 50%;
            }

            &.is-small-size {
                width: 25%;
                max-width: 150px;
            }
        }


/* + + +  first-steps - content navigation  + + + */
        .page-content {
            .col-lg-9 {
                .pad-b(2);
            }

            .nav-link {
                .pad-h();
            }
        }

        .form {
            h2 {
                margin-top: (@topmeteo-pad * 2);
            }
        }
    }
}


/* + + + + + + + + + + + + + + + + +
 * MOBILE ONLY
 * + + + + + + + + + + + + + + + + */

@media (max-width: @topmeteo-site-width-max) {
    body.standard {
        .box-image {
            width: auto;
            max-width: none;

            &.is-half-size {
                width: auto;
                max-width: none;
            }

            &.is-small-size {
                width: auto;
                max-width: 150px;
            }
        }

        #accordion {
            .toggleblock-head {
                display: block;
                width: 100%;
                margin-top: @topmeteo-pad;

                + .toggleblock-head {
                    margin-left: 0;
                }

                + .panel-default {
                    margin-top: (@topmeteo-pad * 2.5);
                }

                a,
                .toggle-link {
                    .pad-v();
                }
            }

            h3 {
                .pad-v(0);
            }
        }

        .content-area {
            margin-top: 0;

            header,
            .page-content {
                .pad-h();
            }

            .page-content {
                .col-lg-9 {
                    max-width: @topmeteo-xxs-width;
                    margin-left: auto;
                    margin-right: auto;
                }

/* + + +  first-steps - content navigation  + + + */
                .row {
                    margin-bottom: 0;

                    div[class*="col"] {
                        .pad-t();
                        .pad-l(0);
                    }
                }

                .nav-link {
                    .pad-h(.5);
                }
            }

            .container {
                margin-top: 0;
            }

            header {
                h1 {
                    background-color: #FFF;
                    color: @topmeteo-blue;
                    text-align: center;
                    margin-left: 40px;
                    margin-right: 40px;
                    padding-top: (@topmeteo-pad/2);
                }
            }
        }
    }
}


#no-script-message {
    display: none;
    font-size: 30px;
    margin: 10px;

    &.no-script {
        display: block;
    }
}

#page-wrapper {
    display: block;

    &.no-script {
        display: none;
    }
}
