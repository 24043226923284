.loading-wrapper {
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  position: absolute;
  align-items: center;
  background: rgba(0, 0, 0, 0.3);

  .loading {
    border: 16px solid white;
    border-top-color: @topmeteo-blue;
    border-radius: 50%;
    width: 120px;
    height: 120px;
    animation: spin 2s linear infinite;
    margin: 0 auto;

    @keyframes spin {
      0% {
        transform: rotate(0deg)
      }

      100% {
        transform: rotate(360deg)
      }
    }
  }

  @media (max-width: @topmeteo-site-width-max) {
    position: fixed;
  }
}
