/**
 * @file sub module for template module map (projection maps)
 * matrix controls for control area
 * ......
 * scope:
 *   - map
 *
 * @copyright CosmoCode GmbH
 */


@import (reference) "../setup";
@import (reference) "../helpers/content";
@import (reference) "../helpers/interactiveElems";
@import (reference) "../helpers/metSelect";
@import (reference) "../helpers/raster";


@width-control-date: 48px;
@width-control-time: 48px;


/* + + + + + + + + + + + + + + + + +
 * DESKTOP + MOBILE
 * + + + + + + + + + + + + + + + + */

body.map {
    #mtx-controls {
        .component-matrix {
            tr {
                font-size: 11px;
                line-height: 100%;
            }

            td {
                padding: 1px 1px 0 0;
                font-size: 11px;
                line-height: 100%;

                /* make as square */
                &[colspan="1"] {
                    a {
                        position: relative;
                        width: 100%;

                        &::before {
                            content: '';
                            display: block;
                            padding-top: 100%;
                        }
                    }
                }

                &.current {
                    background-color: @topmeteo-blue-dark;
                }

                a {
                    display: block;
                    height: 12px;
                    min-width: 13px;
                    overflow: hidden;
                    background-color: rgba(156, 175, 187,.6);  //@topmeteo-blue-td;
                    color: @topmeteo-blue-td;
                    font-size: 1px; // special size in matrix

                    &:hover,
                    &:active {
                        background-color: @topmeteo-grey-light;
                        border-color: #fff;
                        color: @topmeteo-grey-light;
                    }
                }
            }

            thead {
                th {
                    min-height: 16px;
                    min-width: 16px;
                    font-weight: normal;
                    .pad-t(.25);
                    .pad-b(.125);

                    &.current {
                        background-color: @topmeteo-blue-dark;
                        color: #fff;
                    }

                    // show minutes lighter
                    .minutes {
                        opacity: @topmeteo-header-minutes-opacity;
                    }

                    &.timezone-name {
                        text-align: right;
                        padding-right: @topmeteo-pad;
                    }
                }
            }

            tbody {
                tr {
                    &:hover {
                        background-color: rgba(72, 105, 123, .7);
                    }

                    &.product-category-spacer {
                        background-color: transparent;
                    }

                    th {
                        span {
                            &.icon-favourite {
                                cursor: pointer;
                            }

                            &.product-title {
                                display: inline-block;
                                margin-left: @topmeteo-pad/2;
                                padding-top: 3px;
                            }
                        }
                    }

                    &.product-category-spacer {
                        height: 5px;
                    }
                }

                &:last-of-type {
                    .product-category-spacer {
                        display: none;
                    }
                }
            }

            tr {
                th {
                    font-weight: normal;
                }

                &.current {
                    background-color: @topmeteo-blue-dark;

                    th {
                        color: #fff;
                    }

                    td.current {
                        a {
                            background-color: #fff;
                            border-color: #fff;
                            color: #fff;
                        }
                    }
                }
            }
        }

        /* browse */
        .ctl-skip {
            text-align: right;
            margin-left: auto;

            *::before,
            *::after {
                font-family: 'topmeteo';
            }

            button {
                display: inline-block;
                width: 45px;  /* make them all equally wide */

                &:nth-child(odd) {
                    margin-right: 8px;
                }
            }
        }

        .ctl-date {
            .met-select-wrapper {
                .run-status,
                .styled-dropdown > ol > li:before {
                    display: inline-block;
                    width: 12px;
                    height: 12px;
                    border-radius: 6px;
                    position: absolute;
                    top: 10px;
                    left: 20px;
                }

                &.runinfo-in-process .run-status,
                .styled-dropdown > ol >li.in-process {
                    background-color: @run-status-color;
                }

                &.runinfo-done .run-status,
                .styled-dropdown > ol >li.done {
                    background-color: transparent;
                }

                &.runinfo-done .run-status {
                    display: none;
                }

                .styled-dropdown {
                    li {
                        opacity: 0.7;

                        &.available {
                            opacity: 1;
                        }
                    }
                }
            }
        }

        .ctl-mtx-info {
            *::before,
            *::after {
                font-family: 'topmeteo';
            }

            padding: 0 @topmeteo-pad/2 @topmeteo-pad/8 @topmeteo-pad;

            .link {
                display: inline-block;
                text-transform: uppercase;
                .pad-b(.5);

                &::before {
                    display: inline-block;
                    width: 20px;
                    line-height: 1;

                    &:hover,
                    &:focus,
                    &:active {
                        text-decoration: none;
                    }
                }
            }
        }

        .ctl-product {
            .met-select-wrapper {
                .styled-dropdown ol {
                    li.is-favorite {
                        &:first-child {
                            &:extend(.icon-star all);

                            &:before {
                                .icon-font-basics();
                                width:100%;
                                display: block;
                            }
                        }
                    }

                    li.is-favorite + li.not-favorite {
                        &:extend(.icon-star-empty all);

                        &:before {
                            .icon-font-basics();
                            width:100%;
                            display: block;
                        }
                    }
                }
            }
        }

        .ctl-loop-play {
            button {
                &.met-btn {
                    &::before {
                        font-family: 'topmeteo';
                    }

                    &.is-play {
                        &::before {
                            &:extend(.icon-play:before);
                        }
                    }

                    &.is-stop {
                        &::before {
                            &:extend(.icon-stop:before);
                        }
                    }
                }
            }
        }
    }
}


/* + + + + + + + + + + + + + + + + +
 * DESKTOP ONLY
 * + + + + + + + + + + + + + + + + */

@media (min-width: @topmeteo-site-width) {
    body.map {
        #mtx-controls {
            .row {
                .pad-b(0.5);

                &:last-of-type {
                    overflow: hidden; // for hiding range-thumb (ctl-loop-speed)
                }
            }

            .met-select-wrapper,
            .struct-link-wrapper {
                margin-top: 2px;
            }

            .ctl-date {
                .met-select-wrapper {
                    .run-status {
                        left: 20px;
                    }
                }

                .runinfo-in-process {
                    .styled-dropdown > input {
                        text-indent: 36px;
                    }
                }
            }

            .struct-link-wrapper {
                margin-left: @padding-small-horizontal;
                padding-right: @padding-small-horizontal;
            }

            .ctl-mtx-toggle {
                .struct-link-wrapper {
                    margin-left: (@topmeteo-pad - 2);
                }
            }

            .ctl-loop-play {
                span.timezone-name {
                    float: right;
                    display: inline-block;
                    margin-right: 8px;
                }

                button.met-btn {
                    float: left; clear: right;
                    padding: 3px @topmeteo-pad/2 3px;
                    margin-left: -12px;

                    &.is-play,
                    &.is-stop {
                        &::before {
                            display: none;
                        }
                    }
                }
            }

            .ctl-mtx-info {
                padding-top: 9px;

                h2 {
                    font-size: 12px;
                    font-weight: bold;
                }

                p {
                    font-size: 11px;
                    margin-bottom: 6px;
                }

                > div a.link {
                    margin-top: 8px;

                    &.reset {
                        margin-top: 0;
                    }
                }
            }

            .ctl-product {
                margin-left: 0;

                .met-select-wrapper {
                    margin-left: 0;
                }
            }

            table  {
                width: 100%;
                table-layout: fixed;
                margin-top: 0;

                thead th:first-child,
                tbody th {
                    width: ((@topmeteo-site-width / 12) * 3); // FIXME better way to use column width?
                    padding-left: @topmeteo-pad/2;

                    span.product-title{
                        font-size: 11px;
                    }
                    span.icon-favourite{
                        padding-left: 4px;
                    }
                }

                thead th,
                td {
                    text-align: center;
                }

                thead th {
                    span {
                        font-size: 11px;
                    }
                }
            }


/* + + + + +  slider-range  + + + + + */
            .ctl-loop-speed {
                .met-select-wrapper {
                    position: relative;
                    width: 139px;
                    height: 25px;
                    padding-top: 25px; // like height (for firefox)
                    background: @topmeteo-blue-shade url('~images/style/bg-sliderrange.png') no-repeat left top;
                    margin-right: 0;
                    padding-right: 0;

                    &::before,
                    &::after {
                        position: absolute;
                        top: -1.2em;
                        font-family: @topmeteo-font-stack;
                        font-size: @topmeteo-font-size;
                        -webkit-font-smoothing: antialiased;
                        line-height: 1;
                    }
                    &::before {
                        left: 0;
                        content: attr(data-speed-slow);
                    }
                    &::after {
                        right: 0;
                        content: attr(data-speed-fast);
                    }
                }

                /* + + +  chrome, safari  + + + */
                input[type="range"] {
                    width: 100%;
                    appearance: none;
                    background-color: transparent;
                    margin: -17px 0 0;
                    padding: 0;
                }
                input[type=range]:focus {
                    outline: none;
                }
                input[type=range]::-webkit-slider-thumb {
                    cursor: pointer;
                    -webkit-appearance: none;
                    height: 17px;
                    width: 17px;
                    background: transparent;
                    border: 2px solid #fff;
                    border-radius: 50%;
                    margin-top: -7px;
                }
                input[type=range]::-webkit-slider-runnable-track {
                    width: 100%;
                    height: 20px;
                    cursor: pointer;
                    background: transparent;
                }
                input[type=range]:focus::-webkit-slider-runnable-track {
                    background: @topmeteo-blue-shade;
                }

                /* + + +  firefox  + + + */
                input[type=range]::-moz-range-thumb {
                    cursor: pointer;
                    height: 14px;
                    width: 14px;
                    box-shadow: 0 0 0 #000;
                    background: transparent;
                    border: 2px solid #fff;
                    border-radius: 50%;
                }
                input[type=range]::-moz-range-track {
                    cursor: pointer;
                    width: 100%;
                    box-shadow: 0 0 0 #000;
                    background: transparent;
                    border: 0 none;
                    border-radius: 0;
                }

                /* + + +  ie  + + + */
                input[type=range]::-ms-thumb {
                    cursor: pointer;
                    box-shadow: 0 0 0 #000;
                    height: 13px;
                    width: 13px;
                    background: transparent;
                    border: 2px solid #fff;
                    border-radius: 50%;
                    margin-top: -21px;
                }
                input[type=range]::-ms-track {
                    cursor: pointer;
                    width: 100%;
                    height: 40px;
                    background: transparent;
                    border-color: transparent;
                    color: transparent;
                }
                input[type=range]::-ms-fill-lower {
                    background: transparent;
                    border: 0 none;
                    border-radius: 0;
                    box-shadow: 0 0 0 #000;
                }
                input[type=range]::-ms-fill-upper {
                    background: transparent;
                    border: 0 none;
                    border-radius: 0;
                    box-shadow: 0 0 0 #000;
                }
                input[type=range]:focus::-ms-fill-lower {
                    background: transparent;
                }
                input[type=range]:focus::-ms-fill-upper {
                    background: transparent;
                }
            }
            /* slider-range ende */

            .ctl-skip{
                > div{
                    .pad-l(.5);

                    button.met-btn {
                        padding: 4px @topmeteo-pad/2 3px;

                        &.disabled {
                            opacity: .3;
                        }
                    }
                }
            }
        }
    }
}


/* + + + + + + + + + + + + + + + + +
 * MOBILE ONLY
 * + + + + + + + + + + + + + + + + */

@media (max-width: @topmeteo-site-width-max) {
    body.map {
        #mtx-controls {
            min-height: @topmeteo-headerbar-xs;
            background-color: @topmeteo-blue;

            // hide elements we usually don't need
            .ctl-mtx-toggle,
            .ctl-mtx,
            .ctl-skip,
            .ctl-loop-play,
            .ctl-loop-slider,
            .ctl-loop-speed {
                display: none;
            }

            span.timezone-name {
                position: absolute;
                top: (@topmeteo-headerbar-xs + @topmeteo-toggle-size + @topmeteo-pad);
                right: 0;
                z-index: 100000;
                background-color: #5A7A8D;
                opacity: .8;
                line-height: 1;
                .pad-v(.25);
                .pad-h(.5);
            }

            // the loop panel button becomes visible
            .loop-button {
                .toggle();

                position: fixed;
                bottom: 0;
                right: 0;
                z-index: 490;
                opacity: .8;
                -moz-opacity: .8;

                // special sizes
                width: @topmeteo-headerbar-xs;
                height: @topmeteo-headerbar-xs;

                .icon {
                    cursor: pointer;
                    line-height: 38px;
                }
            }


            // product dropdown becomes panel button
            .ctl-product {
                position: absolute;
                top: 0;
                left: 45px;
                right: 70px; // region + info-toggle
                overflow: hidden;
                z-index: @topmeteo-z-top; // under the menu panel, above the loop panel
                height: @topmeteo-toggle-size;
                opacity: 1;

                .met-select-wrapper {
                    margin-right: @topmeteo-toggle-size;

                    select.met-select {
                        text-overflow: clip;
                    }

                    &.has-mobile-short-label {
                        .emulate-short-label {
                            white-space: nowrap;
                            overflow: hidden;
                            text-overflow: ellipsis;
                            text-transform: none;
                        }
                    }
                }
            }


            // loop play button becomes button on the panel
            .ctl-loop-play {
                position: fixed;
                bottom: 70px;
                left: 0;
                z-index: @topmeteo-z-top; // under the menu panel, above the loop panel
                height: @topmeteo-toggle-size;
                width: auto;//150px;
                opacity: .8;
                -moz-opacity: .8;
                margin: 0;
                padding-right: (@topmeteo-pad / 4);

                .timezone-name {
                    position: fixed;
                    left: 0;
                    top: 93px;
                    background-color: @topmeteo-blue-dark;
                    padding: 1px 8px 0;
                }

                .map & {
                    left: auto;
                    right: 51.5%;
                    width: 20%;
                    max-width: 138px;
                    margin-left: 0;

                    button {
                        width: 100%;
                        margin-right: 0;
                        .pad-v();
                    }
                }

                button.met-btn {
                    cursor: pointer;
                    z-index: 501;
                    width: @topmeteo-toggle-size;
                    min-width: 10px;
                    background-color: @topmeteo-blue-dark;
                    border-color: @topmeteo-blue-dark;
                    margin: 0;

                    &::before {
                        position: absolute;
                        display: block;
                        overflow: hidden;
                        color: #fff;
                    }

                    &.is-play {
                        &::before {
                            top: 4px;
                            left: 12px;
                            font-size: 24px;
                        }
                    }

                    &.is-stop {
                        &::before {
                            left: 9px;
                            top: 6px;
                            font-size: 20px;
                        }
                    }
                }
            } // ctl-loop-play


            // loop speed dropwdown becomes button on the panel
            .ctl-loop-speed {
                position: fixed;
                bottom: 70px;
                right: 0;
                z-index: @topmeteo-z-top; // under the menu panel, above the loop panel
                height: @topmeteo-toggle-size;
                width: 56px;
                overflow: hidden;
                opacity: .8;
                -moz-opacity: .8;

                .met-select-wrapper {
                    padding-left: (@topmeteo-pad / 4);
                }

                .map & {
                    left: 50%;
                    width: 27%;
                    max-width: 150px;
                    margin-left: 0;
                }
            } // ctl-loop-speed


            // loop slider becomes element on the panel
            .ctl-loop-slider {
                position: fixed;
                bottom: 0;
                left: 0;
                right: 40px;
                z-index: @topmeteo-z-top; // under the menu panel, above the loop panel
                width: auto;
            } // ctl-loop-slider


/* + + +  date, time panel  + + + */
            .ctl-time {
                width: @width-control-time;

                // MET-687
                .met-select-wrapper {
                    select.met-select {
                        padding-left: 3px;
                        padding-right: 3px;
                    }
                }
            }


            // date dropdown becomes panel button
            .ctl-date {
                position: absolute;
                top: (@topmeteo-toggle-size + 8); // under the menu button
                left: 0;
                z-index: @topmeteo-z-top; // under the menu panel, above the loop panel
                width: 110px;
                height: @topmeteo-toggle-size;
                overflow: hidden;
                opacity: .8;
                -moz-opacity: .8;

                .met-select-wrapper {
                    .run-status {
                        left: 4px;
                        top: 12px;
                    }
                }

                // MET-687
                select.met-select {
                    padding-left: 19px;
                    padding-right: 0;
                }

                // MET-1272:
                // for past dates, we offset the parentheses. This makes the label fit into the button
                // and also visually aligns the weekdays again which makes it look less jumpy on
                // changing the day
                .is-past {
                    select.met-select {
                        padding-left: 14px;
                    }
                }
            }


            // time dropdown becomes visible as panel button
            .ctl-time {
                position: absolute;
                overflow: hidden;
                z-index: @topmeteo-z-top; // under the menu panel, above the loop panel
                height: @topmeteo-toggle-size;
                opacity: .8;
                -moz-opacity: .8;
                top: (@topmeteo-toggle-size + 8); // under the loop panel button
                right: 0;

                select {}
            }
        }


/* + + +  opened panel  + + + */
        .panel-open {
            #mtx-controls {
                .loop-button {
                    i.icon-loop-alt {
                        .icon-close();
                        font-size: 35px;
                    }
                }
            }
        }
    }
}
