/**
 * @file helper mixins and basic classes for metSelect components
 * ......
 * scope:
 *   - global
 *
 * @copyright CosmoCode GmbH
 */



/* + + + + + + + + + + + + + + + + +
 * without media queries
 * + + + + + + + + + + + + + + + + */

/* styles for MOBILE and DESKTOP */
.metSelectForAll(){
    select.met-select{
        cursor: pointer; // spi ergaenzt - aus map.less entfernt
       // text-transform: uppercase;
        -webkit-appearance: none;
        -moz-appearance:    none;
        appearance:         none;
        background: @topmeteo-blue;
        color: #fff;
        border: 1px solid @topmeteo-blue-dark;
        border-radius: 0 !important;
        max-width: 100%;
        width: 100%;

        &::-ms-expand {
            display:none;
        }
    }

    .styled-dropdown {
        position: relative;
        z-index: @topmeteo-z-toggle;

        * {
            box-sizing: border-box;
        }

        > input {
            .pad-t(0.5); .pad-b(0.5);
            text-indent: @topmeteo-pad + @topmeteo-pad/4; //gap between menu icon and chosen fake select option (font-size of icon @topmeteo-pad)
            text-overflow: ellipsis;
            background-color: transparent;
            text-transform: uppercase;
            display: inline-block;
            border: 0 none;
            width: auto;
            min-width: 100%;

            &:focus, &:hover, &:active{
                text-decoration: underline !important;
            }

            &.focus ~ ol {
                transform: scale(1,1);
                opacity: 1;
            }
        }

        > ol {
            position: absolute;
            padding: 0; .pad-t(.5); margin: 0;
            display: block;
            transform: scale(1,0);
            z-index: @topmeteo-z-toggle;
            opacity: 0;
            width: 100%; height: auto; max-width: 500px;
            background: @topmeteo-blue;
            box-shadow: 0 1px 6px rgba(0, 0, 0, 0.12), 0 1px 6px rgba(0, 0, 0, 0.12);
            -webkit-transition: -webkit-transform,-webkit-opacity 0.2s ease-out;
            transition: transform,opacity 0.2s ease-out;
            overflow: auto;

            > li {
                list-style: none;
                padding: .2em @topmeteo-pad*2 .2em @topmeteo-pad/2;
                cursor: pointer;
                //word-wrap: break-word;
                display: block;

                &.level-0 {
                }

                &.level-1 {
                    padding-left: @topmeteo-pad * 1;
                }

                &.level-2 {
                    padding-left: @topmeteo-pad * 2;
                }

                &.level-3 {
                    padding-left: @topmeteo-pad * 3;
                }

                &.styled-dropdown-add {
                    padding: 0;
                    > input {
                        border: 0;
                        padding: .2em @topmeteo-pad .2em @topmeteo-pad/2;
                        width: 100%;
                    }
                }

                &.selected,
                &:active {
                    background: @topmeteo-blue-shade;

                }

                &:focus {
                    outline: 0;
                    outline: 1px solid @topmeteo-grey-light;
                }

                /* nur vorbereitet */
                > .close::before {
                    display: block;
                    position: absolute;
                    right: 15px;
                    float: right;
                    font-size: 21px; // special icon
                    font-weight: 700;
                    line-height: 1;
                    color: #000;
                    text-shadow: 0 1px 0 #fff;
                    opacity: .6;
                }

                &:hover > .close:hover::before {
                    opacity: .9;
                }
                /* ende */
            }
        }

        > ol[data-placement=top-left] {

            transform-origin: bottom left;
            bottom: 0;
            left: -1px; //debug menu icon rendering in ff: icon font in met-select-wrapper::before is placed left: 0, but rendered -1px
        }

        > ol[data-placement=bottom-left] {
            transform-origin: top left;
            top: @topmeteo-pad/4;
            left: -1px;
        }


    }
    /* special case: select with favorites */
    #location-favorites {
        .styled-dropdown {
            > ol {
                padding-top: 0;

                > li {
                    position: relative;
                    padding: 0; // fake select with favorites must not have padding the click event is supposed to be

                    &.divider {
                      border-bottom: 1px solid #ffffff;
                      margin: 5px;
                    }

                    /* star icon for marking favorites */
                    .mark-favorite {
                        position: absolute;
                        top: 6px;
                        left: 4px;
                    }

                    /* option for changing location */
                    .change-location {
                        display: block;
                        padding: .2em @topmeteo-pad .2em (@topmeteo-pad + @topmeteo-pad*0.5);
                    }
                }
            }
        }
    }

    /* special case: cursor variations for fake input and fake input in list */
    .styled-dropdown > input[readonly] {
        cursor: pointer;
    }

    select[data-styledDropdown][disabled] + .styled-dropdown > input[readonly] {
        cursor: default;
    }
}

/* styles DESKTOP ONLY (usage within media queries) */
.metSelectForDesktop(){
    .met-select-wrapper{
        .topmeteo-icon();
        position: relative;
        margin-left: @topmeteo-pad;
        padding-right: @topmeteo-pad;

        &.has-mobile-short-label{
            .emulate-short-label{
                display: none;
            }
        }

        &::before{
            position: absolute;
            z-index: 999;
            font-size: (@topmeteo-font-size + 4);
            top: @topmeteo-pad*0.5;
            left: 0;
            margin: 0;
        }

        &.right-menu-icon{
            text-align: right;
            display: inline-block;

            &::before{
                position: absolute;
                z-index: 999;
                font-size: (@topmeteo-font-size + 4);
                top: @topmeteo-pad*0.5;
                left: auto;
                right: (@topmeteo-pad - 2); // after rendering the icon has topmeteo-pad padding-right
            }

            .styled-dropdown{
                > input{
                    text-align: right;
                    text-indent: 0;
                    padding-right: @topmeteo-pad + @topmeteo-pad/4; //gap between chosen fake select option (font-size of icon @topmeteo-pad) and menu icon
                    min-width: 1px;
                    float: right;
                }

                >ol{
                    min-width: 60px;
                }

                > ol[placement=bottom-left]{
                    left: auto; right: -1px;
                    text-align: right;

                    li{
                        text-align: right;
                    }
                }
            }
        }

        select.met-select{
            display: none !important;
        }

        .styled-dropdown{
            display: block;
        }
    }
}

/* styles MOBILE ONLY (usage within media queries) */
.metSelectForMobile(){
    .met-select-wrapper{
        .styled-dropdown{
            display: none !important;
        }

        select.met-select{
            display: inline-block;
            height: @topmeteo-toggle-size;
            text-overflow: ellipsis;
            //border: 1px solid @topmeteo-blue;
            font-size: @topmeteo-mobile-font-size;
            padding: @topmeteo-pad/2 @triangle-spacer @topmeteo-pad/2 @topmeteo-pad/2; // triangle spacer for triangle background image
            background-color: @topmeteo-blue; // spi ergaenzt - aus map.less entfernt (org: 'right center')
        }

        .met-location-manager {
          position: absolute;
          top: 0;
          right: 65px;
          width: 40px;
          height: 40px;
          cursor: pointer;
          padding: 8px 0;
          text-align: center;

          .control.icon-cog {
            margin: 8px 0;
            color: #fff;
            font-size: 18px;
          }
        }

        &.has-mobile-short-label {
            .emulate-short-label {
                position: relative;
                right: 0;
                top: (@topmeteo-pad / 2);
                z-index: 0;
                display: block;
                width: auto;
                background-color: @topmeteo-blue;
                color: #fff;
                font-size: @topmeteo-mobile-font-size-big;
                text-transform: uppercase;
                text-align: center;
                .pad-h(.25);
            }

            select.met-select {
                position: absolute;
                top: 0;
                right: 0;
                z-index: 1;
                background-color: transparent;
                border-color: transparent;
                color: rgba(0, 0, 0, 0);
                padding-left: 0;
                padding-right: 0;

                optgroup,
                option {
                    background-color: @topmeteo-blue-dark;
                    color: #fff;
                }
            }
        }
    }
}


/* + + + + + + + + + + + + + + + + +
 * with media queries
 * + + + + + + + + + + + + + + + + */

/* mixin for styles used in both mobile and desktop must be overwritten by
 * special mixin for mobile and desktop   */
.metSelectAll(){
    .metSelectForAll();
}


/* mixin for mobile must not be used in media queries  */
.metSelectMobile(){
    @media (max-width: @topmeteo-site-width-max) {
        .metSelectForMobile();
    }
}


/* mixin for desktop must not be used in media queries  */
.metSelectDesktop(){
    @media (min-width: @topmeteo-site-width) {
        .metSelectForDesktop();
    }
}


/* + + + + + + + + + + + + + + + + +
 * full mixin for desktop and mobile
 * + + + + + + + + + + + + + + + + */

// must not be used in media queries
.metSelect(){
    .metSelectAll();
    .metSelectDesktop();
    .metSelectMobile();
}