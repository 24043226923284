@import (reference) "../setup";

.blog-teaser {
  border: 1px solid grey;
  padding: @topmeteo-pad * 2;
  background-color: white;
  color: @topmeteo-blue;

  h2 {
    margin-top: 0;
    margin-bottom: @topmeteo-pad * 2;
  }

  article {
    display: flex;

    img {
      width: 25%;
      flex: 0 0 25%;
      align-self: flex-start;
    }

    .text-wrapper {
      padding-left: @topmeteo-pad * 2;

      .headline {
        h3 {
          margin-top: 0;
        }
      }

      .description {
        color: black;
      }

      a {
        text-transform: uppercase;
      }
    }
  }

  @media (max-width: @topmeteo-site-width-max) {
    margin: 0 @topmeteo-pad;

    article {
      display: block;

      img {
        width: 100%;
        margin-bottom: @topmeteo-pad;
      }

      .text-wrapper {
        padding-left: 0;
      }
    }
  }
}

body.index {
  .blog-teaser {
    h2,
    h3 {
      text-transform: initial;
    }

    @media (min-width: @topmeteo-site-width) {
      margin: 20px 70px 50px;  /* align with intro image */
    }
  }
}

body.start {
  .blog-teaser {
    @media (max-width: @topmeteo-site-width-max) {
      position: relative;
      z-index: 1;
      margin-bottom: 50px + @topmeteo-pad;  /* height of the content area footer */
    }
  }
}
