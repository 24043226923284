@font-face {
  font-family: 'topmeteo';
  src: url('../font/topmeteo.eot?28964583');
  src: url('../font/topmeteo.eot?28964583#iefix') format('embedded-opentype'),
       url('../font/topmeteo.woff2?28964583') format('woff2'),
       url('../font/topmeteo.woff?28964583') format('woff'),
       url('../font/topmeteo.ttf?28964583') format('truetype'),
       url('../font/topmeteo.svg?28964583#topmeteo') format('svg');
  font-weight: normal;
  font-style: normal;
}
/* Chrome hack: SVG is rendered more smooth in Windozze. 100% magic, uncomment if you need it. */
/* Note, that will break hinting! In other OS-es font will be not as sharp as it could be */
/*
@media screen and (-webkit-min-device-pixel-ratio:0) {
  @font-face {
    font-family: 'topmeteo';
    src: url('../font/topmeteo.svg?28964583#topmeteo') format('svg');
  }
}
*/
 
 [class^="icon-"]:before, [class*=" icon-"]:before {
  font-family: "topmeteo";
  font-style: normal;
  font-weight: normal;
  speak: none;
 
  display: inline-block;
  text-decoration: inherit;
  width: 1em;
  margin-right: .2em;
  text-align: center;
  /* opacity: .8; */
 
  /* For safety - reset parent styles, that can break glyph codes*/
  font-variant: normal;
  text-transform: none;
 
  /* fix buttons height, for twitter bootstrap */
  line-height: 1em;
 
  /* Animation center compensation - margins should be symmetric */
  /* remove if not needed */
  margin-left: .2em;
 
  /* you can be more comfortable with increased icons size */
  /* font-size: 120%; */
 
  /* Font smoothing. That was taken from TWBS */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
 
  /* Uncomment for 3D effect */
  /* text-shadow: 1px 1px 1px rgba(127, 127, 127, 0.3); */
}
 
.icon-menu:before { content: '\e800'; } /* '' */
.icon-cog:before { content: '\e801'; } /* '' */
.icon-star:before { content: '\e802'; } /* '' */
.icon-star-empty:before { content: '\e803'; } /* '' */
.icon-ausbr_0:before { content: '\e804'; } /* '' */
.icon-ausbr_1:before { content: '\e805'; } /* '' */
.icon-ausbr_2:before { content: '\e806'; } /* '' */
.icon-sfcinv1:before { content: '\e807'; } /* '' */
.icon-sfcinv2:before { content: '\e808'; } /* '' */
.icon-sfcinv3:before { content: '\e809'; } /* '' */
.icon-sfcinv4:before { content: '\e80a'; } /* '' */
.icon-ww83:before { content: '\e80b'; } /* '' */
.icon-ww93:before { content: '\e80c'; } /* '' */
.icon-ww95:before { content: '\e80d'; } /* '' */
.icon-ww97:before { content: '\e80e'; } /* '' */
.icon-ww98:before { content: '\e80f'; } /* '' */
.icon-ww99:before { content: '\e810'; } /* '' */
.icon-ww100:before { content: '\e811'; } /* '' */
.icon-ww101:before { content: '\e812'; } /* '' */
.icon-ww103:before { content: '\e813'; } /* '' */
.icon-ww105:before { content: '\e814'; } /* '' */
.icon-ww107:before { content: '\e815'; } /* '' */
.icon-ww112:before { content: '\e816'; } /* '' */
.icon-ww113:before { content: '\e817'; } /* '' */
.icon-ww114:before { content: '\e818'; } /* '' */
.icon-ww115:before { content: '\e819'; } /* '' */
.icon-ww116:before { content: '\e81a'; } /* '' */
.icon-ww117:before { content: '\e81b'; } /* '' */
.icon-ww118:before { content: '\e81c'; } /* '' */
.icon-ww129:before { content: '\e81d'; } /* '' */
.icon-ww131:before { content: '\e81e'; } /* '' */
.icon-ww135:before { content: '\e81f'; } /* '' */
.icon-ww136:before { content: '\e820'; } /* '' */
.icon-ww137:before { content: '\e821'; } /* '' */
.icon-ww138:before { content: '\e822'; } /* '' */
.icon-ww144:before { content: '\e823'; } /* '' */
.icon-pin:before { content: '\e824'; } /* '' */
.icon-pin-down:before { content: '\e825'; } /* '' */
.icon-loop-alt:before { content: '\e826'; } /* '' */
.icon-help:before { content: '\e827'; } /* '' */
.icon-th:before { content: '\e828'; } /* '' */
.icon-eye:before { content: '\e829'; } /* '' */
.icon-eye-off:before { content: '\e82a'; } /* '' */
.icon-ccw:before { content: '\e82b'; } /* '' */
.icon-search:before { content: '\e82c'; } /* '' */
.icon-basket:before { content: '\e82d'; } /* '' */
.icon-rew:before { content: '\e82e'; } /* '' */
.icon-ff:before { content: '\e82f'; } /* '' */
.icon-totop:before { content: '\e830'; } /* '' */
.icon-plus-circled:before { content: '\e831'; } /* '' */
.icon-certificate:before { content: '\e832'; } /* '' */
.icon-cw:before { content: '\e833'; } /* '' */
.icon-left-open-big:before { content: '\e834'; } /* '' */
.icon-right-open-big:before { content: '\e835'; } /* '' */
.icon-check:before { content: '\e836'; } /* '' */
.icon-minus-circled:before { content: '\e837'; } /* '' */
.icon-down-open-big:before { content: '\e838'; } /* '' */
.icon-up-open-big:before { content: '\e839'; } /* '' */
.icon-slider-down:before { content: '\e83a'; } /* '' */
.icon-slider-up:before { content: '\e83b'; } /* '' */
.icon-pin-up:before { content: '\e83c'; } /* '' */
.icon-www136:before { content: '\e83d'; } /* '' */
.icon-giropay:before { content: '\e83e'; } /* '' */
.icon-ec:before { content: '\e83f'; } /* '' */
.icon-calendar:before { content: '\e840'; } /* '' */
.icon-play:before { content: '\e841'; } /* '' */
.icon-stop:before { content: '\e842'; } /* '' */
.icon-dot:before { content: '\e843'; } /* '' */
.icon-dot-3:before { content: '\e844'; } /* '' */
.icon-info:before { content: '\e845'; } /* '' */
.icon-cc-sepa:before { content: '\e846'; } /* '' */
.icon-trash-empty:before { content: '\e847'; } /* '' */
.icon-right-small:before { content: '\e848'; } /* '' */
.icon-pencil:before { content: '\e849'; } /* '' */
.icon-price-globus:before { content: '\e85a'; } /* '' */
.icon-price-ruler:before { content: '\e85b'; } /* '' */
.icon-price-calendar:before { content: '\e85c'; } /* '' */
.icon-price-clock:before { content: '\e85d'; } /* '' */
.icon-cc-visa:before { content: '\f1f0'; } /* '' */
.icon-cc-mastercard:before { content: '\f1f1'; } /* '' */
.icon-cc-amex:before { content: '\f1f3'; } /* '' */
.icon-cc-paypal:before { content: '\f1f4'; } /* '' */
.icon-cc-jcb:before { content: '\f24b'; } /* '' */
.icon-cc-diners-club:before { content: '\f24c'; } /* '' */