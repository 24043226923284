/**
 * @file sub module for template module map (projection maps)
 * loop animation slider for control area
 * ......
 * scope:
 *   - map
 *
 * @copyright CosmoCode GmbH
 */


@import (reference) "../setup";
@import (reference) "../helpers/content";
@import (reference) "../helpers/interactiveElems";
@import (reference) "../helpers/metSelect";
@import (reference) "../helpers/raster";


/* + + + + + + + + + + + + + + + + +
 * DESKTOP + MOBILE
 * + + + + + + + + + + + + + + + + */

body.map {
    div#mtx-controls .component-slider {
        @slider-handle-size: 25px;
        @slider-now-size: 25px;
        @slider-marker-width: 2px;

        position: relative;
        width: 100%;

        div.start,
        div.stop {
            cursor: pointer;
            position: absolute;
            top: 0;
            width: @slider-handle-size;
            height: @slider-handle-size;
            z-index: @topmeteo-z-drawer + 10;
            text-align: center;
            color: @topmeteo-green;

            i::before {
                line-height: @slider-handle-size;
                font-size: @slider-handle-size;
                margin: 0;
                text-shadow: 0px 1px 1px rgba(250, 250, 250, .6);
            }
        }

        div.stop {
            color: @topmeteo-red;
        }

        div.scale {
            position: relative;
            width: 100%;
            padding-top: @slider-handle-size;
            z-index: @topmeteo-z-drawer + 5;

            div.numbers {
                display: table;
                table-layout: fixed;
                width: 100%;

                div {
                    display: table-cell;
                    width: auto;
                    text-align: center;

                    // timescale with tickmark
                    &::after {
                        &:extend(.icon-dot:before);
                    }

                    &.minutes {
                        span {
                            opacity: @topmeteo-header-minutes-opacity;
                        }
                    }

                    &.current {
                        background-color: @topmeteo-blue-dark;
                    }
                }
            }

            div.slide {
                position: relative;
                height: @slider-now-size;
                background-color: @topmeteo-blue-light;

                div.now {
                    cursor: pointer;
                    position: absolute;
                    top: 0;
                    left: 50%;
                    height: 100%;
                    width: @slider-now-size;
                    text-align: center;
                    color: @topmeteo-content;
                    i::before {
                        line-height: @slider-now-size;
                        font-size: @slider-now-size;
                        margin: 0;
                    }
                }

                div.startmarker,
                div.stopmarker {
                    position: absolute;
                    top: 0;
                    height: 100%;
                    width: @slider-marker-width;
                    background-color: @topmeteo-green;
                }
                div.stopmarker {
                    background-color: @topmeteo-red;
                }

                .preload-bar {
                    height: 100%;
                    width: 100%;
                    color: transparent;
                    table-layout: fixed;

                    tr {
                        height: 100%;

                        td {
                            height: 100%;

                            span {
                                cursor: default;
                                display: inline-block;
                                width: 100%;
                                height: 100%;
                            }

                            &.loaded {
                                span {
                                    cursor: pointer;
                                    background-color: @topmeteo-blue-shade;
                                }

                                &.start, &.stop {
                                    span {
                                        width: 50%;
                                    }
                                }

                                &.start {
                                    text-align: right;
                                }

                                &.stop {
                                    text-align: left;
                                }
                            }
                        }
                    }
                }
            }
        }

        &.unplayable {
            div.start,
            div.stop {
                color: @topmeteo-blue-td;
            }

            div.scale {
                div.slide {
                    div.now {
                        display: none;
                    }

                    div.startmarker,
                    div.stopmarker {
                        background-color: @topmeteo-blue-td;
                    }
                }
            }
        }
    }
}



/* + + + + + + + + + + + + + + + + +
 * DESKTOP ONLY
 * + + + + + + + + + + + + + + + + */

@media (min-width: @topmeteo-site-width) {
    body.map {
        div#mtx-controls {
            .component-slider {
                div.scale {
                    div.numbers {
                        div {
                            // timescale with tickmark
                            &::after {
                                display: none;
                            }
                        }

                        .inactive {
                            span {
                                opacity: 0;
                            }
                        }
                    }
                }
            }
        }
    }
}



/* + + + + + + + + + + + + + + + + +
 * MOBILE ONLY
 * + + + + + + + + + + + + + + + + */

@media (max-width: @topmeteo-site-width-max) {
    body.map {
        div#mtx-controls{
            .component-slider-wrapper {
                // we need space on both ends to be able to hit the 0 or 23 mark
                padding-left: (@topmeteo-pad / 2);
                padding-right: (@topmeteo-pad / 2);
            }

            // basically increases all controls to the toggle-size
            .component-slider {
                div.start,
                div.stop {
                    width: @topmeteo-toggle-size;
                    height: @topmeteo-toggle-size;

                    i::before {
                        line-height: @topmeteo-toggle-size;
                        font-size: @topmeteo-toggle-size;
                    }
                }

                div.scale {
                    padding-top: @topmeteo-toggle-size - 16px; // minus lineheight of numbers

                    // hide all, then display every sixth (but only if selector is supported)
                    div.numbers {
                        div {
                            position: relative;

                            &.minutes {
                                opacity: @topmeteo-header-hour-opacity;
                            }

                            span {
                                visibility: hidden;
                            }

                            // timescale with tickmark
                            &::after {
                                position: absolute;
                                top: .2em;
                                display: block;
                                width: 100%;
                                font-family: 'topmeteo';
                                line-height: 1;
                            }



                            &.is-shown {
                                span {
                                    visibility: visible;
                                }

                                // timescale with tickmark
                                &::after {
                                    display: none;
                                }
                            }
                        }
                    }

                    div.slide {
                        height: @topmeteo-toggle-size;

                        div.now {
                            width: @topmeteo-toggle-size;

                            i::before {
                                line-height: @topmeteo-toggle-size;
                                font-size: @topmeteo-toggle-size;
                            }
                        }
                    }
                }
            }
        }
    }
}
