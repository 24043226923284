body.shop {
    .total-costs {
        clear: both;
        font-size: (@topmeteo-font-size + 2);

        dt,
        dd {
            color: @topmeteo-blue;
        }

        dl {
            clear: both;
        }

        dd.price {
            float: right;

            &.negative {
                color: @topmeteo-red;
            }

            &.positive {
                color: @topmeteo-green;
            }
        }

        dl {
            margin: (@topmeteo-pad / 2) @topmeteo-pad;

            &::after {
                content: '';
                clear: both;
                display: table;
            }

            dt {
                clear: both;
                float: left;
                display: inline-block;
                width: 54%;
                font-weight: normal;
            }

            dd {
                float: left;
                text-align: right;
            }

            + dl {
                border-top: solid 1px @topmeteo-blue;
                padding-top: 3px;

                dt,
                dd {
                    font-weight: bold;
                }
            }
        }

        @media (max-width: @topmeteo-site-width-max) {
            dl {
                font-size: @topmeteo-mobile-font-size;

                + dl {
                    margin-bottom: 0;
                }
            }
        }
    }
}
