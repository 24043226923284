@import "../setup";

body.shop {
  .mobile-step {
    .help-text {
      display: block;
      opacity: 0.7;
      font-size: 90%;
      margin-top: 0.5em;
    }
  }
}
