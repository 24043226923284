/**
 * mobile detection
 *
 * The special div gets a different z-index assigned depending on the currently
 * applied styles. This z-index is read by our topmeteo_ismobile()
 * function and can be used to switch behavior in scripts depending on the
 * current view mode
 */
#mobile-detect {
    position: relative;
    width: 0;
    height: 0;
    overflow: hidden;
    z-index: 0;
}
@media (max-width: @topmeteo-site-width-max) {
    #mobile-detect {
        z-index: 1;
    }
}