/**
 * @file cross template module for controls areas
 * ......
 * scope:
 *   - map, metar-taf,location-forecast, satellite
 *
 * @copyright CosmoCode GmbH
 */

@import (reference) "../setup";
@import (reference) "../helpers/content";
@import (reference) "../helpers/interactiveElems";
@import (reference) "../helpers/metSelect";
@import (reference) "../helpers/raster";


/* + + + + + + + + + + + + + + + + +
 * DESKTOP + MOBILE
 * + + + + + + + + + + + + + + + + */

.controls-area {
    .container {
        max-width: 100%; // breakpoint != viewport (1050px)
        background-color: @topmeteo-blue;
        color: #fff;

        a {
            color: #fff;
        }

        select, button {
            color: @text-color;
        }

        /* default met-select styles */
        .metSelect();

        /*default met-btn */
        .metBtn();

        /* special */
        .struct-link-wrapper{
            display: inline-block;
            margin-top: @topmeteo-pad*0.5;
            .pad-t(.5);
        }

        .met-select-wrapper{
            &:extend(.icon-menu all);

            &.right-menu-icon{
                float: right;
            }
        }

        .styled-dropdown {
            > ol {
                > li {
                    white-space: nowrap; // MET-719: all dropdown without wordbreak
                }
            }
        }
    }
}


/* + + + + + + + + + + + + + + + + +
 * DESKTOP ONLY
 * + + + + + + + + + + + + + + + + */

@media (min-width: @topmeteo-site-width) { }


/* + + + + + + + + + + + + + + + + +
 * MOBILE ONLY
 * + + + + + + + + + + + + + + + + */

@media (max-width: @topmeteo-site-width-max) {
    .controls-area {
        .container{
            // when open nav.config-area
            nav.config-area {
                ul {
                    padding-left: 0;
                }

                li {
                    list-style-type: none;
                    display: inline-block;
                    margin-right: 10px;

                    &:last-child {
                        margin-right: 0;
                    }

                    ul#mtx-controls & {
                        span.label {
                            font-size: @topmeteo-mobile-font-size-small;
                            margin-top: 12px;
                        }
                    }
                }
            }

            .met-select-wrapper {
                &::before {
                    display: none;
                }

                select.met-select {
                    border-color: @topmeteo-blue-dark;
                    padding: 6px;
                }
            }

            .met-btn {
                height: @topmeteo-toggle-size;
                width: 100%;
            }
        }
    }

}
