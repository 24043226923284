body.shop.order {
  .content-area {
    .is-account {
      table-layout: fixed;

      #shop-selection {
        input[type=checkbox] {
          width: auto !important /* I'm done with this fuck'n useless selectors! */;
        }
      }

      #shop-selection2 {
        position: relative;
        vertical-align: initial;

        /* See shop_order.less additional breakpoints */
        @media (min-width: 601px) {
          button {
            position: absolute;
            right: 0;
            bottom: 0;
            margin: 0;
            float: none;
          }
        }
      }
    }
  }
}
