/**
 * @file helper mixins and Basic Classes for buttons und links
 * ......
 * scope:
 *   - global
 *
 * @copyright CosmoCode GmbH
 */



.metBtn() {
    .met-btn,
    button.met-btn {
        background-color: @topmeteo-blue-shade;
        border: 1px solid @topmeteo-blue-shade;
        color: #fff;
        margin-right: @topmeteo-pad;
        padding: @topmeteo-pad/2 @topmeteo-pad/2 6px @topmeteo-pad/2;
        min-width: 45px;
        @media (max-width: @topmeteo-site-width-max){
            font-size: @topmeteo-mobile-font-size;
        }
    }
}


/* toggle buttons for drawers */
.toggle() {
    position: absolute;
    z-index: @topmeteo-z-toggle;
    width: @topmeteo-toggle-size;
    height: @topmeteo-toggle-size;
    background-color: @topmeteo-blue;
    overflow: hidden;
    color: #fff;

    a {
        color: #fff;
    }

    input,
    select,
    button {
        color: @text-color;
    }

    .icon {
        margin: 0;
        padding: 0;
        font-size: (@topmeteo-toggle-size*0.6);
        line-height: @topmeteo-toggle-size;
        text-align: center;
        display: block;
        width: 100%;
        height: 100%;

        &::before {
            margin: 0;
        }
    }
}


.icon-link {
    &::before {
        font-family: "topmeteo";
        font-style: normal;
        font-weight: normal;
        speak: none;
        display: inline-block;
        text-decoration: inherit;
        width: 1em;
        margin-right: .2em;
        text-align: center;

        /* For safety - reset parent styles, that can break glyph codes*/
        font-variant: normal;
        text-transform: none;

        /* fix buttons height, for twitter bootstrap */
        line-height: 1em;

        /* Animation center compensation - margins should be symmetric */
        /* remove if not needed */
        margin-left: .2em;

        /* you can be more comfortable with increased icons size */
        /* font-size: 120%; */

        /* Font smoothing. That was taken from TWBS */
        -webkit-font-smoothing: antialiased;
        -moz-osx-font-smoothing: grayscale;

        /* Uncomment for 3D effect */
        /* text-shadow: 1px 1px 1px rgba(127, 127, 127, 0.3); */
    }
}


.topmeteo-icon() {
    .icon-link;
}


.struct-link-wrapper {
    padding-top: @topmeteo-pad/2;
    padding-bottom: @topmeteo-pad/2;
}


.struct-link {
    text-transform: uppercase;
    letter-spacing: .05em;
    font-weight: @topmeteo-weight-light;
    display: inline-block;

    &.matrix {
        .topmeteo-icon();
        &:extend(.icon-th all);
        position: relative;
        padding-left: 2*@padding-small-horizontal;

        &::before {
            position: absolute;
            top: 1px;
            left: 0;
            font-size: 13px;
            text-decoration: none;
        }
    }
}


/* + + +  special links  + + + */
.link {
    display: inline-block;
    text-transform: uppercase;
    padding-bottom: 0.5em;

    &::before {
        display: inline-block;
        font-family: 'topmeteo';
        line-height: 1;
        padding-right: 0.5em;

        &:hover,
        &:focus,
        &:active {
            text-decoration: none;
        }
    }

    &.all {
        &:extend(.icon-eye all);
    }

    &.none {
        &:extend(.icon-eye-off all);
    }

    &.reset {
        &:extend(.icon-cw all);
    }
}


/* + + +  default-button  + + + */
.btn-topmeteo {
    color: #fff;
    background-color: @topmeteo-blue;
    border-color: @topmeteo-blue;
    text-transform: uppercase;
    -webkit-border-radius: 0;
    -moz-border-radius: 0;
    border-radius: 0;
    font-size: @topmeteo-font-size;
    font-weight: normal;
    min-height: 27px;
    padding-top: 0.4em;
    padding-bottom: 0.4em;
    letter-spacing: .05em;

    &:hover,
    &:focus,
    &:active {
        color: #fff;
        background-color: @link-hover-color;
    }
}


/* + + +  close-link  + + + */
.close-icon {
    &::before {
        content: '\00d7'; // "x"
        display: flex;
        font-family: "topmeteo";
        font-style: normal;
        width: 100%;
        flex-direction: column;
    }
}

.icon-close {
    &::before {
        content: '\00d7'; // "x"
    }
}

.close-link() {
    position: absolute;
    top: 5px;
    right: 5px;
    opacity: 1;

    a {
        .icon-close;
        display: block;
        width: 42px;
        height: 42px;
        background-color: @topmeteo-blue;
        opacity: 1;
        border: solid 1px @topmeteo-blue;
        color: #fff;
        font-size: 40px;
        font-family: "topmeteo";
        text-align: center;
        padding: 0;

        &::before {
            display: block;
            font-weight: normal;
            line-height: .8;
            margin: 0;
        }

        &:hover,
        &:focus,
        &:active {
            background-color: @topmeteo-blue-darkest;
            text-decoration: none;
        }
    }
}

/* + + +  positioning (center + middle)  + + + */
.center-middle() {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}
