/**
 * @file template module start
 * ......
 * scope:
 *   - start
 *
 * @copyright CosmoCode GmbH
 */


@import (reference) "../setup";
@import (reference) "../helpers/content";
@import (reference) "../helpers/interactiveElems";
@import (reference) "../helpers/metSelect";
@import (reference) "../helpers/raster";


/* + + + + + + + + + + + + + + + + +
 * DESKTOP + MOBILE
 * + + + + + + + + + + + + + + + + */

body.start {
    .content-area-wrapper {
        position: relative; // for notification blocks
        background: #FFFEF2 url('~images/start/entry_tile.png') repeat-y 0 0;

        .container {
            max-width: 100%; // breakpoint != viewport (1050px)
            background-color: transparent;
            margin: 0 auto;
        }

        .entry-list {
            margin-top: 15%;

            li {
                overflow: hidden; // to big
                padding: 10px;
                text-align: center;
                margin-bottom: 30px;
                font-size: @topmeteo-mobile-font-size-big;

                img {
                    margin: 0 auto;
                    border-radius: 50%;
                    max-width: 100%;
                    height: auto;
                    position: relative;
                }

                a {
                    text-decoration: none;
                }

                strong {
                    .topmeteo-icon();
                    &:extend(.icon-plus-circled all);
                    display: block;
                    position: relative;
                    margin-bottom: 40px;
                    z-index: 10;

                    &::before {
                        position: absolute;
                        display: block;
                        text-align: center;
                        width: 100%;
                        bottom: -40px;
                        padding-right: 30px;
                        left: 0;
                        z-index: 16;
                        font-size: 70px; // special icon
                    }
                }

                span {
                    display: block;
                    text-transform: uppercase;
                    font-weight: @topmeteo-weight-lightest;
                }
            }
        }
    }
}


/* + + + + + + + + + + + + + + + + +
 * SPECIAL MEDIA QUERIES
 * + + + + + + + + + + + + + + + + */

// ground image: background size height is auto so height must be adjusted, must be done
// before DESKTOP and MOBILE media queries (cascading level )

@media (min-width: 1051px){
    body.start .content-area-wrapper .content-area-footer{
        height: 154px;
    }
}

@media (min-width: 1200px){
    body.start .content-area-wrapper .content-area-footer{
        height: 178px;
    }
}

@media (min-width: 1400px){
    body.start .content-area-wrapper .content-area-footer{
        height: 236px;
    }
}

@media (min-width: 1800px){
    body.start .content-area-wrapper .content-area-footer{
        height: 370px;
    }
}

@media (min-width: 2000px){
    body.start .content-area-wrapper .content-area-footer{
        height: 376px;
    }
}



/* + + + + + + + + + + + + + + + + +
 * DESKTOP ONLY
 * + + + + + + + + + + + + + + + + */

@media (min-width: @topmeteo-site-width) {
    body.start {

        /* + + + + +  test version  + + + + + */
        #test-notifications {
            left: 0;
            right: 0;
            max-width: none;

            .notifications-inner {
                max-width: @topmeteo-site-width;
                margin-left: auto;
                margin-right: auto;
            }
        }

        .content-area-wrapper {
            .content-area {
                background: url('~images/start/entry_sky.png') bottom left/cover no-repeat;
            }

            .entry-list {
                li {
                    font-size: (@topmeteo-font-size + 8);

                    strong {
                        &::before {
                            font-size: 90px; // special icon
                            padding-right: 40px;
                        }
                    }

                    &.col-lg-3 {
                        width: 20%;
                    }
                }

                span {
                    padding-top: 40px;

                    &.break {
                        display: none;
                    }
                }
            }

            .content-area-footer{
                width: 100%;
                background: url('~images/start/entry_ground.png') top center/100% auto no-repeat;
            }
        }
    }
}



/* + + + + + + + + + + + + + + + + +
 * MOBILE ONLY
 * + + + + + + + + + + + + + + + + */

@media (max-width: @topmeteo-site-width-max){
    body.start {
        background: #fffef2 url("~images/start/entry_tile.png") repeat-y scroll 0 0;


/* + + + + +  test version  + + + + + */
        #test-notifications {
            top: 0;
            left: (@topmeteo-pad / 2 + @topmeteo-toggle-size);
            right: (@topmeteo-pad * 2 + @topmeteo-toggle-size);
        }

        .content-area-wrapper {
            .entry-list {
                position: relative;
                z-index: 1;

                li {
                    strong {
                        margin-right: 20px;
                        margin-left: 20px;
                    }
                }
            }

            .content-area-footer {
                width: 100%;
                height: 135px;
                position: fixed; bottom: 0; left:0;
                background: url('~images/start/entry_ground_mobile.png') bottom center/100% auto no-repeat;
            }
        }
    }
}


@media (max-width: 801px) {
    body.start .content-area-wrapper {
        .content-area-footer {
            height: 126px;
        }
    }
}


@media (max-width: 600px) and (orientation: portrait) {
    body.start .content-area-wrapper {
        .content-area {
            padding-bottom: 0;
        }
        .content-area-footer {
            height: 50px;
        }
        .entry-list li {
            padding: 5px 15px; margin-bottom: 5px;
        }
    }
}


@media (max-width: 800px) and (orientation: landscape) {
    body.start .content-area-wrapper {
        .content-area-footer {
            height: 105px;
        }

        .entry-list {
            margin-top: 9%;

            li {
                width: 24.5%;
                margin-bottom: 5px;
                padding: 5px 0;
            }
        }
    }
}


@media (max-width: 700px) and (orientation: landscape) {
    body.start .content-area-wrapper {
        .content-area-footer {
            height: 90px;
        }

        .entry-list {
            li {
                strong {
                    margin-bottom: 30px;

                    &::before {
                        bottom: -30px;
                        font-size: 50px;
                        padding-right: 24px;
                    }
                }
            }
        }
    }
}


@media (max-width: 600px) and (orientation: landscape) {
    body.start .content-area-wrapper {
        .content-area-footer {
            height: 78px;
        }
    }
}
