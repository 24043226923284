/**
 * @file template module shop
 * ......
 * scope:
 *   - shop
 *
 * @copyright CosmoCode GmbH
 */


@import (reference) "../setup";
@import (reference) "../helpers/content";
@import (reference) "../helpers/interactiveElems";
@import (reference) "../helpers/metSelect";
@import (reference) "../helpers/raster";

@packet-height: 178px;


/* + + + + + + + + + + + + + + + + +
 * DESKTOP + MOBILE
 * + + + + + + + + + + + + + + + + */

body.shop {
    #redeem-voucher {
        clear: both;

        h3 {
            .content-headline-dark-on-light(@topmeteo-mobile-font-size-big, (@topmeteo-pad/2) (@topmeteo-pad/2) 0 @topmeteo-pad);
            margin-left: 0;
            padding: 0;
        }

        .form {
            border-top: solid 1px @topmeteo-blue-td;
            border-bottom: solid 1px @topmeteo-blue-td;
            padding: 0;
            .pad-t(2);

            form {
                .pad-b(2);
            }

            label {
                color: @topmeteo-blue;
            }
        }
    }

    #currency-switch {
        text-align: center;

        .currency-switch-item {
            border: none;
            color: white;
            background-color: @topmeteo-blue;
            font-size: 1.5rem;
            width: 40px;
            height: 40px;

            opacity: 0.3;

            &.active {
                opacity: 1;
            }
        }
    }

    .price-table,
    #redeem-voucher .form {
        margin: @topmeteo-pad;
        width: calc(100% - @topmeteo-pad * 2);

        *::before,
        *::after {
            font-family: "topmeteo";
        }
    }

    .price-table {

/* + + + + +  shopping basket  + + + + + */
        .buy-button {
            position: relative;
            width: 90%;
            height: 2.5em;
            background-color: @topmeteo-blue;
            border: solid 2px @topmeteo-blue;
            border-radius: 0;
            color: #fff;
            font-size: (@topmeteo-font-size + 2);
            text-align: left;
            padding-left: 2px;

            &::after {
                &:extend(.icon-basket:before);
                position: absolute;
                top: 0;
                color: inherit;
                font-size: 25px;
                margin-top: -3px;
            }

            &:hover,
            &:focus,
            &:active {
                background-color: @topmeteo-blue-darkest;
                border-color: @topmeteo-blue-darkest;
                text-decoration: none;
            }
        }


/*  extend doesnt work in media-queries  */
        div[data-toggle="collapse"],
        div[aria-expanded="false"] {
            &::before,
            &::after {
                &:extend(.icon-down-open-big:before);
            }
        }

        div[aria-expanded="true"] {
            &::before,
            &::after {
                &:extend(.icon-up-open-big:before);
            }
        }


/* + + + + +  region  + + + + + */
        .is-region {
            h4 {
                &::after {
                    &:extend(.icon-price-globus:before);
                    font-size: 65px;
                    margin-top: 25px;
                }
            }
        }


/* + + + + +  number of forecast days  + + + + + */
        .is-length {
            h4 {
                &::after {
                    &:extend(.icon-price-calendar:before);
                    font-size: 51px;
                    margin-top: 17px;
                }
            }
        }


/* + + + + +  forecast resolution  + + + + + */
        .is-resolution {
            h4 {
                // icon
                &::after {
                    &:extend(.icon-price-clock:before);
                    font-size: 55px;
                    margin-top: 25px;
                }
            }
        }


/* + + + + +  option 1 + option 2  + + + + + */
        .is-options {
            counter-increment: option;

            h4 {
                &::after {
                    content: counter(option);
                    font: inherit;
                    padding-left: 3px;
                }
            }

            p {
                span {
                    display: block;
                }
            }
        } // is-options


/* + + + + +  duration + prices  + + + + + */
        .is-duration {
            .icon-caption {
                &::after {
                    &:extend(.icon-price-ruler:before);
                    font-size: 21px;
                }
            }

            p {
                span {
                    display: block;
                }
            }
        } // is-duration
    }
}


/* + + + + + + + + + + + + + + + + +
 * DESKTOP ONLY
 * + + + + + + + + + + + + + + + + */

@media (min-width: @topmeteo-site-width) {
    body.shop {
        .price-table {
            clear: both;
            color: @topmeteo-blue;
            display: flex;

            div[data-toggle="collapse"],
            div[aria-expanded="false"],
            div[aria-expanded="true"] {
                &::before,
                &::after {
                    display: none;
                }
            }

            h3 {
                min-height: 40px;
                font-size: @topmeteo-mobile-font-size-big;
                font-weight: @topmeteo-weight-light;
            }

            sup {
                font-size: @topmeteo-font-size;
                padding-left: 2px;
            }

            img {
                width: auto;
                height: auto;
                max-width: 100%;
                max-height: 100px;
            }


/* + + + + +  "table" column  + + + + + */
            @column-width: 180px;

            .table-col {
                flex: 0 1 @column-width;
                counter-reset: option; // for options
                float: left;
                box-sizing: border-box;
                margin-bottom: 50px;

                h4 {
                    overflow: hidden;
                    position: absolute;
                    left: -100000em;
                    top: -100000em;
                }


/* + + +  zebra-look  + + + */
                &:nth-child(odd) {
                    .table-cell {
                        background-color: @topmeteo-grey-lightest;
                    }
                }


/* + + +  first col with visible "TH"  + + + */
                &:first-child {
                    flex: 0 0.5 (@column-width * 2);

                    > * {
                        margin-left: 50%;
                    }

                    h4 {
                        top: -1px;
                        left: -100%;
                        display: block;
                        width: 100%;
                        overflow: visible;
                        border-top: solid 1px @topmeteo-blue-td;
                        text-align: center;
                        margin: 0;
                        .pad(0.5);
                    }

                    .table-cell {
                        overflow: visible;
                    }

/*  longer border for div.is-button in last row and first col  */
                    .is-head + .collapse {
                        > div:last-of-type {
                            &::after {
                                content: '';
                                position: absolute;
                                top: -1px;
                                left: -100%;
                                display: block;
                                width: 100%;
                                height: 1px;
                                border-top: solid 1px @topmeteo-blue-td;
                                margin: 0;
                            }
                        }
                    }
                } // first-child
            } // table-col


/*  no accordion function  */
            .collapse {
                position: static;
                display: block;
                height: auto !important;
                visibility: visible;

                > div:first-of-type {
                    border: 0 none;
                    .pad(0.5);
                }
            }


/* + + + + +  "table" cell  + + + + + */
            .table-cell {
                position: relative;
                height: 15em;
                overflow-y: auto;
                overflow-x: hidden;
                box-sizing: border-box;
                border-top: solid 1px @topmeteo-blue-td;
                text-align: center;
                .pad(0.5);

                &.is-options,
                &.is-duration {
                    text-align: left;
                }
            }


/* + + + + +  "TH" on top  + + + + + */
            .is-head {
                border-top: 0 none;

                // not clickable
                &::after {
                    content: '';
                    position: absolute;
                    width: 100%;
                    height: 100%;
                    left: 0;
                    top: 0;
                }
            }


/* + + + + +  shopping basket  + + + + + */
            .buy-button {
                &::after {
                    right: -3px;
                }
            }

            .is-button {
                min-height: 5em;
                height: auto;
                .pad-v(2);
            }


/* + + + + +  region  + + + + + */
            .is-region {
                .icon {
                    position: absolute;
                    left: 0;
                    width: 100%;
                    overflow: hidden;
                    background-position: center bottom;
                    background-repeat: no-repeat;
                    background-size: auto 100%;
                    text-align: center;
                }

                .region-world {
                    bottom: 20px;
                    height: 100px;
                    background-image: url('~images/regions/world-zoomed.svg');
                }

                .region-usa {
                    bottom: 48px;
                    height: 67px;
                    background-image: url('~images/style/region-usa.png');
                }

                .region-eu {
                    bottom: 30px;
                    height: 100px;
                    background-image: url('~images/style/region-eu.svg');
                }

                .region-choice {
                    bottom: 10px;
                    height: 120px;
                    background-image: url('~images/style/region-one.svg');

                    &.domain-africa {
                        background-image: url('~images/staticPages/forecastRegions/africa_blue.jpg');
                    }
                }

                .region-de {
                    bottom: 30px;
                    height: 100px;
                    background-image: url('~images/style/region-de.svg');
                }

                h4 {
                    // icon
                    &::after {
                        position: absolute;
                        top: 100%;
                        left: 0;
                        display: block;
                        width: 100%;
                        height: auto;
                        color: inherit;
                        text-align: center;
                    }
                }
            } // is-region


/* + + + + +  number of forecast days  + + + + + */
            .is-length {
                display: flex;
                flex-direction: column;
                justify-content: center;

                h4 {
                    // icon
                    &::after {
                        position: absolute;
                        top: 100%;
                        left: 0;
                        display: block;
                        width: 100%;
                        height: auto;
                        color: inherit;
                        text-align: center;
                    }
                }

                p {
                    font-size: (@topmeteo-font-size * 2);
                    font-weight: @topmeteo-weight-lightest;
                }
            }


/* + + + + +  forecast resolution  + + + + + */
            .is-resolution {
                @icon-size: 13px;

                h4 {
                    // icon
                    &::after {
                        position: absolute;
                        top: 100%;
                        left: 0;
                        display: block;
                        width: 100%;
                        height: auto;
                        color: inherit;
                        text-align: center;
                    }
                }

                ul {
                    text-align: left;
                    margin: 0;
                    padding: 0;
                    .pad-t(2);

                    li {
                        position: relative;
                        display: inline-block;
                        min-width: 15px;
                        text-align: left;
                        margin-right: 10px;
                        padding: 0;

                        &:last-child {
                            margin: 0;
                        }

                        &::after {
                            content: '';
                            position: absolute;
                            top: 100%;
                            display: block;
                            width: @icon-size;
                            height: @icon-size;
                            overflow: hidden;
                            background-color: #fff;
                            border: solid 1px @topmeteo-blue;
                            font-size: 1px;
                            text-indent: -20rem;
                        }
                    }
                }

                .is-1-hour-grid {
                    li {
                        &::after {
                            content: 'OK';
                            background-color: @topmeteo-blue;
                        }
                    }
                }

                .is-2-hour-grid {
                    li:nth-child(odd) {
                        &::after {
                            content: 'OK';
                            background-color: @topmeteo-blue;
                        }
                    }
                }

                .is-3-hour-grid {
                    li {
                        &:nth-child(3n + 1) {
                            &::after {
                                content: 'OK';
                                background-color: @topmeteo-blue;
                            }
                        }
                    }
                }

                .is-4-hour-grid {
                    li {
                        &:nth-child(4n + 1) {
                            &::after {
                                content: 'OK';
                                background-color: @topmeteo-blue;
                            }
                        }
                    }
                }
            } // is-resolution


/* + + + + +  options  + + + + + */
            .is-options {
                height: 7em;

                + .is-options {
                    border: 0 none;

                    h4 {
                        border: 0 none;
                    }
                }
            }

            .wrapper-options {
                .is-options {
                    h4 {
                        @icon-size: 70px;

                        &::before {
                            content: '+';
                            position: absolute;
                            top: 65%;
                            left: 50%;
                            width: @icon-size;
                            height: 1em;
                            overflow: hidden;
                            font-size: @icon-size;
                            line-height: 1;
                            margin-left: -(@icon-size / 2);
                        }
                    }

                    p {
                        .pad-t(2);

                        &:first-of-type {
                            padding-top: 0;
                        }
                    }
                }// is-options

                // last option
                > div:last-child {
                    &.is-options {
                        &::after {
                            display: none;
                        }

                        h4 {
                            border: 0 none;

                            &::before {
                                display: none;
                            }
                        }
                    }
                }
            }// wrapper-options


/* + + + + +  duration + prices  + + + + + */
            .is-duration {
                p {
                    .pad-t(0.25);

                    &:first-of-type {
                        padding-top: 0;
                    }
                }


/* + + +  365  + + + */
                .icon-caption {
                    position: relative;
                    top: 30px;
                    display: block;
                    font-weight: bold;
                    font-size: (@topmeteo-font-size + 2);

                    &::after {
                        display: block;
                        width: 100%;
                        height: 15px;
                        color: inherit;
                        font-weight: normal;
                        text-align: center;
                        margin-top: 3px;
                    }
                }
            } // is-duration


/* + + + + +  partner (MET-1183)  + + + + + */
            .is-partner {
                height: auto;
                min-height: 10em;

                .lxnav,
                .seeyou {
                    min-height: 9em;
                }

                img {
                    height: 67px;
                    width: auto;
                    .pad-t();
                    .pad-b();
                }
            }
        }
    }
}


/* + + + + + + + + + + + + + + + + +
 * MOBILE ONLY
 * + + + + + + + + + + + + + + + + */

@media (max-width: @topmeteo-site-width-max) {
    body.shop {
        #redeem-voucher {
            h3 {
                color: #333;
            }

            .form {
                label {
                    font-size: @topmeteo-mobile-font-size;
                    font-weight: normal;
                }

                input {
                    color: @topmeteo-blue;
                    font-size: @topmeteo-mobile-font-size;
                }
            }
        }

        .price-table {
            img,
            .icon-caption {
                display: none;
            }

            h3 {
                font-size: @topmeteo-mobile-font-size-big;
                margin-top: 0;
                margin-bottom: 0;
                padding-bottom: 0;
                .pad-t();

                + p {
                    .pad-t(.25);
                }
            }

            p {
                font-size: @topmeteo-mobile-font-size;
                margin: 0;

                &:last-child {
                    .pad-b();
                }
            }


/* + + + + +  "table" column  + + + + + */
            .table-col {
                border: solid 1px @topmeteo-blue;
                margin-top: 40px;
            }


/* + + + + +  accordion function  + + + + + */
            div[data-toggle="collapse"] {
                position: relative;

                &::before,
                &::after {
                    position: absolute;
                    top: 50%;
                    font-size: 20px;
                    margin-top: -12px;
                }

                &::before {
                    left: (@topmeteo-pad * 2);
                }

                &::after {
                    right: (@topmeteo-pad * 2);
                }
            }


/* + + + + +  "table" cell  + + + + + */
            .table-cell {
                .pad();

                h4 {
                    margin: 0;

                    &::after {
                        display: none;
                    }
                }
            }


/* + + + + +  "TH" on top  + + + + + */
            .is-head {
                background-color: @topmeteo-blue;
                color: #fff;
                text-align: center;
                .pad(0);


                // clickable area
                > div {
                    cursor: pointer;
                    .pad-h();

                    &:hover {
                        background-color: @topmeteo-blue-dark;
                    }
                }

                p {
                    margin: 0 50px;
                }


/* + + +  first following div (accordion)  + + + */
                + div {
                    font-size: @topmeteo-mobile-font-size;

                    &::before,
                    &::after {
                        display: none;
                    }

                    * {
                        font-size: inherit;
                    }


/* + + +  first buy button in first following div  + + + */
                    > div:first-of-type {
                        display: none;
                    }


/* + + +  "table" cell in first following div  + + + */
                    .table-cell {
                        clear: both;
                        min-height: 3em;
                        padding-left: 0;
                        padding-right: 0;

                        &:not([class~="is-partner"]) { // MET-1183
                            p {
                                float: right;
                                width: 56%;
                            }
                        }

                        h4 {
                            display: inline-block;
                            width: 40%;

                            br:nth-of-type(1) {
                                display: none;
                            }
                        }
                    }
                }
            } // is-head


/* + + + + +  "table" cell without button, "TH"  + + + + + */
            .is-content {
                border-top: solid 1px @topmeteo-blue;
                margin-left: @topmeteo-pad;
                margin-right: @topmeteo-pad;
            }


/* + + + + +  shopping basket  + + + + + */
            .is-button {
                text-align: center;
                margin-left: @topmeteo-pad;
                margin-right: @topmeteo-pad;

                button {
                    max-width: 500px;
                    width: 100%;
                    text-align: center;
                    font-size: @topmeteo-mobile-font-size-big;
                    height: 2em;
                    .pad-r(2);

                    &::after {
                        width: 40px;
                        font-size: 21px;
                        font-weight: bold;
                        text-align: right;
                        margin-top: 0;
                    }
                }

                + .is-content {
                    border-top: 0 none;
                }
            }


/* + + + + +  forecast resolution  + + + + + */
            .is-resolution {
                ul {
                    display: none;
                }
            }


/* + + + + +  partner (MET-1183)  + + + + + */
            .is-partner {
                .lxnav,
                .seeyou {
                    float: right;
                    width: 56%;
                }

                img {
                    display: block;
                    height: 35px;
                    width: auto;
                }

                p {
                    .pad-t();
                }
            }
        }
    }
}
