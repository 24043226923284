/**
 * @file template module shop
 * ......
 * scope:
 *   - shop
 *
 * @copyright CosmoCode GmbH
 */


@import (reference) "../setup";
@import (reference) "../helpers/raster";


/* + + + + + + + + + + + + + + + + +
 * DESKTOP + MOBILE
 * + + + + + + + + + + + + + + + + */

body.profile {
    #invoices {
        .pad-t();
        .pad-b(2);

        .profileblock-head {
            h3 {
                padding-right: 0;
            }
        }

        p.download-link {
            text-align: right;

            a {
                display: inline-block;
                position: absolute;
                background-color: @topmeteo-blue;
                border: solid 2px @topmeteo-blue;
                color: #FFF;
                text-transform: uppercase;
                margin-left: auto;

                &:hover {
                    background-color: @topmeteo-blue-darkest;
                    text-decoration: none;
                }
            }
        } // download-link
    } // #invoices
}


/* + + + + + + + + + + + + + + + + +
 * DESKTOP ONLY
 * + + + + + + + + + + + + + + + + */

@media (min-width: @topmeteo-site-width) {
    body.profile {
        #invoices {
            .pad-h(4);

            p.download-link {
                a {
                    bottom: (@topmeteo-pad / 2);
                    right: 0;
                    .pad-v(.25);
                    .pad-h(.5);
                }
            } // download-link
        } // #invoices
    }
}


/* + + + + + + + + + + + + + + + + +
 * MOBILE ONLY
 * + + + + + + + + + + + + + + + + */

@media (max-width: @topmeteo-site-width-max) {
    body.profile {
        #invoices {
            .pad-t(2);
            .pad-h();

            .packet-invoice {
                margin: 0;
                padding: 0;
            }

            p.download-link {
                margin: 0;
                padding: 0;

                a {
                    bottom: @topmeteo-pad;
                    right: @topmeteo-pad;
                    .pad-v(.5);
                    .pad-h();
                }
            } // download-link
        } // #invoices
    }
}
