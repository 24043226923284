@import "../setup";

body.trial {
  &.pw-reset {
    .user-profile-form {
      p {
        .help-text {
          margin-left: 140px + (@topmeteo-pad / 4);
        }
      }
    }
  }
}
