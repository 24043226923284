/**
 * @file cross template module for header/navigation
 * ......
 * scope:
 *   - map, location-forecast
 *
 * @copyright CosmoCode GmbH
 */

@import (reference) "../setup";
@import (reference) "../helpers/content";
@import (reference) "../helpers/interactiveElems";
@import (reference) "../helpers/metSelect";
@import (reference) "../helpers/raster";


/* + + + + + + + + + + + + + + + + +
 * DESKTOP + MOBILE
 * + + + + + + + + + + + + + + + + */

.legend-area {
    z-index: @topmeteo-z-top; // above the browse arrows
    position: relative;

    div.legend-toggle {
        .icon-help::before {
            &:extend(.icon-info:before);
        }
    }

    .container {
        background-color: @topmeteo-content;
        color: @topmeteo-blue;

        // legend from markdown
        .md-legend {
            .pad(0.5);
            .pad-b(0);

            .close {
                background-color: @topmeteo-blue-dark;
                opacity: 1;
                color: #FFF;
                text-shadow: none;
                padding: 0;

                .close-icon {
                    display: block;
                    width: 32px;
                    height: 32px;
                    box-sizing: border-box;
                    text-align: center;
                    font-size: 30px;
                    font-weight: normal;
                    padding: 0;
                }

                &:hover,
                &:focus {
                    background-color: @topmeteo-blue-darkest;
                    color: #FFF;
                    opacity: 1;
                }
            }

            ul {
                .list-unstyled();
            }

            > span:first-child > ul,
            > ul {
                column-count: 4;
                column-width: 200px; // minimum width of columns, less columns otherwise
                font-size: (@topmeteo-font-size * 1.2);

                > li {
                    .pad(0.5);
                    break-inside: avoid;
                    font-weight: bold;

                    ul {
                        .pad-v(0.25);
                        font-size: @topmeteo-font-size;

                        > li {
                            .pad(0.125);
                        }
                    }
                }
            }
        }

        // description from markdown
        .md-description {;
            .pad-h();
            .pad-t(0);
            .pad-b();
        }
    }
}


/* + + + + + + + + + + + + + + + + +
 * DESKTOP ONLY
 * + + + + + + + + + + + + + + + + */

@media (min-width: @topmeteo-site-width) {
    .legend-area {
        .container {
            display: block !important; // edge case when the legend was closed in mobile, then browser is resized to desktop
        }
    }
}


/* + + + + + + + + + + + + + + + + +
 * MOBILE ONLY
 * + + + + + + + + + + + + + + + + */

@media (max-width: @topmeteo-site-width-max) {
    //#mobile-region-select {
    //    opacity: .8;
    //    -moz-opacity: .8;
    //}

    .legend-area {
        position: absolute;
        top: 0;
        right: 70px;

        // toggle button
        div.legend-toggle {
            .toggle();

            cursor: pointer;
            position: static;

            .icon {
                width: (@topmeteo-toggle-size - 15);
                height: (@topmeteo-toggle-size - 15);
                border: solid 2px #FFF;
                border-radius: 50%;
                margin: 7px 0 0 7px;
                padding-top: .2rem;

                &::before {
                    display: block;
                    width: 100%;
                    font-size: 16px;
                }
            }
        }

        .container {
            z-index: @topmeteo-z-drawer;
            position: fixed;
            bottom: 0;
            left: 0;
            display: none;
            width: 100%;
            height: 50%;
            overflow: auto;
            background-color: @topmeteo-content;
            box-shadow: 0 -5px 5px -5px rgba(0,0,0,0.2);

            // no content behind the lower toggle buttons:
            padding-bottom: @topmeteo-toggle-size;
        }
    }
}