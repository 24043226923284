.oauth.authorize {
  .page-header {
    img {
      max-height: 60px;
      max-width: 38.2%;
    }
  }

  .description {
    white-space: pre-line;
  }
}
