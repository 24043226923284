/**
 * @file sub module for template module map (projection maps)
 * modul specific styles for controls area
 * ......
 * scope:
 *   - map
 *
 * @copyright CosmoCode GmbH
 */


@import (reference) "../setup";
@import (reference) "../helpers/content";
@import (reference) "../helpers/interactiveElems";
@import (reference) "../helpers/metSelect";
@import (reference) "../helpers/raster";


/* + + + + + + + + + + + + + + + + +
 * DESKTOP + MOBILE
 * + + + + + + + + + + + + + + + + */


/* + + + + + + + + + + + + + + + + +
 * DESKTOP ONLY
 * + + + + + + + + + + + + + + + + */

@media (min-width: @topmeteo-site-width) {

}


/* + + + + + + + + + + + + + + + + +
 * MOBILE ONLY
 * + + + + + + + + + + + + + + + + */

@media (max-width: @topmeteo-site-width-max) {
    body.map {
        touch-action: none;

        .browse-layer {
            touch-action: none;
        }

        // panel hidden by default
        .controls-area .container {
            background-color: transparent;
            height: 0;
        }

        // when open, show panel and controls on top
        .controls-area.panel-open .container {
            position: relative;
            background-color: @topmeteo-blue;
            height: auto; // (@topmeteo-toggle-size * 4 + @topmeteo-pad - 6); // slider = 2xtoggle-size
            z-index: @topmeteo-z-middle;

            #mtx-controls {
                .ctl-loop-play,
                .ctl-loop-slider,
                .ctl-loop-speed {
                    display: block;
                }
            }
        }
    }
}

/* + + + + + + + + + + + + + + + + +
 * SPECIAL MEDIA QUERIES
 * + + + + + + + + + + + + + + + + */
@media  (max-width: 470px){
    body.map{
        // when open, show panel and controls on top
        .controls-area.panel-open .container {

            //height: (@topmeteo-toggle-size * 5 + @topmeteo-pad * 3);
            // .ctl-loop-play, .ctl-loop-speed position beneath .ctl-date and ctl-time see map_controls-mtxcontrols.less
        }
    }
}
