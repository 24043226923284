/**
 * @file cross template module for footer areas
 * ......
 * scope:
 *   - map, metar-taf,location-forecast, satellite
 *   - shop, shop-checkout
 *   - start,index
 *   - login. password-reset-confirm, user-profile
 *   - standard
 *
 * @copyright CosmoCode GmbH
 */

@import (reference) "../setup";
@import (reference) "../helpers/content";
@import (reference) "../helpers/interactiveElems";
@import (reference) "../helpers/metSelect";
@import (reference) "../helpers/raster";


/* + + + + + + + + + + + + + + + + +
 * DESKTOP + MOBILE
 * + + + + + + + + + + + + + + + + */

footer {
    .container {
        max-width: 100%; // breakpoint != viewport (1050px)
        background-color: @topmeteo-grey-light;
        margin-top: @topmeteo-pad;

        .links {
            text-transform: uppercase;
            font-size: (@topmeteo-font-size * 1.25);
            line-height: 2em;

            .languages {
                .lang {
                    background: transparent none no-repeat center center;
                    background-size: cover;
                    text-indent: -1000px;
                    display: inline-block;
                    width: (( 4 / 3) * 1em );
                    height: 1em;
                    vertical-align: text-top;
                    margin: 0 0.25em;

                    &.de {
                        background-image: url(~images/flags/de.svg);
                    }

                    &.en {
                        background-image: url(~images/flags/us.svg);
                    }

                    &.en-gb {
                        background-image: url(~images/flags/gb.svg);
                    }

                    &.fr {
                        background-image: url(~images/flags/fr.svg);
                    }

                    &:first-of-type {
                        margin-left: 1em;
                    }
                }
            }

            .col {
                .pad-l();
            }

            .social-media {
                a {
                    display: flex;
                    align-items: center;

                    span {
                        margin-right: .3em;
                    }

                    img {
                        width: 16px;
                        height: 16px;
                    }
                }
            }

            .payment-methods {
                margin-left: -10px;
            }

/* + + + + +  partner logos  + + + + + */
            .partner-headline {
                display: block;
                text-align: center;
                font-weight: bold;
                font-size: (@topmeteo-font-size);
                text-transform: none;
                .pad-t();
            }

            .group-partner {
                width: 322px;
                list-style-type: none;
                font-size: 0;
                margin: 0 auto (@topmeteo-pad * 2);
                padding: 0;

                li {
                    display: inline-block;
                    font-size: @topmeteo-font-size;

                    &:first-child {
                        width: 120px;
                    }

                    &:last-child {
                        width: 80px;
                    }

                    &:nth-child(2) {
                        width: 100px;
                        margin-left: 10px;
                        margin-right: 10px;
                    }

                    img {
                        width: 100%;
                        height: auto;
                    }
                }
            }
        }

        .topmeteo-logo {
            margin: 1em auto 2em auto;

            img {
                width: 150px; // original file-size LogoTrans_600.png 600px * 202px
                height: auto;
            }
        }

        .runinfo {
            text-align: center;

            .status-bubble {
                display: inline-block;
                width: @topmeteo-font-size;
                height: @topmeteo-font-size;
                border-radius: @topmeteo-font-size / 2;
            }

            &.run-in-process {
                .status-bubble {
                    background-color: @run-status-color;
                }
            }

            &.run-done {
                .status-bubble {
                    display: none;
                }
            }
        }

        .copyright {
            text-align: center;
            margin: @topmeteo-pad 0;
        }
    }
}


/* + + + + + + + + + + + + + + + + +
 * DESKTOP ONLY
 * + + + + + + + + + + + + + + + + */

@media (min-width: @topmeteo-site-width){ }


/* + + + + + + + + + + + + + + + + +
 * MOBILE ONLY
 * + + + + + + + + + + + + + + + + */

@media (max-width: @topmeteo-site-width-max){ }
