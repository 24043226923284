/**
 * @file cross template module for page structure and bootstrap overwrites
 * ......
 * scope:
 *   - map, metar-taf,location-forecast, satellite
 *   - shop, shop-checkout
 *   - start,index
 *   - login. password-reset-confirm, user-profile
 *   - standard
 *
 * @copyright CosmoCode GmbH
 */

@import (reference) "../setup";
@import (reference) "../helpers/content";
@import (reference) "../helpers/interactiveElems";
@import (reference) "../helpers/metSelect";
@import (reference) "../helpers/raster";


/* + + + + + + + + + + + + + + + + +
 * DESKTOP + MOBILE
 * + + + + + + + + + + + + + + + + */

//The location dropdown is a permanent panel on mobile
.location-panel {//border-top: solid 3px deepskyblue;
    .toggle();
    position: fixed;
    right: 0;
    top: 0;
    width: 65px;
    height: @topmeteo-toggle-size;

    /* default met-select styles */
    .metSelect();

    /* special */
    .met-select-wrapper {
        select {
            height: @topmeteo-toggle-size;
        }
    }
}

// mobile panel positioned top and center used in metar-taf and sat radar
.centered-panel(){
    position: absolute;
    overflow: hidden;
    z-index: @topmeteo-z-top; // under the menu panel, above the loop panel
    height: @topmeteo-toggle-size;
    min-width: 150px;
    opacity: .8;
    -moz-opacity: .8;

    // center:
    top: 0;
    left: 0;
    right: 0;
    margin: 0 auto;

    // no overlay - responsive
    width: 300px;
    max-width: 68%;
}


// icons
.icon-favourite {
    display: inline-block;
    &:extend(.icon-star all);

    &::before {
        display: block;
    }

    &.none {
        display: inline-block;
        &:extend(.icon-star-empty all);
    }
}

.clearer{
    clear: both;
}


/* + + + + + + + + + + + + + + + + +
 * DESKTOP ONLY
 * + + + + + + + + + + + + + + + + */

@media (min-width: @topmeteo-site-width) {
    html,
    body{
        height: 100%;
        width: 100%;
        overflow: hidden;
        //position: fixed; -> in Firefox there are no bg-images
        position: relative;
    }

    body {
        background-position: bottom center;
        background-size: cover;
        background-repeat: no-repeat;
        background-attachment: scroll;

        &.standard, &.shop,
        &.location-forecast {
            background-color: #FFFCEA;
            background-image: url('~images/style/bg_standard.jpg');
        }

        &.map {
            background-color: #2D609B;
            background-image: url('~images/style/bg_map.jpg');
        }
    }

    .page-wrapper {
        width: 100%;
        height: 100%;
        overflow: auto;
    }

    .mobile-only {
        display: none !important;
    }
}



/* + + + + + + + + + + + + + + + + +
 * MOBILE ONLY
 * + + + + + + + + + + + + + + + + */
@media (max-width: @topmeteo-site-width-max){
    .location-panel{
        z-index: @topmeteo-z-top;
    }
    .desktop-only {
        display: none !important;
    }

    html {
        height: 100%;
    }
}
