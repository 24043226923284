/**
 * @file template module for sat (sat / radar)
 * ......
 * scope:
 *   - sat
 *
 * @copyright CosmoCode GmbH
 */


@import (reference) "../setup";
@import (reference) "../helpers/content";
@import (reference) "../helpers/interactiveElems";
@import (reference) "../helpers/metSelect";
@import (reference) "../helpers/raster";


/* + + + + + + + + + + + + + + + + +
 * DESKTOP + MOBILE
 * + + + + + + + + + + + + + + + + */

body.sat {
    .browse-layer {
        .browse {
            .index {
                top: 50%;
            }
        }
    }
}


/* + + + + + + + + + + + + + + + + +
 * DESKTOP ONLY
 * + + + + + + + + + + + + + + + + */

@media (min-width: @topmeteo-site-width) {
    body.sat {}
}


/* + + + + + + + + + + + + + + + + +
 * MOBILE ONLY
 * + + + + + + + + + + + + + + + + */

@media (max-width: @topmeteo-site-width-max) {
    body.sat {

/* + + + + +  test version  + + + + + */
        #test-notifications {
            right: (@topmeteo-toggle-size + 24);
        }

        #mtx-controls {
            .ctl-time {
                width: 56px;
            }

            .ctl-loop-play {
                .timezone-name {
                    top: 48px;
                }
            }
        }

        // MET-312 - header is fixed positioned (belongs to base_header.less)
        .controls-area {
            position: fixed;
            z-index: 101;
            width: 100%;
        }

        .panel-open {
            + div.content-area {
                //.zoom {
                //    top: 170px;
                //}
            }
        }
    }
}
