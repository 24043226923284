/**
 * @file datepicker styles
 *
 * @module      module/datepicker
 * @author      Jana Deutschlaender <deutschlaender@cosmocode.de>,
                Silke Pisulla <pisulla@cosmocode.de>
 * @copyright   CosmoCode GmbH
 */


/* + + + + + datepicker + + + + + */
.datepicker-label {
    position: relative;

    input {
        height: 30px;
        padding-top: 0;
        padding-bottom: 0;

        &[type="date"] {
            + span {
                display: none;
            }
        }
    }

    span {
        position: absolute;
        bottom: 0;
        right: 0;
        display: block;
        width: 40px;
        height: 30px;
        overflow: hidden;
        box-sizing: border-box;
        background-color: @topmeteo-blue;
        color: #fff;
        font-size: (@topmeteo-headline-font-size - 1);
        font-weight: normal;
        text-align: center;
    }
}


/* + + +  datepicker table  + + + */
.datepicker {
    box-shadow: 0 0 12px rgba(0, 0, 0, 0.175);
    border-color: @topmeteo-blue;
    padding: 0;

    &.datepicker-dropdown {
        border-radius: 0;
        z-index: 1001 !important; // for iOS 8 (overwrite inline styles "10")

        table {
            @icon-size: 40px;

            tr {
                th,
                td {
                    text-align: center;
                    line-height: 1;
                }

                th {
                    border-radius: 0;
                    background-color: @topmeteo-blue;
                    color: #fff;

                    &.prev,
                    &.next {
                        cursor: pointer;
                        position: absolute;
                        top: 0;
                        z-index: 1;
                        display: block;
                        width: @icon-size;
                        height: @icon-size;
                        box-sizing: border-box;
                        font-size: @topmeteo-headline-font-size;
                        font-weight: normal;
                        padding-top: 7px;

                        &:hover,
                        &:focus,
                        &:active {
                            background-color: @topmeteo-blue-darkest;
                        }
                    }

                    &.prev {
                        left: 0;
                    }

                    &.next {
                        right: 0;
                    }

                    &.datepicker-switch {
                        position: relative;
                        z-index: 0;
                        height: @icon-size;
                        padding: 0 20px;
                    }
                }

                td {
                    cursor: pointer;
                    border-radius: 0;

                    &.old,
                    &.new {
                        background-color: @topmeteo-table-grey;
                    }

                    &.disabled {
                        cursor: default;
                        pointer-events: none;
                        position: relative;
                        z-index: -1;
                        background-color: @topmeteo-grey-lightest;
                        color: @disable-color-grey;
                        font-style: italic;
                    }

                    &.day {
                        &:hover,
                        &:focus,
                        &:active {
                            background-color: @topmeteo-blue-darkest;
                            color: #fff;
                        }
                    }

                    &.active {
                        background-color: @topmeteo-blue;
                        color: #fff;
                    }

                    &.today {
                        outline: solid 1px @topmeteo-blue;
                    }
                }
            }
        }
    }
}


/* + + + + + + + + + + + + + + + + +
 * DESKTOP ONLY
 * + + + + + + + + + + + + + + + + */

@media (min-width: @topmeteo-site-width) {
    .datepicker {
        &.datepicker-dropdown {
            table {
                tr {
                    th,
                    td {
                        font-size: (@topmeteo-font-size + 2);
                    }

                    td {
                        .pad-h(.25);
                    }
                }
            }
        }
    }
}


/* + + + + + + + + + + + + + + + + +
 * MOBILE ONLY
 * + + + + + + + + + + + + + + + + */

@media (max-width: @topmeteo-site-width-max) {
    body.shop.order {
        input[type="date"] {
            min-width: 94.5%;
            border-radius: 0;
        }
    }

    input[data-content="datepicker"] {
        font-size: @topmeteo-mobile-font-size;
        font-weight: bold;
    }

    .datepicker {
        &.datepicker-dropdown {
            table {
                tr {
                    th,
                    td {
                        font-size: @topmeteo-mobile-font-size;
                    }

                    td {
                        .pad-h(.5);
                    }
                }
            }
        }
    }
}