@import (reference) "../setup";
@import (reference) "../helpers/raster";
@import (reference) "../helpers/content";

body.location-forecast {
  @ov-icon-fontsize: 16px;
  @header-height-normal: 100px;
  @header-height-open: @header-height-normal + 46px;

  *::before,
  *::after {
    font-family: 'topmeteo', sans-serif;
  }

  .icon-sun {
    &:extend(.icon-certificate all);

    &::before {
      display: inline-block;
      width: 20px;
      color: @icon-darkyellow;
    }
  }

  .controls-area {
    #location-map-image {
      text-align: center;

      .location-map-wrapper {
        position: relative;
        display: inline-block;

        .map {
          max-width: 100%; /* downscale the map on mobile devices */
        }
      }

      .location-position {
        position: absolute;
        color: @icon-pin-darkgrey;
        pointer-events: none;  /* let a click go through it to the map */

        /* We set a fixed width and height to easily calucalute the
           tips position, see next comment for explanation. */
        width: @topmeteo-pin-default-size;
        height: @topmeteo-pin-default-size;

        /* The Position is represented by a pin. It is positioned top left
           but the actual position should be the pins tip and not its upper
           left corner. Offset the position half the size and the whole
           height to let the tip show the position. */
        margin-left: -(@topmeteo-pin-default-size / 2);
        margin-top: -@topmeteo-pin-default-size;

        &::before {
          margin: 0;
          font-size: @topmeteo-pin-default-size;  /* match the elements width and height */
          text-shadow: 0 0 10px white; /* make the pin visible on dark backgrounds */
        }
      }
    }
  }

  .location-manager {
    .help-text {
      margin-top: 3rem;
      font-size: 1.5rem;
      text-align: center;
      color: @topmeteo-blue-shade;

      i {
        cursor: pointer;
        font-weight: bold;
        font-style: normal;
        text-transform: capitalize;
      }
    }
  }

  .location-manager-list {
    padding-left: 1rem;
    font-size: 1.5rem;
    list-style: none;

    @media (max-width: @topmeteo-site-width-max) {
      padding-left: 0;
      align-items: center;

      .location-manager-item {
        display: flex;
        flex-wrap: nowrap;
        align-items: center;

        .name {
          display: inline-block;
          border: 1px solid transparent;
          flex-grow: 1;
          overflow: hidden;
          overflow-wrap: break-word;
          word-wrap: break-word;
        }

        .control {
          font-size: 1.5em;

          &.icon-menu {
            margin-right: 20px;
          }

          &.icon-star {
            order: 3;
          }
        }
      }
    }

    .location-manager-item {
      margin-top: 1rem;

      .name {
        display: inline-block;
        border: 4px solid transparent;
      }
    }

    .control {
      cursor: pointer;
    }
  }

  .content-area {
    #content {
      .browse-layer {
        position: fixed;
        top: 50%;
        left: 0;
        width: 100%;
        transform: translateY(-50%);
        z-index: 5;

        @media (min-width: @topmeteo-site-width) {
          .browse {
            position: relative;
            width: 1050px; /* width is set by JS (depends on table width) */
            max-width: 100%;
            margin: 0 auto;

            .met-btn {
              background-color: transparent;
              color: @topmeteo-blue;
              font-size: 80px;
              line-height: 1;
              font-weight: normal;
              text-shadow: 0 0 10px #fff;
              margin-top: -45px;
              opacity: .4;

              &::before {
                top: -12px;
              }

              &:hover,
              &:active {
                opacity: 1;

                &::after {
                  background-color: @topmeteo-blue;
                  opacity: .3;
                }
              }
            }
          }
        }
      }

      &.small-table-content {
        position: relative;

        .browse-layer {
          position: absolute;
        }
      }

      @media (max-width: @topmeteo-site-width-max) {
        &.small-table-content {
          .browse-layer {
            position: fixed;
          }
        }
      }
    }

    .table-head {
      max-width: 1050px;
      color: @topmeteo-blue;
      font-size: (@topmeteo-font-size + 2);
      margin: 20px auto -12px; // MET-588 sunset beneeth table (-12px with transparent margin-bottom -16px without)
      background-color: #fff;
      text-align: center;

      span {
        display: inline-block;
        .pad-r(2);

        &:last-child {
          padding-right: 0;
        }
      }
    }

    .table-wrapper {
      position: relative;
      text-align: center;

      .table-inner {
        display: inline-block;
        position: relative;

        table {
          background: white;
          white-space: nowrap;
          border: 1px solid @topmeteo-blue;

          @cell-width: 56px;
          @cell-height: 16px;

          &.fixed-headers,
          &.mobile-titles {
            background: transparent;
          }

          thead,
          tfoot {
            color: @topmeteo-blue;
            border: 1px solid @topmeteo-blue;
          }

          th, td {
            width: @cell-width;
            min-width: @cell-width;
            max-width: @cell-width;

            background-color: #fff;
            text-align: center;
            padding: 1px;

            &:first-child {
              width: auto;
              //min-width: unset; unset doesn't work for IE11
              max-width: none; // unset doesn't work for IE11
            }
          }

          th {
            line-height: @topmeteo-font-size;
            padding-top: 2px;  // the font doesn't look centered without the extra padding pixel
          }

          span {
            line-height: @cell-height;
            display: inline-block;
            width: 100%;
          }

          tr:first-of-type {
            td {
              padding-top: 4px;
            }
          }

          .product-category-spacer {
            td {
              @spacer-height: 4px;

              height: @spacer-height;
              min-height: @spacer-height;
              max-height: @spacer-height;

              background: @topmeteo-table-grey;
            }
          }

          tbody:last-of-type {
            .product-category-spacer {
              display: none;
            }
          }

          .product-title {
            color: @topmeteo-blue;
            text-align: left;

            .mark-favorite,
            .product-title-txt {
              width: auto;
            }

            .mark-favorite {
              cursor: pointer;

              &:hover,
              &:focus,
              &:active {
                .icon-favourite {
                  &:extend(.icon-star-empty all);

                  &.none {
                    &:extend(.icon-star all);
                  }
                }
              }
            }

            @media (min-width: @topmeteo-xxs-width) and (max-width: @topmeteo-site-width-max) {
              .product-title-txt {
                max-width: 210px;
              }
            }
          }

          &.mobile-titles {
            border-right: none;
            box-shadow: 2px 1px 5px 0 rgba(0, 0, 0, 0.1);

            thead {
              opacity: 0;
            }
          }

          @media (min-width: @topmeteo-site-width) {
            &.fixed-headers,
            &.mobile-titles {
              display: none;
            }
          }

          @media (max-width: @topmeteo-site-width-max) {
            border-left-color: white;

            th, td {
              &:first-child {
                width: 119px;
                min-width: 119px;
                max-width: 119px;

                .product-title-txt {
                  width: 100%;
                  overflow: hidden;
                  text-overflow: ellipsis;
                }
              }
            }

            .product-content {
              span {
                .icon {
                  font-size: @topmeteo-font-size;
                }
              }
            }

            &.responsive {
              thead {
                opacity: 0;

                th {
                  border-color: transparent;
                }
              }

              .mark-favorite {
                display: none;
              }
            }
          }
        }
      }
    }

    .favorites-area {
      text-align: left;

      .table-foot {
        width: 1050px;
        max-width: 100%;
        background-color: @topmeteo-content;
        .pad();

        @media (min-width: @topmeteo-site-width) {
          margin: 10px auto 0; // for same padding: (table to table-foot) like (legend to footer)
        }

        p {
          margin: 0;
          .pad-t(.5);
        }

        .link {
          display: inline-block;
          text-transform: uppercase;
          padding-bottom: 0.5em;

          @media (max-width: @topmeteo-site-width-max) {
            position: relative;
            display: block;
            max-width: 22em;
            border: solid 1px @topmeteo-blue;
            margin-bottom: 20px;
            padding: 10px 15px 10px 36px;

            &::before {
              position: absolute;
              top: 12px;
              left: 13px;
            }

            &:hover,
            &:focus,
            &:active {
              background-color: @topmeteo-blue;
              color: #fff;
              text-decoration: none;
            }
          }
        }

        .sunrise {
          .pad-l(1);
        }
      }
    }

    .grid-point {
      display: flex;
      flex-wrap: wrap;
      justify-content: center;
      gap: @topmeteo-pad;

      span {
        padding: 0;
      }

      .sun {
        display: flex;
        gap: @topmeteo-pad / 2;
      }
    }
  }

  .mobile-only {
    .grid-point {
      justify-content: initial;

      .name,
      .height,
      .coordinates {
        display: none;
      }

      .sun {
        .dawn,
        .dusk {
          display: none;
        }
      }
    }
  }

  @media (min-width: @topmeteo-site-width) {
    .desktop-content-full-center-table();

    .content-area {
      margin: 10px 0 0 0;
    }
  }

  @media (max-width: @topmeteo-site-width-max) {
    #test-notifications {
      opacity: 1;
      left: -(@topmeteo-pad);
      right: -(@topmeteo-pad);
      top: @header-height-normal;

      &.panel-open {
        top: @header-height-open;
      }

      .notifications-inner {
        &::before {
          left: (@topmeteo-pad / 2);
        }

        p {
          .pad-l(.5);
        }
      }
    }

    .controls-area {
      position: static;

      #lfc-controls {
        position: fixed;
        z-index: 10;
        width: 100%;
        background-color: @topmeteo-blue;
        border-bottom: 8px solid @topmeteo-blue;
      }

      #location-map-image {
        margin-top: @header-height-normal;
      }

      .panel-open {
        #location-map-image {
          margin-top: @header-height-open;
        }
      }
    }

    .is-testing {
      .controls-area {
        #location-map-image {
          margin-top: @header-height-normal + @topmeteo-toggle-size;
        }

        .panel-open {
          #location-map-image {
            margin-top: @header-height-open + @topmeteo-toggle-size;
          }
        }
      }

      .content-area {
        .container-fluid {
          margin-top:  @header-height-normal + @topmeteo-toggle-size;

          &.panel-open {
            margin-top:  @header-height-open + @topmeteo-toggle-size;
          }
        }
      }
    }

    .content-area {
      .container-fluid {
        margin-top: @header-height-normal;

        &.panel-open {
          margin-top: @header-height-open;
        }

        #content {
          overflow: auto;
          .webkit-scrollbar();
        }
      }

      .table-wrapper {
        text-align: left;
      }
    }

    .legend-area {
      position: relative;

      .legend-toggle {
        position: fixed;
        bottom: 0;
        left: 0;
        width: (@topmeteo-toggle-size + 4);
        height: (@topmeteo-toggle-size + 4);
        opacity: .8;
        border: 0 none;
        border-radius: 0;

        .icon {
          width: 30px;
          height: 30px;
          border-color: transparent;

          &::before {
            font-size: 24px;
          }
        }
      }
    }
  }

  /* product icons */
  .product-content {
    color: #000;
    background-color: transparent;

    span {
      &::before {
        color: #000;
      }

      .icon {
        font-size: @ov-icon-fontsize;
        font-style: normal;

        &.icon-ww103 {
          font-size: @ov-icon-fontsize;
        }

        &.icon-ww105,
        &.icon-ww107 {
          font-size: @ov-icon-fontsize;
        }
      }

      /* + + +  Wettererscheinung / weather phenomenon  + + + */
      &.ov-ww83,
      &.ov-ww93,
      &.ov-ww95,
      &.ov-ww97 {
        color: @icon-ov-ww-green;
      }

      &.ov-ww98,
      &.ov-ww99 {
        color: @icon-ov-ww-cyan;
      }

      &.ov-ww100,
      &.ov-ww101 {
        color: @icon-ov-ww-purple;
      }

      &.ov-ww103,
      &.ov-ww105,
      &.ov-ww107 {
        color: @icon-ov-ww-blue;
      }

      &.ov-ww112,
      &.ov-ww113,
      &.ov-ww114 {
        color: @icon-ov-ww-lightgreen;
      }

      &.ov-ww115,
      &.ov-ww116 {
        color: @icon-ov-ww-purple;
      }

      &.ov-ww117,
      &.ov-ww118 {
        color: @icon-ov-ww-blue;
      }

      &.ov-ww129,
      &.ov-ww131,
      &.ov-ww135 {
        color: @icon-ov-ww-red;
      }

      &.ov-ww136,
      &.ov-www136 {
        .icon {
          &::before {
            font-weight: bold;
          }
        }
      }

      &.ov-www136 {
        .icon {
          &:extend(.icon-ww136 all);
        }
      }

      /* + + +  Krit. Wolkenuntergrenzen / critical limit for low layer clouds  + + + */
      &.ov-ceil_verl_1,
      &.ov-ceil_verl_2,
      &.ov-ceil_verl_3 {
        display: inline-block;
        .pad-h(.25);
      }

      &.ov-ceil_verl_1 {
        background: linear-gradient(@icon-red 0%, @icon-red 66%, white 100%);

        &::before {
          content: '<500';
        }
      }

      &.ov-ceil_verl_2 {
        background: linear-gradient(@icon-orange, white);

        &::before {
          content: '<1000';
        }
      }

      &.ov-ceil_verl_3 {
        background: linear-gradient(@icon-yellow, white);

        &::before {
          content: '<2000';
        }
      }

      /* + + +  Krit. Sichtweite / critical visibility (gradient)  + + + */
      &.ov-vis_verl_165,
      &.ov-vis_verl_167,
      &.ov-vis_verl_171 {
        display: inline-block;
        .pad-h(.25);
      }

      &.ov-vis_verl_165 {
        background: linear-gradient(white, @icon-yellow);

        &::before {
          content: 'BR';
        }
      }

      &.ov-vis_verl_167 {
        background: linear-gradient(white, @icon-orange);

        &::before {
          content: 'HZ';
        }
      }

      &.ov-vis_verl_171 {
        background: linear-gradient(white 0%, @icon-red 66%, @icon-red 100%);

        &::before {
          content: 'FG';
        }
      }

      /* + + +  Krit. Sichtweite (circle) / critical visibility (circle)  + + + */
      &.ov-vis165,
      &.ov-vis_s_165,
      &.ov-vis167,
      &.ov-vis_s_167,
      &.ov-vis171,
      &.ov-vis_s_171 {
        height: 20px;
        width: 20px;
        min-width: 20px;
        border-radius: 50%;
        overflow: hidden;
        position: relative;
        margin: 0 auto;
      }

      // yellow quarter
      &.ov-vis165,
      &.ov-vis_s_165 {
        background-color: #fff;
        border: solid 1px @icon-yellow;

        .icon {
          position: absolute;
          top: 0;
          right: 0;
          height: 50%;
          width: 50%;
          background-color: @icon-yellow;
        }
      }

      // orange half circle
      &.ov-vis167,
      &.ov-vis_s_167 {
        background-color: @icon-orange;
        border: solid 1px @icon-orange;

        .icon {
          position: absolute;
          top: 0;
          left: 0;
          height: 100%;
          width: 50%;
          background-color: #fff;
        }
      }

      // red whole circle
      &.ov-vis171,
      &.ov-vis_s_171 {
        background-color: @icon-red;
        border: solid 1px @icon-red;
      }

      /* + + +  Ausbreitungstendenz / tendency to spread  + + + */
      .icon-ausbr_0,
      .icon-ausbr_1,
      .icon-ausbr_2 {
        line-height: 1;
      }

      .icon-ausbr_0 {
        font-size: @ov-icon-fontsize; // special icon
      }

      .icon-ausbr_1 {
        font-size: @ov-icon-fontsize; // special icon
      }

      .icon-ausbr_2 {
        font-size: (@ov-icon-fontsize + 2); // special icon
      }

      /* + + +  Bodeninversion / ground inversion  + + + */
      .icon-sfcinv1,
      .icon-sfcinv2,
      .icon-sfcinv3,
      .icon-sfcinv4 {
        &::before {
          font-size: (@ov-icon-fontsize - 2); // special icons
          font-weight: bold;
        }
      }

      .icon-sfcinv1 {
        &::before {
          color: @icon-color-sfcinv1;
        }
      }

      .icon-sfcinv2 {
        &::before {
          color: @icon-color-sfcinv2;
        }
      }

      .icon-sfcinv3 {
        &::before {
          color: @icon-color-sfcinv3;
        }
      }

      .icon-sfcinv4 {
        &::before {
          color: @icon-color-sfcinv4;
        }
      }
    }
  }
}
